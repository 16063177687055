import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { useTranslation } from 'react-i18next';
import { FormEvent, SyntheticEvent, useEffect } from 'react';
import { useBulkEditsStore } from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { PricingForm } from '@pages/Client/Calendar/components/PricingForm/PricingForm';
import { enableMapSet } from 'immer';
import {
  BulkEditSchema,
  bulkEditSchema
} from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { first, includes, last, snakeCase, some, sortBy } from 'lodash-es';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';
import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { useTransformData } from '@pages/Client/Calendar/components/BulkEdit/hooks/useTransformFormData';
import { WeekDayChips } from '@pages/Client/Calendar/components/BulkEdit/Fragments/WeekDayChips';
import { EditDateRangeSelect } from '@pages/Client/Calendar/components/BulkEdit/Fragments/EditDateRangeSelect';
import { AdjustmentTypeSelect } from '@pages/Client/Calendar/components/BulkEdit/Fragments/AdjustmentTypeSelect';
import { extractNonEmptyKeys } from '@pages/Client/Calendar/components/BulkEdit/helpers/extractNonEmptyKeys';
import { BulkEditTabs } from '@pages/Client/Calendar/components/BulkEditTab/BulkEditTabs';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { ADJUSTMENT_OPTIONS } from '@pages/Client/Calendar/constants';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useMinStayVisualiser } from '@pages/Client/Calendar/hooks/useMinStayVisualiser';
import { BulkEditUserflowIds } from '@common/types/userflow-ids';
import { AxiosError, isAxiosError } from 'axios';
import { AdjustmentQueryKeys } from '@common/types/query-keys';

enableMapSet();

export function BulkEdit({ runPricing }: { runPricing: () => Promise<void> }) {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const { transformData } = useTransformData();
  const { addNotification } = useNotificationsStore();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { selectedHotelRoom } = useHotelRoomsList();
  const { minStaysVisualiser } = useMinStayVisualiser();
  const { setComparePriceMode, setAdjustmentMode, setMinStayDays } = useCalendarPageStore();
  const { mutateAsync: savePricing } = useMutation({
    mutationFn: savePricingSettings,
    mutationKey: [AdjustmentQueryKeys.SAVE_BULK_EDIT_ADJUSTMENT]
  });

  const { setBulkEditDrawerState, setBulkEditViewingDates, setDateRangeError } =
    useBulkEditsStore();

  const form = useForm<BulkEditSchema>({
    mode: 'onChange',
    resolver: async (data, context, options) => {
      const resolver = await zodResolver(bulkEditSchema)(data, context, options);
      return resolver;
    },
    defaultValues: {
      [ADJUSTMENT.AGGRESSIVENESS]: 0,
      [ADJUSTMENT.CLOSED]: [],
      [ADJUSTMENT.DERIVATION]: [],
      [ADJUSTMENT.FIX_PRICES]: [],
      [ADJUSTMENT.MEDIAN]: 0,
      [ADJUSTMENT.MIN_MAX]: [],
      [ADJUSTMENT.MIN_STAY]: [],
      [ADJUSTMENT.PERCENT]: [],
      [ADJUSTMENT.OCCUPANCY]: [],
      adjustmentType: 0,
      dateRange: {},
      isBulkEdit: true,
      selectedRoom: null,
      weekDays: []
    }
  });

  function handleClose() {
    setBulkEditDrawerState(false);
    setDateRangeError(undefined);
    setBulkEditViewingDates(undefined);
    form.reset();
  }

  function handleCancel(e: SyntheticEvent) {
    e.preventDefault();
    handleClose();
  }

  function shouldRerunPricing(formDataRaw: BulkEditSchema) {
    const shouldTriggerReRun = some(extractNonEmptyKeys(formDataRaw), (key) =>
      includes([ADJUSTMENT.AGGRESSIVENESS, ADJUSTMENT.MEDIAN, ADJUSTMENT.CLOSED], key)
    );
    if (shouldTriggerReRun) runPricing();
  }

  async function handleFormSave(formDataRaw: BulkEditSchema) {
    let dates: string[] = [];
    try {
      const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
      const { newPricing, dates: newDates } = await transformData(
        latestPricingSettings,
        formDataRaw
      );
      dates = newDates;
      const newPricingString = JSON.stringify(newPricing);

      await applyIndividualAdjustment({
        end_date: last(sortBy(dates)) as string,
        start_date: first(sortBy(dates)) as string,
        json_settings: newPricingString
      });

      await savePricing(newPricingString);
      addNotification('success', 'Data Updated Successfully');
    } catch (error) {
      if (!isAxiosError(error)) return;
      const typedErr = error as AxiosError;
      if (typedErr.response?.status === 400) {
        addNotification(
          'fail',
          String(
            t(
              `Invalid bulk edit adjustment for the date range ${first(sortBy(dates))} to ${last(sortBy(dates))}. Please check your input values and try again.`
            )
          )
        );
      }
      if (typedErr.response?.status === 500) {
        addNotification('fail', String(t('Something went wrong, please try again later.')));
      }
    } finally {
      setComparePriceMode(undefined);
      setAdjustmentMode(form.getValues('adjustmentType') || 1);
      trackEvent('BulkEditApplied', {
        tags: {
          adjustment: snakeCase(
            ADJUSTMENT_OPTIONS('Room').find(
              (item) => item.value === form.getValues('adjustmentType')
            )?.label
          )
        }
      });
      await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
      handleClose();
      shouldRerunPricing(formDataRaw);
    }
  }

  async function onSubmit(e: FormEvent) {
    e?.preventDefault();
    await form.handleSubmit(handleFormSave)(e);
  }

  useEffect(() => {
    if (!cachePriceQuery.isLoading && !pricingSettingsQuery.isLoading) {
      setMinStayDays(minStaysVisualiser(selectedHotelRoom?.id));
    }
  }, [cachePriceQuery.data, pricingSettingsQuery.data, selectedHotelRoom?.id]);

  return (
    <FormProvider {...form}>
      <form className="relative h-full">
        <div className="h-full px-5 pb-10 md:px-10">
          <div className="h-full overflow-auto px-0.5">
            <div className="sticky top-0 z-10 mb-5 bg-white bg-opacity-70 backdrop-blur">
              <BulkEditTabs />
            </div>
            <div className="flex flex-col pb-16">
              <div className="flex gap-x-7">
                <div className="flex flex-1 flex-col gap-1">
                  <Typography color="darkGrey">{t('Select a Date Range')}</Typography>
                  <EditDateRangeSelect />
                </div>
              </div>

              <ScrollArea>
                <div
                  className="mb-2 mt-6 grid w-full grid-cols-7 gap-2"
                  data-userflow-id={BulkEditUserflowIds.WEEKDAY_CHIPS}
                >
                  <WeekDayChips />
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>

              <div className="mt-6">
                <Typography
                  variant="paragraph-1"
                  className="text-paragraph-1-medium"
                  color="darkGrey"
                >
                  {t('Adjustment')}
                </Typography>
                <div className="mt-2" data-userflow-id={BulkEditUserflowIds.ADJUSTMENT_TYPE_SELECT}>
                  <AdjustmentTypeSelect />
                </div>
              </div>

              <div className="mt-5">
                <PricingForm />
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
          <div className="flex justify-end gap-2">
            <Button
              intent="text"
              onClick={(e: SyntheticEvent) => handleCancel(e)}
              data-userflow-id={BulkEditUserflowIds.CANCEL_BUTTON}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={(e: SyntheticEvent) => onSubmit(e)}
              disabled={
                form?.formState?.isSubmitting ||
                Object.keys(form?.formState?.errors || {}).length > 0
              }
              isLoading={form?.formState?.isSubmitting}
              data-userflow-id={BulkEditUserflowIds.SAVE_BUTTON}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
