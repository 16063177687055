import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map, isNaN } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { FixedPrices } from '@pages/Client/Calendar/components/BulkEdit/types/schema/fixedPricesSchema';

/**
 * Rules for setting and deleting the fix_price key:
 *
 * - Set fix_price Rule:
 *   - Condition:
 *     - The value is greater than 0
 *   - Action:
 *     - Set the fix_price key (as specified by the `actions.setFixedPriceKey` event)
 *
 * - Delete fix_price Rule:
 *   - Condition:
 *     - `isBulkEdit` is true
 *     - The value is exactly 0
 *   - Action:
 *     - Delete the fix_price key (as specified by the `actions.deleteFixedPriceKey` event)
 *
 *   - Condition:
 *     - `isBulkEdit` is false
 *     - The value is 0, null, or NaN
 *   - Action:
 *     - Delete the fix_price key (as specified by the `actions.deleteFixedPriceKey` event)
 */

const actions = {
  setFixedPriceKey: 'setFixedPriceKey',
  deleteFixedPriceKey: 'deleteFixedPriceKey'
};

const isBulkEdit = { fact: 'isBulkEdit', operator: 'equal', value: true };
const isNotBulkEdit = { fact: 'isBulkEdit', operator: 'equal', value: false };
const isZeroValue = { fact: 'value', operator: 'equal', value: 0 };
const isNullValue = { fact: 'value', operator: 'equal', value: null };
const isNaNValue = { fact: 'value', operator: 'isNaNOperator', value: true };

const deleteFixedPriceKey = new Rule({
  conditions: {
    any: [
      { all: [isBulkEdit, isZeroValue] },
      { all: [isNotBulkEdit, { any: [isZeroValue, isNullValue, isNaNValue] }] }
    ]
  },
  event: { type: 'deleteFixedPriceKey' }
});

const setFixedPriceKey = new Rule({
  conditions: {
    all: [
      { fact: 'value', operator: 'notEqual', value: null },
      { fact: 'value', operator: 'isNotNaNOperator', value: true },
      { fact: 'value', operator: 'isNotZero', value: true }
    ]
  },
  event: { type: actions.setFixedPriceKey }
});

export async function transformFixedPrices(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, FixedPrices>,
  date: string
) {
  const engine = new Engine();
  engine.addOperator('isNaNOperator', (factValue) => isNaN(factValue));
  engine.addOperator('isNotNaNOperator', (factValue) => !isNaN(factValue));
  engine.addOperator('isNotZero', (factValue) => factValue !== 0);
  engine.addRule(setFixedPriceKey);
  engine.addRule(deleteFixedPriceKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('id') as Promise<string>).then((id: string) => {
      if (event.type === actions.setFixedPriceKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][id]) currentPricingDraft.dates[date][id] = {};
        currentPricingDraft.dates[date][id][ADJUSTMENT_DB.FIX_PRICES_KEY] = formDataById[id]
          .value as number;
      }
      if (event.type === actions.deleteFixedPriceKey) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.FIX_PRICES_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: FixedPrices) => {
      await engine.run(item);
    })
  );

  return;
}
