import { useMemo } from 'react';
import { find, isNil, isNull, map, result, round } from 'lodash-es';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { PercentAdjustment } from '@pages/Client/Calendar/components/BulkEdit/types/schema/percentageAdjustmentSchema';
import { FixedPrices } from '@pages/Client/Calendar/components/BulkEdit/types/schema/fixedPricesSchema';
import { ClosedSpaces } from '@pages/Client/Calendar/components/BulkEdit/types/schema/closedSpacesSchema';
import { Derivation } from '@pages/Client/Calendar/components/BulkEdit/types/schema/derivationSchema';
import { MinMax } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minMaxSchema';
import { MinStay } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minStaySchema';
import { useFeaturesStore } from '@common/store/features';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

function showDefaultsIfEmpty(value: number, defaultValue: number): number {
  if (isNil(value) || isNull(value) || isNaN(value) || value === 0) return defaultValue;
  return value;
}

function showDefaultValue(
  isBulkEdit: boolean,
  isMinMaxPerRoomType: boolean,
  isAbsoluteDerivation: boolean,
  isReferenceRoom: boolean,
  defaultPrice: number,
  adjustment: number,
  _referenceRoomSetPrice: number,
  referenceRoomDefaultPrice: number,
  roomValue: number
) {
  const referenceRoomSetPrice = showDefaultsIfEmpty(
    _referenceRoomSetPrice,
    referenceRoomDefaultPrice
  );

  switch (true) {
    case isBulkEdit:
      return undefined;

    case !isMinMaxPerRoomType && !isReferenceRoom && isAbsoluteDerivation:
      return referenceRoomSetPrice + adjustment;

    case !isMinMaxPerRoomType && !isReferenceRoom && !isAbsoluteDerivation:
      return adjustment > 0
        ? round(Number(referenceRoomSetPrice + (referenceRoomSetPrice * adjustment) / 100))
        : referenceRoomDefaultPrice;

    default:
      return showDefaultsIfEmpty(roomValue, defaultPrice);
  }
}

export function useBuildAdjustmentInputsArray<T>(formName: ADJUSTMENT): T[] | never {
  const { pricingSettings } = useRoomPrices();
  const { displayHotelRooms } = useHotelRoomsList();
  const { control, getValues } = useFormContext();
  const { features } = useFeaturesStore();
  const { fields } = useFieldArray({ name: formName, control });
  const flags = useFlags();

  const editDate = getValues('editDate');
  const isBulkEdit = getValues('isBulkEdit');

  const adjustmentFields = useMemo(() => {
    const isMinMaxPerRoomType = features?.includes(6) ?? false;
    const day = dayjs(editDate).format(API_DATE_FORMAT);
    const pricingDefault = result(pricingSettings, 'default');
    const hotelSetting = result(pricingSettings, 'hotel');
    const pricingValues = result(pricingSettings, `dates.${day}`);
    const roomsSettings = result(pricingSettings, 'rooms.reference');
    const referenceRoomCount = round(Number(result(roomsSettings, 'number_of_rooms' ?? 0)));
    const referenceRoomId = find(displayHotelRooms, { is_reference_room: true })?.id;

    return map(displayHotelRooms, (room) => {
      const derivedRoomCount = round(
        Number(pricingSettings?.rooms?.derived?.[room.id]?.number_of_rooms ?? 0)
      );
      switch (true) {
        case formName === ADJUSTMENT.MEDIAN:
        case formName === ADJUSTMENT.AGGRESSIVENESS:
        case formName === ADJUSTMENT.OCCUPANCY: {
          //not built here - initialized within the form as it's not room level or
          // requires nested arrays like occupancy
          return {};
        }

        case formName === ADJUSTMENT.CLOSED: {
          const closedSpaces: ClosedSpaces = {
            id: room.id,
            isBulkEdit,
            value: undefined,
            numberOfRooms: room.is_reference_room ? referenceRoomCount : derivedRoomCount
          };
          return closedSpaces;
        }

        case formName === ADJUSTMENT.DERIVATION: {
          const derivation: Derivation = {
            id: room.id,
            isBulkEdit,
            isReferenceRoom: room.is_reference_room,
            value: undefined,
            baseValue: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.DERIVATION_KEY}`)
          };
          return derivation;
        }

        case formName === ADJUSTMENT.FIX_PRICES: {
          const fixPricesAdjustment: FixedPrices = {
            id: room.id,
            isTenPercent: flags[Flags.TenPercentFixedPricesThreshold],
            isPricingCheeseLayer: flags[Flags.PricingCheeseLayers],
            isAbsolute: result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
            adjustment: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.DERIVATION_KEY}`),
            isBulkEdit,
            value: isBulkEdit
              ? undefined
              : round(Number(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.FIX_PRICES_KEY}`))),
            baseValue: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.AVG_PRICE_KEY}`) || 0
          };
          return fixPricesAdjustment;
        }

        case formName === ADJUSTMENT.MIN_MAX: {
          const minMaxAdjustment: MinMax = {
            id: room.id,
            adjustment: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.DERIVATION_KEY}`),
            defaultMaxPrice: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`),
            defaultMinPrice: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`),
            averagePrice: result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.AVG_PRICE_KEY}`),
            isAbsolute: result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
            isBulkEdit,
            isReferenceRoom: room.is_reference_room,
            isPerRoomType: isMinMaxPerRoomType,
            isPricingCheeseLayer: flags[Flags.PricingCheeseLayers],
            minPrice: showDefaultValue(
              isBulkEdit,
              isMinMaxPerRoomType,
              result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
              room.is_reference_room,
              result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`),
              result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.DERIVATION_KEY}`),
              result(pricingValues, `${referenceRoomId}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`),
              result(pricingDefault, `${referenceRoomId}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`),
              round(Number(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`)))
            ),
            maxPrice: showDefaultValue(
              isBulkEdit,
              isMinMaxPerRoomType,
              result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
              room.is_reference_room,
              result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`),
              result(pricingDefault, `${room.id}.${ADJUSTMENT_DB.DERIVATION_KEY}`),
              result(pricingValues, `${referenceRoomId}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`),
              result(pricingDefault, `${referenceRoomId}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`),
              round(Number(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`)))
            )
          };
          return minMaxAdjustment;
        }

        case formName === ADJUSTMENT.MIN_STAY: {
          const minStayAdjustment: MinStay = {
            id: room.id,
            isBulkEdit,
            is_skip_min_stay_rule:
              Boolean(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE}`)) ??
              false,
            value: isBulkEdit
              ? undefined
              : Number(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.MIN_STAY_KEY}`))
          };
          return minStayAdjustment;
        }

        case formName === ADJUSTMENT.PERCENT: {
          const percentAdjustment: PercentAdjustment = {
            id: room.id,
            isBulkEdit,
            value: isBulkEdit
              ? undefined
              : Number(result(pricingValues, `${room.id}.${ADJUSTMENT_DB.PERCENT_KEY}`))
          };
          return percentAdjustment;
        }

        default:
          throw new Error(`Unsupported form name: ${formName}`);
      }
    });
  }, [fields, editDate, displayHotelRooms, pricingSettings, formName, isBulkEdit]);

  return adjustmentFields as T[];
}
