import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';

export const useRecommendedPrice = (currentViewingDate?: Dayjs) => {
  const { sortedHotelRooms } = useHotelRoomsList();
  const { recommendedPriceData, setRecommendedPriceData } = useCalendarPageStore();
  const { roomPrices } = useRoomPrices();

  const defaultRecommendedPriceData = useMemo(() => {
    if (!currentViewingDate) return;

    const formattedDate = currentViewingDate.format(API_DATE_FORMAT);
    return sortedHotelRooms.reduce<Record<number, { price: number; suggested_price: number }>>(
      (acc, room) => ({
        ...acc,
        [room.id]: {
          price: roomPrices?.prices.data?.[formattedDate]?.[room.id]?.price || 0,
          suggested_price: roomPrices?.prices.data?.[formattedDate]?.[room.id]?.suggested_price || 0
        }
      }),
      {}
    );
  }, [currentViewingDate, sortedHotelRooms, roomPrices]);

  return {
    recommendedPriceData: recommendedPriceData || defaultRecommendedPriceData,
    setRecommendedPriceData
  };
};

export const useRecommendedMinStay = (currentViewingDate?: Dayjs) => {
  const { sortedHotelRooms } = useHotelRoomsList();
  const [isUpdatingRecommendedMinStay] = useState(false);
  const { recommendedMinStayData, setRecommendedMinStayData } = useCalendarPageStore();
  const { roomPrices } = useRoomPrices();

  const defaultRecommendedMinStayData = useMemo(() => {
    if (!currentViewingDate) return;

    return sortedHotelRooms.reduce(
      (accumulateVal, room) => {
        accumulateVal[room.id] =
          roomPrices?.prices.data?.[currentViewingDate.format(API_DATE_FORMAT)]?.[room.id]
            ?.suggested_min_stay || undefined;
        return accumulateVal;
      },
      {} as Record<number, number | undefined>
    );
  }, [currentViewingDate]);

  const originalMinStayData = useMemo(() => {
    if (!currentViewingDate) return;

    return sortedHotelRooms.reduce(
      (accumulateVal, room) => {
        accumulateVal[room.id] =
          roomPrices?.prices.data?.[currentViewingDate.format(API_DATE_FORMAT)]?.[room.id]
            ?.original_min_stay || undefined;
        return accumulateVal;
      },
      {} as Record<number, number | undefined>
    );
  }, [currentViewingDate]);

  return {
    isUpdatingRecommendedMinStay,
    recommendedMinStayData: recommendedMinStayData || defaultRecommendedMinStayData,
    setRecommendedMinStayData,
    originalMinStayData
  };
};
