import { Flags } from '@common/constants/flags';
import { useHotelStore } from '@common/store/auth';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';

export enum PmsProvider {
  OPEN_API_PMS = 0,
  APALEO = 1,
  MEWS = 2,
  BOOKING_FACTORY = 3,
  LITTLE_HOTELIER = 4,
  SITEMINDER = 5,
  CLOCK_SYSTEM = 7,
  HOTEL_SPIDER_DEPRECATED = 10,
  COVER = 14,
  PROTEL = 15,
  SMX = 16,
  BOOKING_AUTOMATION = 17,
  CHANNEX = 20,
  GLOBERES = 21,
  GUESTLINE = 22,
  BOCCO = 23,
  SEEKOM = 24,
  BEDS24_NEW = 25,
  BOOKING_AUTOMATION_NEW = 26,
  RMS = 27,
  WEB_HOTELIER = 28,
  KOGNITIV = 29,
  FIVESTELLE_PMS = 33,
  VERTICAL_BOOKING = 34,
  CULTSWITCH = 35,
  SITEMINDER_RMS = 37,
  SEMPER = 38,
  MAPPING_MASTER = 39,
  IBELSA = 40,
  ROOMRACCOON = 41,
  GUESTY = 42,
  RECONLINE = 44,
  D_EDGE = 45,
  EVIIVO = 46,
  GHIX = 47,
  ONE_HOTEL = 48,
  HLS = 49,
  CUBILIS = 50,
  ROOM_CLOUD = 51,
  FREE_TO_BOOK = 52,
  PROFITROOM = 53,
  ZIMMERSOFTWARE = 54,
  SITEMINDER_OFFICIAL = 55,
  AMENITIZ = 56,
  NOOVY = 57,
  DIRS21 = 58,
  HOTSOFT = 59,
  STAYNTOUCH = 60,
  OPERA_CLOUD = 61,
  HOTELAVAILABILITIES = 62,
  RATETIGER = 63,
  SITEMINDER_NEW = 64,
  SITEMINDER_RMS_NEW = 65,
  THAIS = 66,
  UPDATE247 = 67,
  RESLY = 68,
  WINCLOUD = 69,
  CLOUDBEDS = 70,
  SABEE_APP = 71,
  NEWBOOK = 72,
  STAAH = 73,
  ANAND_SYSTEM = 74,
  REZCONTROL = 76,
  ROOM_MASTER = 77,
  HOTLINE = 78,
  THREE_RPMS = 79,
  CASABLANCA = 80,
  LOBBI = 82,
  HOTEL_SPIDER_PUSH_MODEL = 83,
  BED_24_V2_API = 84,
  WEB_REZ_PRO = 85,
  HS_3 = 86,
  VIPS_CLOUD_PMS = 88,
  SKYTOUCH = 87,
  GUEST_POINT = 89
}

const PmsHasIncludedServices = new Set<PmsProvider>([PmsProvider.CLOUDBEDS]);

const PmsHasKeyValueSegmentation = new Set<PmsProvider>([
  PmsProvider.MEWS,
  PmsProvider.OPERA_CLOUD
]);

const PmsHideMinMaxOccupancyOnRoomTypeTable = new Set<PmsProvider>([PmsProvider.GUESTLINE]);

const PmsIndependenRates = new Set<PmsProvider>([
  PmsProvider.MEWS,
  PmsProvider.THAIS,
  PmsProvider.WINCLOUD,
  PmsProvider.SABEE_APP,
  PmsProvider.REZCONTROL,
  PmsProvider.HOTLINE,
  PmsProvider.PROFITROOM,
  PmsProvider.WEB_REZ_PRO,
  PmsProvider.RMS,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.HS_3
]);

const PmsHasDashboard = new Set<PmsProvider>([
  PmsProvider.APALEO,
  PmsProvider.BOOKING_FACTORY,
  PmsProvider.CLOUDBEDS,
  PmsProvider.IBELSA,
  PmsProvider.GUESTLINE,
  PmsProvider.HLS,
  PmsProvider.MEWS,
  PmsProvider.SABEE_APP,
  PmsProvider.PROTEL,
  PmsProvider.RESLY,
  PmsProvider.BEDS24_NEW,
  PmsProvider.RMS,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.ROOMRACCOON,
  PmsProvider.LITTLE_HOTELIER,
  PmsProvider.NEWBOOK
]);

const PmsHasClosedRoomDetection = new Set<PmsProvider>([
  PmsProvider.MEWS,
  PmsProvider.IBELSA,
  PmsProvider.APALEO,
  PmsProvider.PROTEL,
  PmsProvider.FREE_TO_BOOK,
  PmsProvider.CLOCK_SYSTEM,
  PmsProvider.SABEE_APP,
  PmsProvider.RESLY,
  PmsProvider.GUESTLINE,
  PmsProvider.CLOUDBEDS,
  PmsProvider.BOOKING_FACTORY,
  PmsProvider.THREE_RPMS,
  PmsProvider.HOTLINE,
  PmsProvider.HLS,
  PmsProvider.ANAND_SYSTEM,
  PmsProvider.BED_24_V2_API,
  PmsProvider.LOBBI,
  PmsProvider.HS_3,
  PmsProvider.ROOM_MASTER,
  PmsProvider.VIPS_CLOUD_PMS,
  PmsProvider.SKYTOUCH,
  PmsProvider.RMS,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HOTSOFT,
  PmsProvider.OPEN_API_PMS,
  PmsProvider.GUEST_POINT
]);

const PmsHasRetrieveBlockedRooms = new Set<PmsProvider>([
  PmsProvider.BOOKING_FACTORY,
  PmsProvider.HLS,
  PmsProvider.SABEE_APP,
  PmsProvider.RESLY,
  PmsProvider.CLOUDBEDS,
  PmsProvider.LOBBI,
  PmsProvider.BED_24_V2_API,
  PmsProvider.MEWS,
  PmsProvider.RMS
]);

const PmsHasWebHookMessage = new Set<PmsProvider>([PmsProvider.PROTEL, PmsProvider.REZCONTROL]);

const PmsHasNoMarketData = new Set<PmsProvider>([PmsProvider.HOTSOFT]);

const PmsHasMinStayMultipleRates = new Set<PmsProvider>([PmsProvider.APALEO]);

const PmsHasMinStay = new Set<PmsProvider>([
  PmsProvider.MEWS,
  PmsProvider.BED_24_V2_API,
  PmsProvider.CLOUDBEDS,
  PmsProvider.BOOKING_FACTORY,
  PmsProvider.APALEO,
  PmsProvider.GUEST_POINT
]);
const PmsHasManageIframe = new Set<PmsProvider>([PmsProvider.APALEO, PmsProvider.IBELSA]);

const PmsHasRoomTypeOnly = new Set<PmsProvider>([
  PmsProvider.GUESTY,
  PmsProvider.FREE_TO_BOOK,
  PmsProvider.MAPPING_MASTER,
  PmsProvider.CULTSWITCH,
  PmsProvider.ANAND_SYSTEM,
  PmsProvider.THREE_RPMS,
  PmsProvider.LOBBI,
  PmsProvider.CASABLANCA,
  PmsProvider.SKYTOUCH
]);

const PmsHasNoButtonRefreshRates = new Set<PmsProvider>([PmsProvider.HOTLINE, PmsProvider.HS_3]);

const PmsHasNoUploadPricesButton = new Set<PmsProvider>([PmsProvider.HOTLINE, PmsProvider.HS_3]);

const PmsHasUploadAllRates = new Set<PmsProvider>([PmsProvider.HOTLINE, PmsProvider.HS_3]);

const PmsHasSpecialRates = new Set<PmsProvider>([
  PmsProvider.PROTEL,
  PmsProvider.SITEMINDER_NEW,
  PmsProvider.WEB_REZ_PRO,
  PmsProvider.ROOM_MASTER,
  PmsProvider.HS_3,
  PmsProvider.VERTICAL_BOOKING,
  PmsProvider.ROOMRACCOON
]);

const PmsHasRoomOccupancyForPricing = new Set<PmsProvider>([
  PmsProvider.BOOKING_AUTOMATION_NEW,
  PmsProvider.BED_24_V2_API
]);

const pmsHasSandboxEnv = new Set<PmsProvider>([
  PmsProvider.MEWS,
  PmsProvider.IBELSA,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.CLOUDBEDS,
  PmsProvider.ROOMRACCOON,
  PmsProvider.HOTSOFT
]);
const PmsHasPushStatus = new Set<PmsProvider>([PmsProvider.STAAH, PmsProvider.THREE_RPMS]);

const PmsHasReservationPipelineV2 = new Set<PmsProvider>([
  PmsProvider.PROTEL,
  PmsProvider.GUESTLINE,
  PmsProvider.ROOMRACCOON,
  PmsProvider.SITEMINDER_RMS,
  PmsProvider.SITEMINDER_RMS_NEW
]);

const PmsIsArrivalBased = new Set<PmsProvider>([
  PmsProvider.APALEO,
  PmsProvider.BOOKING_FACTORY,
  PmsProvider.GUEST_POINT
]);
const PmsIsSupportArivalAndStayThrough = new Set<PmsProvider>([PmsProvider.BOOKING_FACTORY]);
const PmsHasNotSupportV2 = new Set<PmsProvider>([
  PmsProvider.COVER,
  PmsProvider.SMX,
  PmsProvider.BOOKING_AUTOMATION,
  PmsProvider.CHANNEX,
  PmsProvider.SEEKOM,
  PmsProvider.RMS,
  PmsProvider.WEB_HOTELIER,
  PmsProvider.KOGNITIV,
  PmsProvider.FIVESTELLE_PMS,
  PmsProvider.VERTICAL_BOOKING,
  PmsProvider.CULTSWITCH,
  PmsProvider.SEMPER,
  PmsProvider.MAPPING_MASTER,
  PmsProvider.ROOMRACCOON,
  PmsProvider.GUESTY,
  PmsProvider.RECONLINE,
  PmsProvider.D_EDGE,
  PmsProvider.EVIIVO,
  PmsProvider.ONE_HOTEL,
  PmsProvider.CUBILIS,
  PmsProvider.ROOM_CLOUD,
  PmsProvider.FREE_TO_BOOK,
  PmsProvider.PROFITROOM,
  PmsProvider.ZIMMERSOFTWARE,
  PmsProvider.SITEMINDER_OFFICIAL,
  PmsProvider.AMENITIZ,
  PmsProvider.NOOVY,
  PmsProvider.DIRS21,
  PmsProvider.STAYNTOUCH,
  PmsProvider.OPERA_CLOUD,
  PmsProvider.RATETIGER
]);

type MinStayConfig = {
  min_stay_remove_value: number;
};

// This is the config that determines what min stay value removes the min stay restriction from the PMS. It is assumed that is the min_stay_remove_value and lower.
const PmsMinStayConfig: Partial<Record<PmsProvider, MinStayConfig>> = {
  [PmsProvider.MEWS]: {
    min_stay_remove_value: 1
  },
  [PmsProvider.BED_24_V2_API]: {
    min_stay_remove_value: 1
  },
  [PmsProvider.CLOUDBEDS]: {
    min_stay_remove_value: 1
  },
  [PmsProvider.APALEO]: {
    min_stay_remove_value: 1
  }
};

export const usePmsProvider = (isEnabled?: boolean) => {
  const { hotelAuthToken } = useHotelStore();

  // Ensure isEnabled is a boolean
  const shouldEnableHooks = Boolean(isEnabled) && !!hotelAuthToken;

  const { hotelDetails } = useHotelDetails(shouldEnableHooks);
  const { pmsList } = useGetHotelPmsList(shouldEnableHooks);

  const flags = useFlags();

  const pmsProviderId = hotelDetails?.pms_provider ?? 0;

  const isHotelUseIntegrationV2 = pmsList?.find((pms) => pms?.primary)?.use_v2_integration;
  const isPmsUseArivalBased =
    pmsList?.find((pms) => pms?.primary)?.configurations?.min_stay_type === 'stay_arrival';

  return {
    pmsProviderId,
    hasIncludedServicesEnabled: () => {
      return PmsHasIncludedServices.has(pmsProviderId as PmsProvider);
    },
    hasDashboardEnabled: () => {
      return PmsHasDashboard.has(pmsProviderId as PmsProvider);
    },
    hasMinStayEnabled: () => {
      return PmsHasMinStay.has(pmsProviderId as PmsProvider) && flags[Flags.MinStayV2];
    },
    hasWebHookMessage: () => {
      return PmsHasWebHookMessage.has(pmsProviderId as PmsProvider);
    },
    hasClosedRoomDetection: () => {
      return PmsHasClosedRoomDetection.has(pmsProviderId as PmsProvider);
    },
    hasRoomTypeOnly: () => {
      return PmsHasRoomTypeOnly.has(pmsProviderId as PmsProvider);
    },
    hasRetrieveBlockedRooms: () => {
      return PmsHasRetrieveBlockedRooms.has(pmsProviderId as PmsProvider);
    },
    hasNoButtonRefreshRates: () => {
      return PmsHasNoButtonRefreshRates.has(pmsProviderId as PmsProvider);
    },
    shouldDisableReporting: () => {
      if (pmsProviderId !== PmsProvider.IBELSA) {
        return false;
      }
      const provider = pmsList?.find((pms) => pms.provider === PmsProvider.IBELSA);
      return !(provider?.password && provider?.username);
    },
    hasSandboxEnv: (pmsId: number) => {
      return pmsHasSandboxEnv.has(pmsId as PmsProvider);
    },
    pmsHasnotSupportV2: (pmsId: number) => {
      return PmsHasNotSupportV2.has(pmsId as PmsProvider);
    },
    hasUploadAllRates: (pmsId: number) => {
      return PmsHasUploadAllRates.has(pmsId as PmsProvider);
    },
    hasRoomOccupancyForPricing: () => {
      return PmsHasRoomOccupancyForPricing.has(pmsProviderId as PmsProvider);
    },
    hasNoUploadPricesButton: () => {
      return PmsHasNoUploadPricesButton.has(pmsProviderId as PmsProvider);
    },
    hasSpecialRates: () => {
      return PmsHasSpecialRates.has(pmsProviderId as PmsProvider);
    },
    hasReservationPipelineV2: () => {
      return PmsHasReservationPipelineV2.has(pmsProviderId as PmsProvider);
    },
    hasKeyValueSegmentation: () => {
      return PmsHasKeyValueSegmentation.has(pmsProviderId as PmsProvider);
    },
    hasManageIframe: () => {
      return PmsHasManageIframe.has(pmsProviderId as PmsProvider);
    },
    isArrivalBased: () => {
      return PmsIsArrivalBased.has(pmsProviderId as PmsProvider) && isPmsUseArivalBased;
    },
    hasIndependentRates: () => {
      return PmsIndependenRates.has(pmsProviderId as PmsProvider);
    },
    hasNoMarketData: () => {
      return PmsHasNoMarketData.has(pmsProviderId as PmsProvider);
    },
    pmsMinStayConfig: PmsMinStayConfig[pmsProviderId as PmsProvider],
    hideMinMaxOccupancyOnRoomTypeTable: () => {
      return PmsHideMinMaxOccupancyOnRoomTypeTable.has(pmsProviderId as PmsProvider);
    },
    isHotelUseIntegrationV2,
    hasPushStatus: () => {
      return [...PmsHasPushStatus];
    },
    isSupportArivalAndStayThrough: () => {
      return PmsIsSupportArivalAndStayThrough.has(pmsProviderId as PmsProvider);
    },
    hasMinStayMultipleRates: () => {
      return (
        PmsHasMinStayMultipleRates.has(pmsProviderId as PmsProvider) &&
        flags[Flags.MinStayMultipleRatePlans]
      );
    }
  };
};
