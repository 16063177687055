import dayjs from 'dayjs';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { SegmentedControl, Popover, Text, Title, rem } from '@mantine/core';

interface Props {
  label: string;
  previousYear: number;
  currentYear: number;
  nextYear: number;
  currentDate: Date | undefined;
  onYearChange?: (year: number) => void;
  onMonthChange: (month: number) => void;
  additionalInfo?: React.ReactNode;
  isYearly?: boolean;
}

export const QuickMonthNavigator: React.FC<Props> = ({
  label,
  previousYear,
  currentYear,
  nextYear,
  currentDate,
  onYearChange,
  onMonthChange,
  additionalInfo,
  isYearly = false
}) => {
  const { trackEvent } = useEventTracking();

  return (
    <Popover onOpen={() => trackEvent('QuickMonthNavigatorOpened')}>
      <Popover.Target>
        <div className="cursor-pointer">
          <Title order={2} className="text-base md:text-xl 2xl:text-2xl" fw={500} c="dark.9">
            {currentDate ? dayjs(currentDate).format(isYearly ? 'YYYY' : 'MMM YYYY') : ''}
          </Title>
        </div>
      </Popover.Target>
      <Popover.Dropdown bg="dark.7" bd={0} miw="330px">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <Text size="xs" variant="meta-2" fw={600} c="gray.6">
              {label}
            </Text>
            <SegmentedControl
              onChange={(value) => onYearChange?.(Number(value))}
              size="xs"
              value={currentDate ? currentDate.getFullYear().toString() : currentYear.toString()}
              data={[
                { label: previousYear.toString(), value: previousYear.toString() },
                { label: currentYear.toString(), value: currentYear.toString() },
                { label: nextYear.toString(), value: nextYear.toString() }
              ]}
            />
          </div>

          {!isYearly && (
            <div className="grid grid-cols-3 gap-1">
              {dayjs.months().map((month, index) => (
                <button
                  key={month}
                  type="button"
                  className={`w-full rounded-md px-3 py-0.5 text-left hover:bg-grey hover:bg-opacity-40 ${
                    currentDate && index === currentDate.getMonth() ? 'bg-grey bg-opacity-40' : ''
                  }`}
                  onClick={() => onMonthChange(index)}
                >
                  <Text size="sm" c="white">
                    {month}
                  </Text>
                </button>
              ))}
            </div>
          )}

          {additionalInfo}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
