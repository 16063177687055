import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { Button } from '@mantine/core';
import { Typography } from '@common/components/foundations/Typography';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { isUTCYesterdayInUserTZ } from '@common/utils/isUTCYesterdayInUserTZ';
import { PricingTabOptions } from '@pages/Client/Calendar/components/BulkEdit/Fragments/PriceAdjustmentTabOptions';
import { useTransformData } from '@pages/Client/Calendar/components/BulkEdit/hooks/useTransformFormData';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { EditSchema, editSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { PricingStrategy } from '@pages/Client/Calendar/components/PriceDrawerContent/EditPrices/PricingStrategy';
import { DrawerTabProps } from '@pages/Client/Calendar/components/PriceDrawerContent/types';
import { PricingForm } from '@pages/Client/Calendar/components/PricingForm/PricingForm';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { find, isEmpty, map, result } from 'lodash-es';
import { FormEvent, SyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMinStayVisualiser } from '@pages/Client/Calendar/hooks/useMinStayVisualiser';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';
import { AxiosError, isAxiosError } from 'axios';
import { useFormPreflight } from '@pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight';
import { AdjustmentQueryKeys } from '@common/types/query-keys';

export const EditPrices = ({ currentViewingDate }: DrawerTabProps) => {
  const isBulkEdit = false;
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { transformData } = useTransformData();
  const { selectedHotelRoom, sortedHotelRooms } = useHotelRoomsList();
  const { pricingSettings } = usePricingSettings();
  const { addNotification } = useNotificationsStore();
  const { minStaysVisualiser } = useMinStayVisualiser();
  const { setModalState, toggleIsInterMediate, setIntermediateFunction } =
    useUploadPMSPriceModalState();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { comparePriceTo, setComparePriceMode, setMinStayDays } = useCalendarPageStore();

  const { mutateAsync: savePricing } = useMutation({
    mutationFn: savePricingSettings,
    mutationKey: [AdjustmentQueryKeys.SAVE_EDIT_PRICES]
  });
  const { drawerState, setDrawerState, adjustmentsData, setAdjustmentsData } =
    usePriceDrawerStore();
  const { hasUploadPriceButton, hasNoUploadPricesButton } = useGetDetailProviderConfig();
  const { runPreflight, isUpdating: isUpdatingRecommendedPrice } = useFormPreflight();

  const isHideUploadPricesButton =
    !hasUploadPriceButton ||
    isUTCYesterdayInUserTZ(currentViewingDate.format(API_DATE_FORMAT)) ||
    hasNoUploadPricesButton;

  const form = useForm<EditSchema>({
    mode: 'onChange',
    resolver: async (data, context, options) => {
      const resolver = await zodResolver(editSchema)(data, context, options);
      toggleIsInterMediate(true);
      setIntermediateFunction(async () => handleSaveThroughPMSModal());
      return resolver;
    },
    defaultValues: {
      [ADJUSTMENT.FIX_PRICES]: adjustmentsData?.fixPricesAdjustment || [],
      [ADJUSTMENT.MIN_MAX]: adjustmentsData?.minMaxAdjustment || [],
      [ADJUSTMENT.MIN_STAY]: adjustmentsData?.minStayAdjustment || [],
      [ADJUSTMENT.PERCENT]: adjustmentsData?.percentAdjustment || [],
      adjustmentType: 0,
      editDate: currentViewingDate.startOf('day').toDate(),
      isBulkEdit
    }
  });

  async function removeAdjustment(e: SyntheticEvent) {
    try {
      e?.preventDefault();
      const formDataRaw = form.getValues();
      const newPricingForm = produce(formDataRaw, (draft: any) => {
        if (isEmpty(draft)) throw new Error('No form data');
        const rooms = map(sortedHotelRooms, 'id');
        map(rooms, (room, index) => {
          const hotelSetting = result(pricingSettings, 'hotel');
          const pricingDefault = result(pricingSettings, 'default');
          const baseMin = result(pricingSettings, `default.${room}.${ADJUSTMENT_DB.MIN_PRICE_KEY}`);
          const baseMax = result(pricingSettings, `default.${room}.${ADJUSTMENT_DB.MAX_PRICE_KEY}`);

          draft[ADJUSTMENT.FIX_PRICES][index] = {
            id: room,
            value: null,
            isBulkEdit: false,
            isAbsolute: result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
            adjustment: result(pricingDefault, `${room}.${ADJUSTMENT_DB.DERIVATION_KEY}`)
          };
          draft[ADJUSTMENT.PERCENT][index] = { id: room, value: null, isBulkEdit: false };
          draft[ADJUSTMENT.MIN_MAX][index] = {
            id: room,
            minPrice: baseMin,
            maxPrice: baseMax,
            defaultMinPrice: baseMin,
            defaultMaxPrice: baseMax,
            isReferenceRoom: find(sortedHotelRooms, { id: room })?.is_reference_room ?? false,
            isPerRoomType: features?.includes(6) ?? false,
            [ADJUSTMENT_DB.MIN_PRICE_KEY]: null,
            [ADJUSTMENT_DB.MAX_PRICE_KEY]: null,
            isBulkEdit: false,
            isAbsolute: result(hotelSetting, ADJUSTMENT_DB.DERIVATION_ABSOLUTE_KEY),
            adjustment: result(pricingDefault, `${room}.${ADJUSTMENT_DB.DERIVATION_KEY}`)
          };
          draft[ADJUSTMENT.MIN_STAY][index] = {
            id: room,
            value: null,
            is_skip_min_stay_rule: false,
            isBulkEdit: false
          };
        });
      });
      form.reset(newPricingForm);

      const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
      const { newPricing, dates } = await transformData(latestPricingSettings, newPricingForm);

      await runPreflight(JSON.stringify(newPricing), dates[0]);
    } catch (_) {
      return;
    }
  }

  function handleClose() {
    console.log('Closed Correctly Edit Prices');
    setComparePriceMode(comparePriceTo);
    setDrawerState(false);
    toggleIsInterMediate(false);
    setAdjustmentsData({});
    form.reset();
  }

  async function refetchData() {
    await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
  }

  async function handleFormSave(formDataRaw: EditSchema) {
    let dates: string[] = [];

    try {
      const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
      const { newPricing, dates: newDates } = await transformData(
        latestPricingSettings,
        formDataRaw
      );
      dates = newDates;
      const newPricingString = JSON.stringify(newPricing);
      await applyIndividualAdjustment({
        end_date: dates[0],
        start_date: dates[0],
        json_settings: newPricingString
      });

      // Only execute savePricing if applyIndividualAdjustment was successful
      await savePricing(newPricingString);
      addNotification('success', 'Data Updated Successfully');
    } catch (err) {
      if (!isAxiosError(err)) return;
      const typedErr = err as AxiosError;
      if (typedErr.response?.status === 400) {
        addNotification(
          'fail',
          String(
            t(
              `Invalid price adjustment for ${dates[0]}. Please check your input values and try again.`
            )
          )
        );
      }
      if (typedErr.response?.status === 500) {
        addNotification('fail', String(t('Something went wrong, please try again later.')));
      }
    }
  }

  async function onSubmit(e: FormEvent) {
    e?.preventDefault();
    await form.handleSubmit(handleFormSave)(e);
    await refetchData();
    handleClose();
  }

  function handleCancel(e: SyntheticEvent) {
    e.preventDefault();
    handleClose();
  }

  async function handleSaveThroughPMSModal() {
    const formDataRaw = form.getValues();
    await handleFormSave(formDataRaw);
    console.log('handleSaveThroughPMSModal', formDataRaw);
  }

  async function handleUploadPrices(e: SyntheticEvent) {
    e?.preventDefault();
    setModalState(true);
  }

  useEffect(() => {
    if (!cachePriceQuery.isLoading && !pricingSettingsQuery.isLoading) {
      setMinStayDays(minStaysVisualiser(selectedHotelRoom?.id));
    }
  }, [cachePriceQuery.data, pricingSettingsQuery.data, selectedHotelRoom?.id]);

  useEffect(() => {
    if (!drawerState) handleClose();
  }, [drawerState]);

  useEffect(() => {
    form.setValue('editDate', currentViewingDate.startOf('day').toDate());
    form.resetField(ADJUSTMENT.PERCENT);
    form.resetField(ADJUSTMENT.MIN_MAX);
    form.resetField(ADJUSTMENT.FIX_PRICES);
    form.resetField(ADJUSTMENT.MIN_STAY);
  }, [currentViewingDate]);

  const isDisabled =
    form?.formState?.isSubmitting ||
    !form?.formState?.isValid ||
    Object.keys(form?.formState?.errors || {}).length > 0;

  const isLoading =
    form?.formState?.isSubmitting ||
    cachePriceQuery.isLoading ||
    pricingSettingsQuery.isLoading ||
    isUpdatingRecommendedPrice;

  return (
    <FormProvider {...form}>
      <form className="-my-4">
        <PricingStrategy currentViewingDate={currentViewingDate} />
        <Typography element="h5" className="mt-6 text-nav-medium" color="grey">
          {t('Edit Prices')}
        </Typography>
        <div className="mt-5 flex flex-col gap-y-6 pb-16">
          <PricingTabOptions />
          <PricingForm />
        </div>
        <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
          <ScrollArea>
            <div className="flex justify-end gap-2 p-0.5">
              <Button
                variant="subtle"
                onClick={(e: SyntheticEvent) => handleCancel(e)}
                data-userflow-id={PriceDrawerUserflowIds.CANCEL_BUTTON}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="outline"
                onClick={(e: SyntheticEvent) => onSubmit(e)}
                disabled={isDisabled}
                loading={form?.formState?.isSubmitting}
                data-userflow-id={PriceDrawerUserflowIds.SAVE_PRICES_BUTTON}
              >
                {t('Save Prices')}
              </Button>
              <Button
                variant="outline"
                disabled={isUpdatingRecommendedPrice}
                onClick={removeAdjustment}
                loading={isLoading}
                data-userflow-id={PriceDrawerUserflowIds.REMOVE_ADJUSTMENTS_BUTTON}
              >
                {t('Remove Adjustments')}
              </Button>
              {isHideUploadPricesButton ? null : (
                <Button
                  onClick={(e: SyntheticEvent) => handleUploadPrices(e)}
                  disabled={isDisabled}
                  data-userflow-id={PriceDrawerUserflowIds.UPLOAD_PRICES_BUTTON}
                >
                  {t('Upload Prices')}
                </Button>
              )}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </form>
    </FormProvider>
  );
};
