import * as z from 'zod';
import { isMoreThanThirtyPercent } from '@pages/Client/Calendar/components/BulkEdit/helpers/isMoreThanThirtyPercent';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { isMoreThanTenPercent } from '@pages/Client/Calendar/components/BulkEdit/helpers/isMoreThanTenPercent';

export const baseFixedPrices = z.object({
  id: z.number(),
  adjustment: z.union([z.number(), z.nan(), z.null()]).optional(),
  baseValue: z.union([z.number(), z.nan(), z.null()]).optional(),
  isPricingCheeseLayer: z.boolean().default(false),
  isTenPercent: z.boolean().default(false),
  isAbsolute: z.boolean().default(false),
  isBulkEdit: z.boolean().default(false),
  value: z.union([z.number(), z.nan(), z.null()]).optional()
});

/**
 * Form Validation Logic for Fixed Prices Schema:
 *
 * - First Validation Rule:
 *   - Condition:
 *     - If 'isTenPercent' is true, the 'value' must be more than 10% higher than the 'baseValue'. This is controlled via a LaunchDarkly feature flag.
 *     - The 'value' must be more than 30% higher than the 'baseValue'.
 *   - Message:
 *     - If validation fails, the error message will be 'Please enter a higher fix price.'
 *       This message prompts the user to enter a value significantly higher than the base value.
 *
 * - Second Validation Rule:
 *   - Condition:
 *     - The 'value' must not have any decimal places.
 *   - Message:
 *     - If validation fails, the error message will be 'Value is not allowed.'
 *       This message indicates that the value entered should be an integer without fractional parts.
 *
 * These rules are applied to 'fixedPricesZodOutputSchema', which is based on 'baseFixedPrices'.
 * The combined validation ensures that the entered 'value' is both significantly higher than the base value
 * and is an integer.
 */

export const fixedPricesZodOutputSchema = baseFixedPrices
  .refine(
    (val) =>
      val.isPricingCheeseLayer ||
      !val.isTenPercent ||
      isMoreThanTenPercent(Number(val.value), Number(val.baseValue)),
    {
      message: 'Please set a price at least 10% of the base price.'
    }
  )
  .refine(
    (val) =>
      val.isPricingCheeseLayer ||
      val.isTenPercent ||
      isMoreThanThirtyPercent(Number(val.value), Number(val.baseValue)),
    {
      message: 'Please set a price at least 30% of the base price.'
    }
  )
  .refine((val) => validateNoDecimals(val?.value), {
    message: 'Value is not allowed.'
  });

export type FixedPrices = z.infer<typeof fixedPricesZodOutputSchema>;
