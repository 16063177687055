import { setAuthToken } from '@common/api/axiosInstance';
import { findBookingUrl } from '@common/api/hotel';
import { FindBookingUrlPayload } from '@common/api/hotel/types';
import { useAuthStore } from '@common/store/auth';
import { useCheckBookingUrlStore } from '@pages/Client/Account/Property/store/bookingUrl';
import { useMutation } from '@tanstack/react-query';

export const useFindBookingUrl = () => {
  const { setValidBookingUrl, setSuggestBookingUrl, setIsSuccessUrl } = useCheckBookingUrlStore();
  const { token } = useAuthStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: FindBookingUrlPayload) => {
      setAuthToken(token);
      return findBookingUrl(payload).then((res) => {
        if (res.data.success) {
          setValidBookingUrl(res.data.is_ref_url_valid);
          setSuggestBookingUrl(res.data.booking_ref_url);
          setIsSuccessUrl(true);
        } else {
          setSuggestBookingUrl(res.data.message);
          setValidBookingUrl(false);
          setIsSuccessUrl(false);
        }
      });
    },
    onError: () => {
      setValidBookingUrl(false);
      setSuggestBookingUrl('An error occurred while checking the booking URL');
      setIsSuccessUrl(false);
    }
  });

  return {
    findBookingUrl: mutate,
    isLoading,
    isSuccess
  };
};
