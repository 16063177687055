import { initDataPmsSync, resyncEviivoPms, resyncFreetobookPms } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useMutation } from '@tanstack/react-query';

export const useRequestFullSync = () => {
  const { addNotification } = useNotificationsStore();
  const { isHotelUseIntegrationV2 } = usePmsProvider();
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (provider: number | undefined) => {
      if (provider === PmsProvider.EVIIVO) {
        return resyncEviivoPms();
      }
      if (!isHotelUseIntegrationV2 && provider === PmsProvider.FREE_TO_BOOK) {
        return resyncFreetobookPms();
      } else {
        return initDataPmsSync();
      }
    },
    onSuccess: () => {
      addNotification('success', 'Inventory and Rates Reset Started');
    },
    onError: () => {
      addNotification('fail', 'Failed to start Inventory and Rates Reset');
    }
  });

  return {
    requestFullSync: mutate,
    isLoading
  };
};
