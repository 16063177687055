import { Icon } from '@common/components/foundations/icons';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { Typography } from '@common/components/foundations/Typography';
import { Button } from '@common/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { QuickMonthNavigator } from '@common/components/molecules/QuickMonthNavigator/QuickMonthNavigator';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Area, AreaChart, ResponsiveContainer, YAxis } from 'recharts';
import { isNumber } from 'lodash-es';
import {
  subWeeks,
  startOfWeek,
  startOfMonth,
  differenceInCalendarWeeks,
  addWeeks,
  addMonths,
  subMonths
} from 'date-fns';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Day, Returns } from 'use-lilius';
import { zonedTimeToUtc } from 'date-fns-tz';

export const DateNavigation = () => {
  const { t } = useTranslation();
  const { liliusInstance, isTableView, weekIndex, setWeekIndex } = useCalendarPageStore();
  const { hotelDetails } = useHotelDetails();

  const calculateWeekIndex = (date: Date, liliusInstance: Returns | undefined) => {
    if (!liliusInstance || !hotelDetails) return null;
    const startOfMonthDate = startOfMonth(date);
    const startOfWeekDate = startOfWeek(date, {
      weekStartsOn: hotelDetails?.starts_monday ? Day.MONDAY : Day.SUNDAY
    });

    const weekIndex = differenceInCalendarWeeks(startOfWeekDate, startOfMonthDate, {
      weekStartsOn: hotelDetails?.starts_monday ? Day.MONDAY : Day.SUNDAY
    });

    return weekIndex;
  };

  const toPrevious = () => {
    if (isTableView && liliusInstance?.calendar) {
      weekIndex !== null && weekIndex > 0 && setWeekIndex(weekIndex - 1);
      if (weekIndex === 0) {
        const startOfWeek = liliusInstance?.calendar[0][weekIndex][0];
        const previousWeekDate = subWeeks(startOfWeek, 1);
        liliusInstance?.setViewing(previousWeekDate);
        const newWeekIndex = calculateWeekIndex(previousWeekDate, liliusInstance);
        setWeekIndex(newWeekIndex);
      }
      return;
    }
    liliusInstance?.setViewing(subMonths(liliusInstance?.viewing, 1));
  };

  const toNext = () => {
    if (isTableView && liliusInstance?.calendar) {
      weekIndex !== null &&
        weekIndex < liliusInstance?.calendar[0].length - 1 &&
        setWeekIndex(weekIndex + 1);
      if (weekIndex === liliusInstance?.calendar[0].length - 1) {
        const startOfWeek = liliusInstance?.calendar[0][weekIndex][0];
        const previousWeekDate = addWeeks(startOfWeek, 1);
        liliusInstance?.setViewing(previousWeekDate);
        const newWeekIndex = calculateWeekIndex(previousWeekDate, liliusInstance);
        setWeekIndex(newWeekIndex);
      }
      return;
    }
    liliusInstance?.setViewing(addMonths(liliusInstance?.viewing, 1));
  };

  const toCurrentMonth = () => {
    liliusInstance?.setViewing(new Date());
  };

  const toCurrentWeek = () => {
    const today = new Date();
    liliusInstance?.setViewing(today);

    let calendarStart = liliusInstance?.calendar[0][0][0];
    if (!calendarStart) {
      calendarStart = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    // Ensure that calendarStart is set to the first day of the current month
    if (
      calendarStart.getMonth() !== today.getMonth() ||
      calendarStart.getDate() > today.getDate()
    ) {
      calendarStart = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    // Calculate the difference in days between today and the start of the month
    const dayDifference = Math.max(0, today.getDate() - calendarStart.getDate());

    // Calculate the week index
    const weekIndex = Math.round((dayDifference + 1) / 7);

    setWeekIndex(weekIndex);
  };

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;

  const { roomPrices } = useRoomPrices();
  const lightGrey = useTailwindColor('lightGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '11px';
  const fontWeight = 400;

  const currentMonth = dayjs(liliusInstance?.viewing).format('YYYY-MM');

  const CustomDot = (props: any) => {
    const { cx, cy, payload, index } = props;

    if (!payload.occupancy) {
      return null; // Return null if there's no occupancy
    }

    let xOffset = 0;
    if (index === 0)
      xOffset = 15; // Adjust these values accordingly
    else if (index === data.length - 1) xOffset = -15;

    // Check if the dot represents the current month
    if (payload.date === currentMonth) {
      // Render the dot along with the label
      return (
        <>
          <circle cx={cx + xOffset} cy={cy} r={4} fill={lightGrey} />
          <text
            x={cx + xOffset} // Use the offset here
            y={cy}
            dy={-10}
            textAnchor="middle"
            fill={lightGrey}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={fontFamily}
          >
            {Math.round(payload.occupancy) + '%'}
          </text>
        </>
      );
    }
    return null;
  };

  const data = useMemo(() => {
    return Array.from({ length: 12 })
      .map((_, i) => liliusInstance && dayjs(liliusInstance.viewing).month(i).format('YYYY-MM'))
      .map((month) => {
        if (!month) return;
        return {
          date: month,
          occupancy: roomPrices?.prices?.latest_actual_occupancy?.[month] ?? undefined
        };
      });
  }, [liliusInstance?.viewing]);

  const TransparentSegment = ({
    index,
    onClick
  }: {
    index: number;
    onClick: (index: number) => void;
  }) => (
    <div
      style={{
        position: 'absolute',
        left: `${index * (100 / 12)}%`,
        width: `${100 / 12}%`,
        top: 0,
        bottom: 0,
        cursor: 'pointer'
      }}
      onClick={() => onClick(index)}
    />
  );

  const handleSegmentClick = (index: number) => {
    // Calculate the exact date based on the index
    const date = dayjs(liliusInstance?.viewing).month(index).toDate();
    liliusInstance?.setViewing(date);
  };

  const week = isNumber(weekIndex) && liliusInstance?.calendar?.[0]?.[weekIndex];
  const weeksInCalendar = liliusInstance?.calendar?.[0].length;

  const handleYearChange = (year: number) => {
    liliusInstance?.viewYear(year);
  };

  const handleMonthChange = (month: number) => {
    if (!liliusInstance?.viewing) return;
    const hotelTimezone =
      hotelDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentYear = liliusInstance.viewing.getFullYear();
    const newDate = zonedTimeToUtc(new Date(currentYear, month, 1), hotelTimezone);
    liliusInstance?.setViewing(newDate);
  };

  return (
    <>
      <div className="min-w-[80px] md:min-w-[130px]">
        <QuickMonthNavigator
          label={t('Jump to Month')}
          currentYear={currentYear}
          previousYear={previousYear}
          nextYear={nextYear}
          currentDate={liliusInstance?.viewing}
          onYearChange={handleYearChange}
          onMonthChange={handleMonthChange}
          additionalInfo={
            <div className="flex items-center">
              <div className="relative h-[4.6rem] w-full max-w-xs rounded-sm shadow-sm">
                <Typography variant="meta-2" className="text-meta-2-semibold" color="grey">
                  {t('Your Occupancy')} {dayjs(liliusInstance?.viewing).format('YYYY')}
                </Typography>
                <div className="absolute bottom-0 left-0 right-0 opacity-60">
                  <ResponsiveContainer width="100%" height={60}>
                    <AreaChart
                      data={data}
                      margin={{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 5
                      }}
                    >
                      <defs>
                        <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor={lightGrey} stopOpacity={0.5} />
                          <stop offset="100%" stopColor={lightGrey} stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <YAxis hide={true} domain={[0, 'dataMax + 100']} />
                      <Area
                        dataKey="occupancy"
                        stroke={lightGrey}
                        dot={<CustomDot />}
                        isAnimationActive={false}
                        strokeWidth={1.5}
                        fill="url(#totalGradient)"
                        fillOpacity={0.3}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <TransparentSegment key={index} index={index} onClick={handleSegmentClick} />
                  ))}
                </div>
              </div>
            </div>
          }
        />
      </div>
      {isTableView && week && weeksInCalendar ? (
        <div className="flex-shrink-0">
          <Typography variant="meta-1" color="darkGrey" className="text-meta-1-medium">
            {t('W')}
            {dayjs(week[week.length - 1]).week()}
          </Typography>
        </div>
      ) : null}
      <div className="flex gap-x-1 text-grey">
        <Button type="button" data-testid="toPreviousMonthButton" icon onClick={toPrevious}>
          <Icon.ChevronLeft />
        </Button>
        {isTableView ? (
          <Button
            type="button"
            intent="text"
            onClick={toCurrentWeek}
            className="hidden whitespace-nowrap md:block"
          >
            <Typography element="span" variant="meta-1">
              {t('Current Week')}
            </Typography>
          </Button>
        ) : (
          <Button
            type="button"
            intent="text"
            onClick={toCurrentMonth}
            className="hidden whitespace-nowrap md:block"
          >
            <Typography element="span" variant="meta-1">
              {t('Current Month')}
            </Typography>
          </Button>
        )}
        <Button type="button" data-testid="toNextMonthButton" icon onClick={toNext}>
          <Icon.ChevronRight />
        </Button>
      </div>
    </>
  );
};
