import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import {
  BudgetResult,
  ForecastingResult,
  KPI,
  KPIData,
  NumberOfBookings,
  PickupData
} from '@common/api/dashboard/types';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { usePerformanceDashboardReservationKPI } from '@pages/Client/Dashboard/hooks/usePerformanceDashboardReservationKPI';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import {
  LastYearFilter,
  PerformanceFilter,
  useDashboardPageStore
} from '@pages/Client/Dashboard/store/dashboard';
import { usePickup } from '@pages/Client/Dashboard/hooks/usePickup';
import { useForecastingCache } from '@pages/Client/Dashboard/hooks/useForecastingCache';
import { useFeaturesStore } from '@common/store/features';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { PerformanceOptions } from '@pages/Client/Dashboard/pages/Performance/components/PerformanceOptions';
import { calculateLeadTime } from '@pages/Client/Dashboard/pages/Performance/utils/calculateLeadTime';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { PerformanceCard } from '@common/components/molecules/Card';
import {
  calculateDifferenceWithMessage,
  calculatePercentageIncreaseWithMessage
} from '@pages/Client/Dashboard/utils/diff';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { TabBar } from '@common/components/molecules/TabsBar';
import { DailyKPIChart } from '@pages/Client/Dashboard/components/DailyKPIChart';
import { MonthlyKPIChart } from '@pages/Client/Dashboard/components/MonthlyKPIChart';
import { Modal } from '@common/components/molecules/Modal';
import { Button } from '@common/components/atoms/Button';
import { MonthlyBookingCurve } from '@pages/Client/Dashboard/components/MonthlyBookingCurve';
import { MultiPropertyFilters } from '@pages/Client/Dashboard/components/MultiPropertyFilters';
import { useMultiPropertyFilters } from '@pages/Client/hooks/useMultiPropertyFilters';
import { usePerformanceFilter } from '@pages/Client/Dashboard/hooks/usePerformanceFilter';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import { isLeapYear } from '@common/utils/isLeapYear';
import { useDocumentTitle } from '@mantine/hooks';
import { useUserLocale } from '@common/hooks/useUserLocale';
import { useBudget } from '@pages/Client/Dashboard/hooks/useBudget';
import clsx from 'clsx';

const areObjectsEmpty = (data: KPI | undefined) => {
  if (!data) return true;
  const dataEntries = Object.entries(data ?? {});
  return dataEntries?.every(([_, value]) => {
    return isEmpty(value.total) && isEmpty(value.stly);
  });
};

type AllowedKPIKeys =
  | Extract<
      keyof KPIData<string>,
      'REVENUE' | 'NUMBER_OF_STAYS' | 'NUMBER_OF_ROOMS' | 'OCCUPANCY' | 'ADR' | 'REV_PAR'
    >
  | Extract<
      keyof KPIData<string>,
      'REVENUE_INCL_SERVICES' | 'ADR_INCL_SERVICES' | 'REV_PAR_INCL_SERVICES'
    >
  | Extract<keyof KPIData<string>, 'REVENUE_NET' | 'ADR_NET' | 'REV_PAR_NET'>
  | Extract<
      keyof KPIData<string>,
      'REVENUE_NET_INCL_SERVICES' | 'ADR_NET_INCL_SERVICES' | 'REV_PAR_NET_INCL_SERVICES'
    >;

type AllowedPickupKeys =
  | Extract<
      keyof PickupData<string>,
      | 'NUMBER_OF_BOOKINGS'
      | 'REVENUE'
      | 'NUMBER_OF_CANCELLATION'
      | 'CANCELLED_REVENUE'
      | 'NIGHTS_SOLD'
      | 'ADR'
    >
  | Extract<keyof PickupData<string>, 'REVENUE_INCL_SERVICES' | 'ADR_INCL_SERVICES'>
  | 'CANCELLED_REVENUE_INCL_SERVICES'
  | Extract<keyof PickupData<string>, 'REVENUE_NET' | 'ADR_NET' | 'CANCELLED_REVENUE_NET'>
  | Extract<
      keyof PickupData<string>,
      'REVENUE_NET_INCL_SERVICES' | 'ADR_NET_INCL_SERVICES' | 'CANCELLED_REVENUE_NET_INCL_SERVICES'
    >;

export const Performance = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Performance Reporting'));
  const { SparkIcon, isProDashboardUpselling, setUpsellingModalOpen, ProEntryPoints } =
    useUpselling();
  const {
    isMultipleHotels,
    shouldHighlightPropertyFilter,
    query: { isLoading: isMultiPropertyFiltersLoading }
  } = useMultiPropertyFilters();
  const { formatLocaleNumber } = useUserLocale();
  const [isMultiPropertyPopoverOpen, setIsMultiPropertyPopoverOpen] = useState(false);

  const indigoOpacity = 'rgb(91, 72, 238, 0.2)';

  const [isHovered, setIsHovered] = useState({
    monthlyBookingCurve: false,
    last90Days: false
  });

  const {
    performanceDashboardReservationKPI: {
      getKPITable,
      getMonthlyBookingCurve,
      getAllYears,
      getKPITableDailyByMonth
    },
    query: { data: performanceDashboardData, isLoading: isKPITableLoading }
  } = usePerformanceDashboardReservationKPI(true);

  const { lastYearFilter, setLastYearFilter, propertyIds, updatePropertyIds } =
    useDashboardPageStore();
  const { hotelDetails } = useHotelDetails();

  const isPerformanceDashboardEmpty = areObjectsEmpty(performanceDashboardData);
  const indigo = useTailwindColor('indigo');
  const multiPropertyFilterCount = hotelDetails?.id ? propertyIds?.[hotelDetails.id]?.length : 0;

  const {
    performanceOptions,
    currentPerformanceFilter,
    performanceOptionsDisabled,
    onPerformanceOptionChange,
    isHovered: isTaxModeHovered,
    setIsHovered: setIsTaxModeHovered
  } = usePerformanceFilter();

  const {
    pickup: { getPickup },
    query: { isLoading: isPickupLoading }
  } = usePickup(true);
  const {
    query: { data: forecastingCache, isLoading: isForecastingCacheLoading }
  } = useForecastingCache(true);
  const {
    query: { data: budgetData, isLoading: isBudgetDataLoading }
  } = useBudget(true);
  const { view, setView, liliusInstance, performanceFilter } = useDashboardPageStore();
  const { features } = useFeaturesStore();
  const [activePickupTab, setActivePickupTab] = useState(0);
  const [activeFinancialTab, setActiveFinancialTab] = useState(0);
  const [showMonthlyBookingCurve, setShowMonthlyBookingCurve] = useState(false);

  const allYears = getAllYears();
  const minYear = allYears[0];
  const maxYear = allYears[allYears.length - 1];

  const viewingDate = liliusInstance?.viewing;
  const viewingYear = viewingDate?.getFullYear();
  const viewingMonth = viewingDate?.getMonth();

  const toCurrentMonth = () => {
    liliusInstance?.setViewing(new Date());
  };

  const toPreviousMonth = () => {
    if (viewingYear && viewingMonth !== undefined) {
      const prevMonth = new Date(viewingYear, viewingMonth - 1);
      if (prevMonth.getFullYear() >= minYear) {
        liliusInstance?.viewPreviousMonth();
      }
    }
  };

  const toNextMonth = () => {
    if (viewingYear && viewingMonth !== undefined) {
      const nextMonth = new Date(viewingYear, viewingMonth + 1);
      if (nextMonth.getFullYear() <= maxYear) {
        liliusInstance?.viewNextMonth();
      }
    }
  };

  const prevButtonDisabled =
    view === 'monthly'
      ? viewingYear
        ? viewingYear <= minYear && viewingMonth === 0
        : false
      : viewingYear
        ? viewingYear <= minYear
        : false;

  const nextButtonDisabled =
    view === 'monthly'
      ? viewingYear
        ? viewingYear >= maxYear && viewingMonth === 11
        : false
      : viewingYear
        ? viewingYear >= maxYear
        : false;

  const openMonthlyBookingCurve = () => {
    setShowMonthlyBookingCurve(true);
  };

  const closeMonthlyBookingCurve = () => {
    setShowMonthlyBookingCurve(false);
  };

  const getKPIValues = (kpi: keyof KPIData<string>) => {
    if (!view) return null;
    const currentYear = dayjs(liliusInstance?.viewing)
      .tz()
      .format(view === 'monthly' ? 'YYYY-MM' : 'YYYY');
    const lastYear = dayjs(liliusInstance?.viewing)
      .tz()
      .subtract(1, 'year')
      .format(view === 'monthly' ? 'YYYY-MM' : 'YYYY');
    const total = getKPITable(view, 'total', currentYear)?.[kpi] ?? 0;
    const tly = getKPITable(view, 'total', lastYear)?.[kpi] ?? 0;
    const stly = getKPITable(view, 'stly', lastYear)?.[kpi] ?? 0;

    return { total, tly, stly };
  };

  const performanceKPILookup: Record<PerformanceFilter, AllowedKPIKeys[]> = {
    grossValuesExclExtraServices: [
      'REVENUE',
      'NUMBER_OF_STAYS',
      'NUMBER_OF_ROOMS',
      'OCCUPANCY',
      'ADR',
      'REV_PAR'
    ],
    grossValuesInclExtraServices: [
      'REVENUE_INCL_SERVICES',
      'ADR_INCL_SERVICES',
      'REV_PAR_INCL_SERVICES'
    ],
    netValuesExclExtraServices: ['REVENUE_NET', 'ADR_NET', 'REV_PAR_NET'],
    netValuesInclExtraServices: [
      'REVENUE_NET_INCL_SERVICES',
      'ADR_NET_INCL_SERVICES',
      'REV_PAR_NET_INCL_SERVICES'
    ]
  };

  const performancePickupLookup: Record<PerformanceFilter, AllowedPickupKeys[]> = {
    grossValuesExclExtraServices: [
      'REVENUE',
      'ADR',
      'NUMBER_OF_BOOKINGS',
      'NUMBER_OF_CANCELLATION',
      'NIGHTS_SOLD',
      'CANCELLED_REVENUE'
    ],
    grossValuesInclExtraServices: [
      'REVENUE_INCL_SERVICES',
      'ADR_INCL_SERVICES',
      'CANCELLED_REVENUE_INCL_SERVICES'
    ],
    netValuesExclExtraServices: ['REVENUE_NET', 'ADR_NET', 'CANCELLED_REVENUE_NET'],
    netValuesInclExtraServices: [
      'REVENUE_NET_INCL_SERVICES',
      'ADR_NET_INCL_SERVICES',
      'CANCELLED_REVENUE_NET_INCL_SERVICES'
    ]
  };

  const alwaysIncludedKPIKeys: AllowedKPIKeys[] = [
    'NUMBER_OF_STAYS',
    'NUMBER_OF_ROOMS',
    'OCCUPANCY'
  ];
  const alwaysIncludedPickupKeys: AllowedPickupKeys[] = [
    'NUMBER_OF_BOOKINGS',
    'NUMBER_OF_CANCELLATION',
    'NIGHTS_SOLD'
  ];

  const getKPIKeys = useMemo(
    () =>
      performanceFilter
        ? [...performanceKPILookup[performanceFilter], ...alwaysIncludedKPIKeys]
        : [],
    [performanceFilter]
  );

  const getPickupKeys = useMemo(
    () =>
      performanceFilter
        ? [...performancePickupLookup[performanceFilter], ...alwaysIncludedPickupKeys]
        : [],
    [performanceFilter]
  );

  const kpiValuesByFilter = (kpi: AllowedKPIKeys | undefined) => {
    if (!kpi) return null;

    const allowedKPIKeys = getKPIKeys;
    if (!allowedKPIKeys.includes(kpi)) return null;
    const values = () => {
      if (allowedKPIKeys.length > 0) {
        return allowedKPIKeys.reduce(
          (accumulator, currentKpi) => {
            const kpiValues = getKPIValues(currentKpi);
            return {
              ...accumulator,
              [currentKpi]: kpiValues
            };
          },
          {} as Record<keyof KPIData<string>, ReturnType<typeof getKPIValues>>
        );
      } else {
        return null;
      }
    };

    return values()?.[kpi];
  };

  const revenueKPIKey = getKPIKeys.find((kpi) => kpi.startsWith('REVENUE'));
  const adrKPIKey = getKPIKeys.find((kpi) => kpi.startsWith('ADR'));
  const revParKPIKey = getKPIKeys.find((kpi) => kpi.startsWith('REV_PAR'));

  const activeFinancialTabValue: Record<number, AllowedKPIKeys> = {
    0: revenueKPIKey ?? 'REVENUE',
    1: 'OCCUPANCY',
    2: adrKPIKey ?? 'ADR',
    3: revParKPIKey ?? 'REV_PAR'
  };

  const activePickupTabValue: Record<number, keyof NumberOfBookings<string>> = {
    0: 'pickup_yesterday',
    1: 'pickup_last_7_days',
    2: 'pickup_last_30_days',
    3: 'pickup_last_90_days'
  };

  const getPickupValues = (pickup: keyof PickupData<string>) => {
    if (!view) return null;
    const currentYear = dayjs(liliusInstance?.viewing)
      .tz()
      .format(view === 'monthly' ? 'YYYY-MM' : 'YYYY');
    const lastYear = dayjs(liliusInstance?.viewing)
      .tz()
      .subtract(1, 'year')
      .format(view === 'monthly' ? 'YYYY-MM' : 'YYYY');
    const total =
      getPickup(view, 'total', activePickupTabValue[activePickupTab], currentYear)?.[pickup] ?? 0;
    const stly =
      getPickup(view, 'stly', activePickupTabValue[activePickupTab], lastYear)?.[pickup] ?? 0;
    return { total, stly };
  };

  const pickupValuesByFilter = (pickup: AllowedPickupKeys | undefined) => {
    if (!pickup) return null;

    const allowedPickupKeys = getPickupKeys;
    if (!allowedPickupKeys.includes(pickup)) return null;
    const values = () => {
      if (allowedPickupKeys.length > 0) {
        return allowedPickupKeys.reduce(
          (accumulator, currentPickup) => {
            const pickupValues = getPickupValues(currentPickup);
            return {
              ...accumulator,
              [currentPickup]: pickupValues
            };
          },
          {} as Record<keyof PickupData<string>, ReturnType<typeof getPickupValues>>
        );
      } else {
        return null;
      }
    };

    return values()?.[pickup];
  };

  const getMonthlyBookingCurveValues = () => {
    const currentMonth = dayjs(liliusInstance?.viewing).tz().format('YYYY-MM');
    const lastYear = dayjs(liliusInstance?.viewing).tz().subtract(1, 'year').format('YYYY-MM');
    const total = getMonthlyBookingCurve('total', currentMonth);
    const stly = getMonthlyBookingCurve('stly', lastYear);
    return { total, stly };
  };

  const getDailyKPIValues = (kpi: keyof KPIData<string>, type: LastYearFilter = 'DTD') => {
    const currentMonth = dayjs(liliusInstance?.viewing).tz().format('YYYY-MM');
    const lastYear = dayjs(liliusInstance?.viewing).tz().subtract(1, 'year').format('YYYY-MM');
    function getAllDaysOfMonth(month: string) {
      const startDate = dayjs(month).startOf('month');
      const endDate = dayjs(month).endOf('month');
      const days = [];

      let currentDate = startDate;
      while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
        days.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }

      return days;
    }

    function getKPIData(dates: string[], kpiType: 'stly' | 'total', kpi: keyof KPIData<string>) {
      return dates.map((day) => {
        // Take next day to apply "DOW: Day of the Week" comparison
        let addDays = 1;

        if (isLeapYear(dayjs(day).add(1, 'year').year()) && dayjs(day).month() > 1) {
          // For leap years later than February, adjust the day addition to 2 days
          addDays = 2;
        }
        const valueDate =
          type === 'DOW' ? dayjs(day).add(addDays, 'day').format('YYYY-MM-DD') : day;

        return {
          date: day,
          value: getKPITableDailyByMonth(kpiType, kpi, valueDate)
        };
      });
    }

    const total = getKPIData(getAllDaysOfMonth(currentMonth), 'total', kpi);
    const stly = getKPIData(getAllDaysOfMonth(lastYear), 'stly', kpi);
    const tly = getKPIData(getAllDaysOfMonth(lastYear), 'total', kpi);

    return { total, stly, tly };
  };

  const forecastKeyLookup: Record<PerformanceFilter, keyof ForecastingResult> = {
    grossValuesExclExtraServices: 'forecasted_values_gross',
    grossValuesInclExtraServices: 'forecasted_values_gross',
    netValuesExclExtraServices: 'forecasted_values_net',
    netValuesInclExtraServices: 'forecasted_values_net'
  };

  const budgetKeyLookup: Record<PerformanceFilter, keyof BudgetResult> = {
    grossValuesExclExtraServices: 'revenue_budget_gross_excl_services',
    grossValuesInclExtraServices: 'revenue_budget_gross_excl_services',
    netValuesExclExtraServices: 'revenue_budget_net_excl_services',
    netValuesInclExtraServices: 'revenue_budget_net_excl_services'
  };

  const getMonthlyKPIValues = (kpi: keyof KPIData<string>) => {
    const currentYear = dayjs(liliusInstance?.viewing).tz().format('YYYY');
    const lastYear = dayjs(liliusInstance?.viewing).tz().subtract(1, 'year').format('YYYY');
    function getAllMonthsOfYear(year: string) {
      const startDate = dayjs(year).startOf('year');
      const endDate = startDate.endOf('year');
      const months = [];

      let currentDate = startDate;
      while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
        months.push(currentDate.format('YYYY-MM'));
        currentDate = currentDate.add(1, 'month');
      }
      return months;
    }

    const total = getAllMonthsOfYear(currentYear).map((month) => {
      return {
        date: month,
        value: getKPITable('monthly', 'total', month)?.[kpi] ?? 0
      };
    });

    const stly = getAllMonthsOfYear(lastYear).map((month) => {
      return {
        date: month,
        value: getKPITable('monthly', 'stly', month)?.[kpi] ?? 0
      };
    });

    const tly = getAllMonthsOfYear(lastYear)?.map((month) => {
      return {
        date: month,
        value: getKPITable('monthly', 'total', month)?.[kpi] ?? 0
      };
    });

    const budget = getAllMonthsOfYear(currentYear)?.map((month) => {
      return {
        date: month,
        value: budgetData?.[budgetKeyLookup[performanceFilter]]?.[month] ?? 0
      };
    });

    const forecast = getAllMonthsOfYear(currentYear)?.map((month) => {
      return {
        date: month,
        value:
          forecastingCache?.data?.forecasting_result?.[forecastKeyLookup[performanceFilter]]?.[
            month
          ] ?? 0
      };
    });

    return { total, stly, tly, budget, forecast };
  };

  const revenue = {
    total: kpiValuesByFilter(revenueKPIKey)?.total,
    tly: kpiValuesByFilter(revenueKPIKey)?.tly,
    stly: kpiValuesByFilter(revenueKPIKey)?.stly
  };

  const occupancy = {
    total: kpiValuesByFilter('OCCUPANCY')?.total,
    tly: kpiValuesByFilter('OCCUPANCY')?.tly,
    stly: kpiValuesByFilter('OCCUPANCY')?.stly
  };

  const adr = {
    total: kpiValuesByFilter(adrKPIKey)?.total,
    tly: kpiValuesByFilter(adrKPIKey)?.tly,
    stly: kpiValuesByFilter(adrKPIKey)?.stly
  };

  const revPar = {
    total: kpiValuesByFilter(revParKPIKey)?.total,
    tly: kpiValuesByFilter(revParKPIKey)?.tly,
    stly: kpiValuesByFilter(revParKPIKey)?.stly
  };

  const revenuePickupKey = getPickupKeys.find((pickup) => pickup.startsWith('REVENUE'));
  const adrPickupKey = getPickupKeys.find((pickup) => pickup.startsWith('ADR'));
  const cancelledRevenuePickupKey = getPickupKeys.find((pickup) =>
    pickup.startsWith('CANCELLED_REVENUE')
  );

  const revenuePickup = {
    total: pickupValuesByFilter(revenuePickupKey)?.total,
    stly: pickupValuesByFilter(revenuePickupKey)?.stly
  };

  const adrPickup = {
    total: pickupValuesByFilter(adrPickupKey)?.total,
    stly: pickupValuesByFilter(adrPickupKey)?.stly
  };

  const cancelledRevenuePickup = {
    total: pickupValuesByFilter(cancelledRevenuePickupKey)?.total,
    stly: pickupValuesByFilter(cancelledRevenuePickupKey)?.stly
  };

  const nightsSold = {
    total: pickupValuesByFilter('NIGHTS_SOLD')?.total,
    stly: pickupValuesByFilter('NIGHTS_SOLD')?.stly
  };

  const currentMonth = dayjs(liliusInstance?.viewing).tz().format('YYYY-MM');

  const forecast =
    view === 'monthly'
      ? formattedCurrency(
          forecastingCache?.data?.forecasting_result?.[forecastKeyLookup[performanceFilter]]?.[
            currentMonth
          ] ?? 0
        )
      : undefined;

  const budget =
    view === 'monthly'
      ? formattedCurrency(budgetData?.[budgetKeyLookup[performanceFilter]]?.[currentMonth] ?? 0)
      : undefined;

  const renderCustomAction = () => {
    return (
      <PerformanceOptions lastYearType={lastYearFilter} onLastYearTypeChange={setLastYearFilter} />
    );
  };

  const handleMonthDetailClick = (date: string) => {
    const parsedDate = dayjs(date).tz().toDate();
    liliusInstance?.setViewing(parsedDate);
    setView('monthly');
  };

  const currentLeadTime = useMemo(() => {
    const todayInTZ = dayjs().tz();
    const viewing = dayjs(viewingDate);
    return calculateLeadTime(todayInTZ, viewing);
  }, [viewingDate]);

  if (!view) return null;

  if (isPerformanceDashboardEmpty && !isKPITableLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Dashboard className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="h2" variant="h6" color="copyTextGrey">
                {t('Reservation data is not yet available')}
              </Typography>
              <Typography element="p" variant="paragraph-2" color="copyTextGrey">
                {t('We are currently setting up your dashboard. Please check back later.')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  const monthlyBookingCurveAction = () => {
    if (!ProEntryPoints.isProDashboardEntryPoint) {
      return null;
    }
    if (isProDashboardUpselling) {
      return setUpsellingModalOpen(true);
    }
    return openMonthlyBookingCurve();
  };

  const isKeyOfTaxModeHovered = (key: any): key is keyof typeof isTaxModeHovered => {
    return key in isTaxModeHovered;
  };
  const shouldRenderAdjacent = (option: { value: any; label?: string }) =>
    isProDashboardUpselling &&
    isKeyOfTaxModeHovered(option.value) &&
    isTaxModeHovered?.[option.value] &&
    performanceOptionsDisabled(option);

  const clearMultiPropertyFilters = () => {
    if (!hotelDetails?.id) return;
    updatePropertyIds(hotelDetails.id, []);
  };

  const selectedDateText = dayjs(liliusInstance?.viewing)
    .tz()
    .format(view === 'monthly' ? 'MMM YYYY' : 'YYYY');

  return (
    <div className="flex flex-col space-y-12">
      <div className="grid grid-flow-row gap-y-6">
        <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
          <div className="flex items-end gap-1.5">
            <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
              {t('On the Books')} {selectedDateText}
            </Typography>
            <TooltipIcon
              iconClassName="mb-1"
              content={t(
                'On the Books is the revenue that is either already received or due in future, from reservations that have been made up to this point for the stay dates specified. For example if a booking were made for 200 EUR of which a deposit of 20 EUR has been paid, OTB would be 200 EUR.'
              )}
            />
          </div>
          <div className="flex items-end gap-1">
            <div>
              {isMultiPropertyFiltersLoading ? <Skeleton className="mr-2 h-8 w-20" /> : null}
              {ProEntryPoints.isProDashboardEntryPoint && isMultipleHotels ? (
                <div className="group mr-1" data-html2canvas-ignore="true">
                  <Popover
                    open={isMultiPropertyPopoverOpen}
                    onOpenChange={(open) => {
                      if (isProDashboardUpselling) {
                        return setUpsellingModalOpen(true);
                      }
                      if (!open && isMultiPropertyPopoverOpen) {
                        setIsMultiPropertyPopoverOpen(false);
                      } else {
                        setIsMultiPropertyPopoverOpen(true);
                      }
                    }}
                  >
                    <PopoverTrigger className="relative" asChild>
                      <Button
                        intent="text"
                        className={`mr-1 bg-white px-4 py-2 ${
                          shouldHighlightPropertyFilter() ? 'bg-opacity-50' : ''
                        }`}
                        style={
                          shouldHighlightPropertyFilter()
                            ? {
                                backgroundColor: indigoOpacity
                              }
                            : {}
                        }
                      >
                        <Icon.Home className="mr-0.5 h-5 w-5" />
                        {`${t('Multi-Property Filter')}${
                          multiPropertyFilterCount && multiPropertyFilterCount !== 1
                            ? ` (${multiPropertyFilterCount})`
                            : ''
                        }`}
                        {multiPropertyFilterCount && multiPropertyFilterCount !== 1 ? (
                          <Icon.Clear
                            className="h-4 w-4 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              clearMultiPropertyFilters();
                            }}
                          />
                        ) : null}
                        {isProDashboardUpselling ? (
                          <span className="hidden group-hover:block">
                            <SparkIcon className="-mr-1" />
                          </span>
                        ) : null}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="bg-white text-darkGrey">
                      <MultiPropertyFilters onClose={() => setIsMultiPropertyPopoverOpen(false)} />
                    </PopoverContent>
                  </Popover>
                </div>
              ) : null}
            </div>
            <div className="flex items-center">
              {(features?.includes(25) || features?.includes(20)) &&
              ProEntryPoints.isProDashboardEntryPoint ? (
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    label={t('Tax Mode') as string}
                    selectedOption={
                      currentPerformanceFilter
                        ? {
                            value: currentPerformanceFilter?.value,
                            label: currentPerformanceFilter?.label
                              ? t(currentPerformanceFilter.label)
                              : ''
                          }
                        : undefined
                    }
                    options={performanceOptions.map((option) => ({
                      label: t(option.label) as string,
                      value: option.value,
                      renderAdjacent: () =>
                        shouldRenderAdjacent(option) ? <SparkIcon className="ml-1.5" /> : null,
                      onMouseEnter: () =>
                        setIsTaxModeHovered({ ...isTaxModeHovered, [option.value]: true }),
                      onMouseLeave: () =>
                        setIsTaxModeHovered({ ...isTaxModeHovered, [option.value]: false })
                    }))}
                    onOptionClick={(option) => {
                      if (
                        isProDashboardUpselling &&
                        performanceOptionsDisabled({ value: option.value })
                      ) {
                        return setUpsellingModalOpen(true);
                      }
                      return onPerformanceOptionChange(option.value);
                    }}
                  />
                </div>
              ) : (
                <div className="relative hidden items-center justify-self-end md:flex">
                  <Typography variant="meta-2" className="text-copyTextGrey">
                    {t(currentPerformanceFilter?.label as string)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex snap-x items-center justify-start gap-x-4 overflow-x-auto pb-1">
          <PerformanceCard
            topLeftText={t('Revenue')}
            topLeftTooltipIcon={
              <TooltipIcon
                content={t(
                  'Revenue refers to the total income generated by a property from its various services and offerings. Depending on the user choice we can show either revenue from Room Only (excl. Extra Services), or Total Revenue (incl. Extra Services), which includes food and beverage, and other ancillary services. Depending on the tax mode selected, we are displaying the revenue either including or excluding taxes.'
                )}
              />
            }
            topLeftValue={formattedCurrency(revenue.total)}
            totalLastYear={formattedCurrency(revenue.tly)}
            totalLastYearTooltipIcon={
              <TooltipIcon
                content={t(
                  'Total Last Year is the total revenue received in the year before, for the stay dates specified one year earlier. For example if we are looking at January 2025, then Total Last Year will be the total revenue received for stay dates in January 2024. This amount is calculated after the event, so takes into account all cancellations, upgrades etc.'
                )}
              />
            }
            sameTimeLastYear={formattedCurrency(revenue.stly)}
            sameTimeLastYearTooltipIcon={
              <TooltipIcon
                content={t(
                  'STLY looks at where your KPIs were at the same time last year. This enables you to compare how you are doing this year vs last year at the same lead time, and gives you early notice if you are doing better or worse. To give an example: if today is the 14th February 2025, and you are looking at your KPIs for March 2025, your STLY would be where your March 2024 occupancy, ADR and revenue were if we had taken the snapshot on 14th February 2024.'
                )}
              />
            }
            diff={calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly).content}
            negative={
              calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly).isNegative
            }
            // highlight={
            //   !calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly).isNegative
            // }
            isLoading={isKPITableLoading || isForecastingCacheLoading || isBudgetDataLoading}
            forecast={features?.includes(25) ? forecast : undefined}
            forecastTooltipIcon={
              <TooltipIcon
                content={t(
                  'RoomPriceGenie’s forecasting feature projects future revenue based on your budget revenue and target occupancy, and then continually reassesses based on current bookings. It allows you to predict future performance and see how your current bookings are performing relative to your budget.'
                )}
              />
            }
            budget={features?.includes(25) ? budget : undefined}
            budgetTooltipIcon={
              <TooltipIcon
                content={t(
                  'Your budget represents the anticipated monthly revenue. Here you can either include or exclude taxes, and either include or exclude services revenue. Typically, budgets are adjusted year-over-year to account for both inflation and changes in market dynamics.'
                )}
              />
            }
            chart={
              revenueKPIKey &&
              (view === 'monthly'
                ? getDailyKPIValues(revenueKPIKey)
                : getMonthlyKPIValues(revenueKPIKey)
              ).total.every(
                (item) => item.value === 0 || item.value === undefined || isNaN(item.value)
              ) ? undefined : (
                <ResponsiveContainer width="100%" height={36}>
                  <AreaChart
                    data={
                      view === 'monthly' && revenueKPIKey
                        ? getDailyKPIValues(revenueKPIKey).total
                        : revenueKPIKey && getMonthlyKPIValues(revenueKPIKey).total
                    }
                    margin={{
                      left: 0,
                      right: 0,
                      top: 5,
                      bottom: 10
                    }}
                  >
                    <defs>
                      <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="0%"
                          stopColor={
                            // !calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly)
                            //   .isNegative
                            //   ? lightGrey
                            //   : indigo
                            indigo
                          }
                          stopOpacity={0.5}
                        />
                        <stop
                          offset="100%"
                          stopColor={
                            // !calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly)
                            //   .isNegative
                            //   ? lightGrey
                            //   : indigo
                            indigo
                          }
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      dataKey="value"
                      stroke={
                        // !calculatePercentageIncreaseWithMessage(revenue.total, revenue.stly)
                        //   .isNegative
                        //   ? lightGrey
                        //   : indigo
                        indigo
                      }
                      dot={false}
                      isAnimationActive={false}
                      strokeWidth={1.5}
                      fill="url(#totalGradient)"
                      fillOpacity={0.6}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )
            }
          />
          <PerformanceCard
            topLeftText={t('Occupancy')}
            topLeftTooltipIcon={
              <TooltipIcon
                content={
                  <div className="flex flex-col gap-2">
                    <div>
                      {t(
                        'Occupancy is calculated as the ratio of sold rooms to the total available rooms, excluding any rooms that are out of order.'
                      )}
                    </div>
                    <div>
                      {t(
                        'Formula: Occupancy = Sold Rooms ÷ (Total Number of Rooms - Out of Order Rooms).'
                      )}
                    </div>
                  </div>
                }
              />
            }
            topLeftValue={formattedPercentage(occupancy.total)}
            totalLastYear={formattedPercentage(occupancy.tly)}
            sameTimeLastYear={formattedPercentage(occupancy.stly)}
            diff={formattedPercentage((occupancy.total ?? 0) - (occupancy.stly ?? 0))}
            negative={calculateDifferenceWithMessage(occupancy.total, occupancy.stly).isNegative}
            isLoading={isKPITableLoading}
            showPercentagePoint={true}
          />
          <PerformanceCard
            topLeftText={t('ADR')}
            topLeftTooltipIcon={
              <TooltipIcon
                content={
                  <div className="flex flex-col gap-2">
                    <div>
                      {t(
                        'Average Daily Rate represents the average revenue earned for an occupied room per day. It’s calculated by dividing the total revenue by the number of rooms sold.'
                      )}
                    </div>
                    <div>{t('Formula: ADR = Total Revenue ÷ Number of Sold Rooms.')}</div>
                  </div>
                }
              />
            }
            topLeftValue={formattedCurrency(adr.total)}
            totalLastYear={formattedCurrency(adr.tly)}
            sameTimeLastYear={formattedCurrency(adr.stly)}
            diff={formattedCurrency(
              Number(calculateDifferenceWithMessage(adr.total, adr.stly).content)
            )}
            negative={calculateDifferenceWithMessage(adr.total, adr.stly).isNegative}
            isLoading={isKPITableLoading}
          />
          <PerformanceCard
            topLeftText={t('RevPar')}
            topLeftTooltipIcon={
              <TooltipIcon
                content={
                  <div className="flex flex-col gap-2">
                    <div>
                      {t(
                        'Revenue Per Available Room measures the revenue generated per available room, incorporating both the average daily rate and occupancy rate.'
                      )}
                    </div>
                    <div>
                      {t('Formula: RevPAR = Total Room Revenue ÷ Total Number of Available Rooms.')}
                    </div>
                  </div>
                }
              />
            }
            topLeftValue={formattedCurrency(revPar.total)}
            totalLastYear={formattedCurrency(revPar.tly)}
            sameTimeLastYear={formattedCurrency(revPar.stly)}
            diff={formattedCurrency(
              Number(calculateDifferenceWithMessage(revPar.total, revPar.stly).content)
            )}
            negative={calculateDifferenceWithMessage(revPar.total, revPar.stly).isNegative}
            isLoading={isKPITableLoading}
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-6">
        <div className="flex items-center gap-1.5">
          <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
            {t('Recent Pickup for {{date}}', { date: selectedDateText })}
          </Typography>
          <TooltipIcon
            content={t(
              'Pickup is a representation of how many room nights were sold in a given time period in the past. For example, we might look at Pickup in the time period ’Yesterday’. Every booking made yesterday for a date in the future is Pickup from yesterday. This Pickup can be further filtered for certain stay-dates in the future. For example we might look at Pickup from yesterday for stay-date in March. Pickup is net/gross of cancellations; meaning that if we have 2 nights reserved and one night cancelled, Pickup will be 1/2 room night/s.'
            )}
          />
        </div>
        <TabBar
          type="linear"
          options={[
            { label: t('Yesterday'), value: 0 },
            { label: t('Last 7 Days'), value: 1 },
            { label: t('Last 30 Days'), value: 2 },
            ...(ProEntryPoints.isProDashboardEntryPoint
              ? [
                  {
                    label: t('Last 90 Days'),
                    value: 3,
                    renderAdjacent: () =>
                      isProDashboardUpselling && isHovered.last90Days ? <SparkIcon /> : null,
                    onMouseEnter: () => setIsHovered({ ...isHovered, last90Days: true }),
                    onMouseLeave: () => setIsHovered({ ...isHovered, last90Days: false })
                  }
                ]
              : [])
          ]}
          activeTab={activePickupTab}
          onTabClick={(value) => {
            if (value === 3 && isProDashboardUpselling) {
              return setUpsellingModalOpen(true);
            }
            return setActivePickupTab(value);
          }}
          action={view === 'monthly' ? monthlyBookingCurveAction : undefined}
          actionLabel={
            ProEntryPoints.isProDashboardEntryPoint
              ? (t('Open Booking Curve') as string)
              : undefined
          }
          actionLabelTooltipIcon={
            <TooltipIcon
              content={t(
                'The booking curve graphically represents the timing of bookings for a specific date or month, illustrating the lead time trends. The horizontal axis shows time to arrival in days, the vertical axis represents the occupancy booked. As we approach arrival day, occupancy tends to rise.'
              )}
              iconClassName="-ml-1.5 -mr-2"
            />
          }
          renderActionAdjacent={() =>
            isProDashboardUpselling && isHovered.monthlyBookingCurve ? <SparkIcon /> : null
          }
          onActionMouseEnter={() => setIsHovered({ ...isHovered, monthlyBookingCurve: true })}
          onActionMouseLeave={() => setIsHovered({ ...isHovered, monthlyBookingCurve: false })}
        />

        <div className="flex snap-x grid-cols-4 items-center justify-start gap-x-4 overflow-x-auto pb-1">
          <PerformanceCard
            topLeftText={t('Nights sold')}
            topLeftValue={formatLocaleNumber(nightsSold.total ?? 0)}
            sameTimeLastYear={formatLocaleNumber(nightsSold.stly ?? 0)}
            diff={calculateDifferenceWithMessage(nightsSold.total, nightsSold.stly).content}
            negative={calculateDifferenceWithMessage(nightsSold.total, nightsSold.stly).isNegative}
            small
            isLoading={isPickupLoading}
          />
          <PerformanceCard
            topLeftText={t('ADR')}
            topLeftValue={formattedCurrency(adrPickup.total)}
            sameTimeLastYear={formattedCurrency(adrPickup.stly)}
            diff={formattedCurrency(
              Number(calculateDifferenceWithMessage(adrPickup.total, adrPickup.stly).content)
            )}
            negative={calculateDifferenceWithMessage(adrPickup.total, adrPickup.stly).isNegative}
            small
            isLoading={isPickupLoading}
          />
          <PerformanceCard
            topLeftText={t('Pickup Revenue')}
            topLeftValue={formattedCurrency(revenuePickup.total)}
            sameTimeLastYear={formattedCurrency(revenuePickup.stly)}
            diff={formattedCurrency(
              Number(
                calculateDifferenceWithMessage(revenuePickup.total, revenuePickup.stly).content
              )
            )}
            negative={
              calculateDifferenceWithMessage(revenuePickup.total, revenuePickup.stly).isNegative
            }
            small
            isLoading={isPickupLoading}
          />
          {features?.includes(25) ? (
            <PerformanceCard
              topLeftText={t('Cancelled Revenue')}
              topLeftValue={formattedCurrency(cancelledRevenuePickup.total)}
              sameTimeLastYear={formattedCurrency(cancelledRevenuePickup.stly)}
              small
              isLoading={isPickupLoading}
            />
          ) : null}
        </div>
      </div>

      <div className="grid grid-flow-row gap-y-6">
        <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
          {t(`${view === 'monthly' ? 'Daily' : 'Monthly'} Key Performance Indicators`)}{' '}
          {selectedDateText}
        </Typography>
        <TabBar
          type="linear"
          options={[
            { label: t('Revenue'), value: 0 },
            { label: t('Occupancy'), value: 1 },
            { label: t('ADR'), value: 2 },
            { label: t('RevPar'), value: 3 }
          ]}
          activeTab={activeFinancialTab}
          onTabClick={setActiveFinancialTab}
          renderAction={
            features?.includes(25) && view === 'monthly' ? renderCustomAction : undefined
          }
        />

        {view === 'monthly' ? (
          <DailyKPIChart
            tly={getDailyKPIValues(activeFinancialTabValue[activeFinancialTab], lastYearFilter).tly}
            // Total (On the Books) calculation should always be made regarding default value: DTD
            total={getDailyKPIValues(activeFinancialTabValue[activeFinancialTab]).total}
            stly={
              getDailyKPIValues(activeFinancialTabValue[activeFinancialTab], lastYearFilter).stly
            }
            isCurrency={activeFinancialTabValue[activeFinancialTab] === 'OCCUPANCY' ? false : true}
          />
        ) : null}
        {view === 'yearly' ? (
          <MonthlyKPIChart
            tly={getMonthlyKPIValues(activeFinancialTabValue[activeFinancialTab]).tly}
            total={getMonthlyKPIValues(activeFinancialTabValue[activeFinancialTab]).total}
            stly={getMonthlyKPIValues(activeFinancialTabValue[activeFinancialTab]).stly}
            budget={
              activeFinancialTab === 0
                ? getMonthlyKPIValues(activeFinancialTabValue[activeFinancialTab]).budget
                : undefined
            }
            forecast={
              activeFinancialTab === 0
                ? getMonthlyKPIValues(activeFinancialTabValue[activeFinancialTab]).forecast
                : undefined
            }
            isCurrency={activeFinancialTabValue[activeFinancialTab] === 'OCCUPANCY' ? false : true}
            onMonthDetailClick={handleMonthDetailClick}
          />
        ) : null}
      </div>

      <Modal
        open={showMonthlyBookingCurve}
        size="full"
        onClose={closeMonthlyBookingCurve}
        showFooter={false}
      >
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex">
            <div className="flex flex-col gap-y-1">
              <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
                {t('Monthly Booking Curve')}
              </Typography>
            </div>
          </div>
          <div className="flex flex-col flex-wrap gap-4 md:flex-row md:items-center">
            <div className="flex flex-1 items-center gap-x-4">
              <div
                role="button"
                className={clsx('min-w-[40px] cursor-pointer md:min-w-[60px]')}
                onClick={toCurrentMonth}
              >
                <Typography variant="h5" className="text-meta-1 font-medium" color="darkGrey">
                  {dayjs(liliusInstance?.viewing).tz().format('MMM YYYY')}
                </Typography>
              </div>
              <div className="flex gap-x-1 text-grey">
                <Button icon onClick={toPreviousMonth} disabled={prevButtonDisabled}>
                  <Icon.ChevronLeft />
                </Button>
                <Button
                  intent="text"
                  onClick={toCurrentMonth}
                  className="hidden whitespace-nowrap md:block"
                >
                  <Typography element="span" variant="meta-1">
                    {t('Current Month')}
                  </Typography>
                </Button>
                <Button icon onClick={toNextMonth} disabled={nextButtonDisabled}>
                  <Icon.ChevronRight />
                </Button>
              </div>
              <div className="flex-1" />
            </div>
          </div>
          <MonthlyBookingCurve
            total={getMonthlyBookingCurveValues().total}
            stly={getMonthlyBookingCurveValues().stly}
            currentLeadTime={currentLeadTime}
          />
        </div>
      </Modal>
    </div>
  );
};
