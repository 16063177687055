import dayjs from 'dayjs';
import { useMemo } from 'react';
import styles from '@pages/Client/Calendar/components/BigCalendar/CalendarView.module.css';
import { Typography } from '@common/components/foundations/Typography';
import { API_DATE_FORMAT } from '@common/constants/date';
import classNames from 'classnames';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { usePriceKpiContent } from '@pages/Client/Calendar/hooks/usePriceKpiContent';
import { useViewStore } from '@common/store/view';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useHotelPricePerOccupancy } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPricePerOccupancy';
import { AdjustmentType, CALENDAR_PAGES } from '@pages/Client/Calendar/constants';
import { Tooltip } from '@radix-ui/react-tooltip';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { utcToZonedTime } from 'date-fns-tz';
import { isBefore } from 'date-fns';
import { cn } from '@common/utils/cn';
import { Icon } from '@common/components/foundations/icons';
import { useInflation } from '@pages/Client/Calendar/hooks/useInflation';
import { CalendarViewProps } from '@pages/Client/Calendar/components/BigCalendar/CalendarView';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useDate } from '@common/hooks/useDate';

export interface DateCellProps extends CalendarViewProps {
  day: Date;
  weekIndex: number;
  dayIndex: number;
  calendarLength: number;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseEnter?: () => void;
  selected?: boolean;
}

export const DateCell = ({
  day,
  liliusInstance: lilius,
  onCellClick,
  dateCellContent,
  topRightCellContent,
  dateCellClassName,
  context = 'pricing',
  pricingSettings,
  selectedRoomId,
  roomPrices,
  isLoading,
  tooltipExtraContent,
  getPricingCellContent,
  onMouseDown,
  onMouseEnter,
  onMouseUp,
  selected,
  weekIndex,
  dayIndex,
  calendarLength,
  surgeEvent
}: DateCellProps) => {
  const { t } = useTranslation();
  const {
    comparePickupTo,
    adjustment,
    isTableView,
    minStayDays,
    visualMinStayDays,
    setVisualMinStayDays
  } = useCalendarPageStore();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { selectedHotelRoom } = useHotelRoomsList();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const { pmsList } = useGetHotelPmsList();

  const { getSurgePriceForDay } = useSurgePrice();

  const spaceName = hotelDetails?.room_apartment_space_name ?? 'Room';

  const { isToday, isPastDate } = useDate();
  const key = dayjs(day).format(API_DATE_FORMAT);

  const basePriceKpiContent = usePriceKpiContent(key);
  const { view } = useViewStore();

  const handleDayClick = (day: Date) => {
    if (!basePriceKpiContent) {
      return;
    }
    lilius.toggle(day, true);
    onCellClick?.(day);
  };

  const { cachedPriceData, propertyData } = useMemo(() => {
    if (!selectedRoomId) return {};

    return {
      cachedPriceData: roomPrices?.prices?.data?.[key]?.[selectedRoomId],
      propertyData: roomPrices?.prices?.data?.[key]?.property
    };
  }, [selectedRoomId, day, roomPrices]);

  const priceKpiContent = useMemo(() => {
    if (!selectedRoomId || !cachedPriceData || !basePriceKpiContent) return;

    const defaultRoomPriceData = pricingSettings?.default[selectedRoomId];

    if (!defaultRoomPriceData) return;

    const fixPrice = pricingSettings?.dates?.[key]?.[selectedRoomId]?.fix_price;
    const cellContent = getPricingCellContent?.(day);
    const { surgePrice, isSurgePrice } = getSurgePriceForDay(key, selectedRoomId);

    return {
      ...basePriceKpiContent,
      recommendedPrice: CurrencyFormatter.format(cachedPriceData.suggested_price),
      fixPrice: fixPrice ? CurrencyFormatter.format(fixPrice) : null,
      barLevel: pricingSettings.features?.includes(Feature.BarLevels)
        ? CurrencyFormatter.format(cachedPriceData.suggested_price)
        : undefined,
      trending: cellContent?.trending,
      surgePrice: CurrencyFormatter.format(surgePrice),
      isSurgePrice
    };
  }, [selectedRoomId, cachedPriceData, basePriceKpiContent]);

  const isDisabled = isPastDate(day);

  const formattedDate = dayjs(day).format('DD');

  const roomsSold = useMemo(() => {
    if (!propertyData) return 'n.A';

    return Math.max(0, propertyData.number_of_rooms - propertyData.inventory_remaining);
  }, [propertyData]);

  const expectedRoomSold = useMemo(() => {
    if (!propertyData) return 'n.A';

    return propertyData.expectedroomssold_adjusted
      ? Math.round(propertyData.expectedroomssold_adjusted)
      : propertyData.expectedroomssold_adjusted;
  }, [propertyData]);

  const pickupKeys = {
    yesterday: 'roomsleft_change_vs_yesterday',
    'last-week': 'roomsleft_change_vs_7daysago',
    'last-month': 'roomsleft_change_vs_30daysago'
  } as const;

  const mappedDataItem = hotelPmsDataMap?.mapped_data.find(
    (item) => item.id === selectedHotelRoom?.pms_room
  );

  const { hotelPricePerOccupancy } = useHotelPricePerOccupancy(selectedRoomId);

  const minOccupancy = mappedDataItem?.min_occupancy;
  const maxOccupancy = mappedDataItem?.max_occupancy;
  const defaultOccupancy = selectedHotelRoom?.default_occupancy;

  const occupancies = useMemo(() => {
    if (minOccupancy !== undefined && maxOccupancy !== undefined) {
      return Array.from({ length: maxOccupancy - minOccupancy + 1 }, (_, i) => minOccupancy + i);
    } else {
      return [];
    }
  }, [minOccupancy, maxOccupancy, defaultOccupancy]);

  const isOccupancyPercentDerivation = pmsList?.find(
    (pms) => pms.primary
  )?.occupancy_percent_derivation;

  const { inflationByMonth, isAutomaticInflationIncrease } = useInflation(day);

  return (
    <TooltipProvider delayDuration={300} disableHoverableContent>
      <Tooltip>
        <TooltipTrigger
          data-testid={dayjs(day).format(API_DATE_FORMAT)}
          className={cn(
            'h-full overflow-hidden',
            minStayDays?.[key] && adjustment === AdjustmentType.MinStay
              ? 'ring-inset ring-darkGreen hover:ring-2'
              : null,
            visualMinStayDays.includes(key)
              ? 'bg-darkGreen-reduced transition-colors duration-150'
              : null,
            styles.dateCell,
            !isDisabled && 'selectable',
            selected && 'bg-indigo-reduced',
            'w-full',
            weekIndex === 0 && dayIndex === 0 ? 'rounded-tl-xl' : null,
            weekIndex === 0 && dayIndex === 6 ? 'rounded-tr-xl' : null,
            weekIndex === calendarLength - 1 && dayIndex === 0 && !isTableView
              ? 'rounded-bl-xl'
              : null,
            weekIndex === calendarLength - 1 && dayIndex === 6 && !isTableView
              ? 'rounded-br-xl'
              : null,
            isTableView ? 'bg-opacity-50' : null
          )}
          data-in-range={dayjs(day).isSameOrAfter(dayjs(lilius.viewing).startOf('month'))}
          data-disabled={isDisabled}
          data-today={isToday(day)}
          data-after-today={dayjs(day).isSameOrAfter(dayjs(), 'day')}
          data-key={dayjs(day).format(API_DATE_FORMAT)}
          onClick={() => {
            handleDayClick(day);
          }}
          onMouseLeave={() => {
            if (adjustment === AdjustmentType.MinStay) {
              setVisualMinStayDays([]);
            }
          }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseEnter={() => {
            if (adjustment === AdjustmentType.MinStay) {
              if (minStayDays?.[key]) {
                setVisualMinStayDays(minStayDays?.[key].dates.map((date) => date.date));
              }
            }
            onMouseEnter?.();
          }}
          type="button"
        >
          <div className={cn(dateCellClassName?.(day), 'relative h-full')}>
            {surgeEvent?.(day).isSurgeEvent ? (
              <span className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-0 flex items-center justify-center opacity-40">
                <Icon.Ripple className="h-10 w-10" />
              </span>
            ) : null}
            <div className="flex h-full min-h-[100px] w-full flex-col justify-between pb-1.5 pl-2 pr-2 pt-1.5 lg:pl-4 lg:pr-3">
              <div className="flex items-center justify-between gap-4">
                <div>
                  <div className={styles.dateText}>
                    <span className="z-10 truncate">
                      {formattedDate} {isToday(day) ? t('Today') : null}
                    </span>
                  </div>
                </div>

                <div
                  className={cn(
                    'max-w-[80px] truncate text-meta-2 xl:max-w-[120px]',
                    styles.contentText
                  )}
                >
                  {isLoading ? <Skeleton className="h-3 w-10" /> : topRightCellContent?.(day)}
                </div>
              </div>

              <div>
                <div className={styles.contentText}>
                  {isLoading ? (
                    <div className="flex items-end justify-between">
                      <Skeleton className="h-3 w-5" />

                      <div className="flex flex-col items-end gap-1">
                        <Skeleton className="h-3 w-10" />
                        <Skeleton className="h-3 w-20" />
                      </div>
                    </div>
                  ) : (
                    dateCellContent?.(day)
                  )}
                </div>
              </div>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom" sideOffset={10} className="w-[326px]" hidden={isTableView}>
          {adjustment === AdjustmentType.Occupancy ? (
            <LineTable className="text-white">
              <thead>
                <tr>
                  <th className="text-meta-3-medium text-mediumGrey">{t('Occ.')}</th>
                  <th className="text-meta-3-medium text-mediumGrey">{t('Price Difference')}</th>
                </tr>
              </thead>

              <tbody>
                {occupancies.map((occupancy) => {
                  const hotelPricePerOccupancyDefaultDerivation = hotelPricePerOccupancy?.find(
                    (occupancyPrice) => occupancyPrice.occupancy === occupancy
                  )?.derivation;

                  if (!selectedRoomId) return null;

                  return (
                    <tr
                      key={`occupancy-${occupancy}`}
                      className={cn(
                        occupancy === defaultOccupancy ? 'bg-grey bg-opacity-10' : null
                      )}
                    >
                      <td align="right">{occupancy}</td>
                      <td>
                        {occupancy === defaultOccupancy ? (
                          <Typography color="white">
                            {t('Base Price')}: {CurrencyFormatter.currencySymbol()}
                            {pricingSettings?.default?.[selectedRoomId]?.avg_price}
                          </Typography>
                        ) : (
                          <div className="flex w-full items-center">
                            <Typography color="white">
                              {(defaultOccupancy || 0) < occupancy ? `+` : `-`}

                              {isOccupancyPercentDerivation
                                ? Math.round(
                                    pricingSettings?.dates?.[key]?.[selectedRoomId]
                                      ?.occupancy_pricing?.[occupancy] ??
                                      hotelPricePerOccupancyDefaultDerivation ??
                                      0
                                  ) + '%'
                                : CurrencyFormatter.format(
                                    pricingSettings?.dates?.[key]?.[selectedRoomId]
                                      ?.occupancy_pricing?.[occupancy] ??
                                      hotelPricePerOccupancyDefaultDerivation ??
                                      0
                                  )}
                            </Typography>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </LineTable>
          ) : (
            <>
              <h5 className="text-meta-3-medium text-mediumGrey">{t('Prices')}</h5>

              <div className="mt-2">
                <div className="mt-1 flex justify-between">
                  <span className="text-paragraph-3">{`${t('Base Price')}${
                    isAutomaticInflationIncrease && inflationByMonth > 1
                      ? ` (${t('adj. for inflation')})`
                      : ''
                  }`}</span>
                  <span className="text-paragraph-3 font-medium">
                    {priceKpiContent?.basePriceValue ?? 'n.A.'}
                  </span>
                </div>

                {priceKpiContent?.breakdownPrice?.map((item, index) => (
                  <div key={`priceKpiContent-${index}`} className="mt-1 flex justify-between">
                    <span className="text-paragraph-3">
                      {item.title} {item.isUpselling ? `(${t('Disabled')})` : null}
                    </span>
                    <div className="flex items-center text-paragraph-3 font-medium">
                      {item.isUpselling ? (
                        <span className="blur-sm">{t('n.A.')}</span>
                      ) : (
                        <>
                          <span className="flex items-center justify-center text-meta-1 text-mediumGrey">
                            <span>{item.icon}</span>
                            <span>{item.percentage}</span>
                          </span>

                          <span className="ml-1">{item.value}</span>
                        </>
                      )}
                    </div>
                  </div>
                ))}

                <div className="mt-1 flex justify-between">
                  <span
                    className={cn(
                      'text-paragraph-3',
                      surgeEvent?.(day).hasSurgePrice ? 'inline-flex items-center gap-1' : null
                    )}
                  >
                    {surgeEvent?.(day).hasSurgePrice ? <Icon.Ripple className="h-4 w-4" /> : null}
                    {surgeEvent?.(day).hasSurgePrice
                      ? t('Protection Price')
                      : priceKpiContent?.barLevel
                        ? t('Recommended BAR Level')
                        : t('Recommended Price')}
                  </span>
                  <span
                    className={classNames(
                      'text-paragraph-3 font-medium',
                      priceKpiContent?.trending === 'up'
                        ? 'font-semibold text-uiGreen'
                        : priceKpiContent?.trending === 'down'
                          ? 'font-semibold text-uiRed'
                          : // What if there's fix price?
                            ''
                    )}
                  >
                    <span className="ml-1">
                      {priceKpiContent?.isSurgePrice
                        ? priceKpiContent.surgePrice
                        : priceKpiContent?.barLevel ?? priceKpiContent?.recommendedPrice}
                    </span>
                  </span>
                </div>

                {priceKpiContent?.fixPrice && (
                  <div className="mt-1 flex justify-between">
                    <span className="text-paragraph-3">{t('Fix Price')}</span>
                    <span className="text-paragraph-3 font-medium">
                      <span className="ml-1">{priceKpiContent?.fixPrice}</span>
                    </span>
                  </div>
                )}

                <div className="mt-1 flex justify-between">
                  <span className="text-paragraph-3">{t('Price in PMS')}</span>
                  <span className={classNames('text-paragraph-3 font-medium')}>
                    <span className="ml-1">
                      {CurrencyFormatter.format(cachedPriceData?.original_price) ?? 'n.A.'}
                    </span>
                  </span>
                </div>
              </div>

              <div className="mt-4 border-t border-lightGrey py-4">
                <h5 className="text-meta-3-medium text-mediumGrey">{t('Occupancy')}</h5>

                <div className="mt-1 flex justify-between">
                  <span className="text-paragraph-3">{t(`Total ${spaceName}s Sold`)}</span>
                  <span className="text-paragraph-3 font-medium">{roomsSold}</span>
                </div>

                {view === 'admin' && context === 'pricing' && (
                  <div className="mt-1 flex justify-between">
                    <span className="text-paragraph-3">
                      {t(`Total Expected ${spaceName}s Sold`)}
                    </span>
                    <span className="text-paragraph-3 font-medium">{expectedRoomSold}</span>
                  </div>
                )}

                <div className="mt-1 flex justify-between">
                  <span className="text-paragraph-3">{t('Occupancy')}</span>
                  <span className="text-paragraph-3 font-medium">
                    {roomPrices?.prices.data?.[key]?.property?.occupancy ?? 0}%
                  </span>
                </div>

                {[CALENDAR_PAGES.OCCUPANCY, CALENDAR_PAGES.PICKUP].includes(context) &&
                features?.includes(Feature.PickupCalendar) ? (
                  <div className="mt-1 flex justify-between">
                    <span className="text-paragraph-3">{t('Pickup')}</span>
                    <span className="text-paragraph-3 font-medium">
                      {isPastDate(day)
                        ? '-'
                        : roomPrices?.pickup_boost_prices?.[key]?.arguments?.[
                            pickupKeys[comparePickupTo]
                          ] ?? 0}
                    </span>
                  </div>
                ) : null}
              </div>

              {tooltipExtraContent &&
                tooltipExtraContent(day).map((content) => (
                  <div
                    key={`tooltipExtraContent-${content.title}`}
                    className="border-t border-lightGrey py-4"
                  >
                    <h5 className="text-meta-3-medium text-mediumGrey">{content.title}</h5>

                    {content.breakdownContent.map((item, index) => (
                      <div
                        key={`tooltipExtraContent-breakdown-${index}`}
                        className="mt-1 flex justify-between"
                      >
                        <span className="text-paragraph-3">{item.leftText}</span>
                        <span className="text-paragraph-3 font-medium">{item.rightText}</span>
                      </div>
                    ))}
                  </div>
                ))}
            </>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
