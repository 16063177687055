import { FormInput } from '@common/components/atoms/Input';
import { Fragment, useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Switcher } from '@common/components/atoms/Switcher';
import { isEmpty, isNil, map, result } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Icon } from '@common/components/foundations/icons';
import { absoluteOrPercentage } from '@pages/Client/Calendar/components/BulkEdit/helpers/absoluteOrPercentage';
import { useFormPreflight } from '@pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { FixedPrices } from '@pages/Client/Calendar/components/BulkEdit/types/schema/fixedPricesSchema';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useViewStore } from '@common/store/view';
import { Group, Indicator, rem, Stack, Text, Tooltip } from '@mantine/core';
import { Flags } from '@common/constants/flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRecommendedPrice } from '@pages/Client/Calendar/components/Tables/hooks';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function FixedPricesAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.FIX_PRICES;
  const flags = useFlags();
  const { hotelDetails } = useHotelDetails();
  const { view } = useViewStore();
  const { pricingSettings, getRoomData } = useRoomPrices();
  const pricingDefault = result(pricingSettings, 'default');
  const { displayHotelRooms } = useHotelRoomsList();
  const { preflightCallback, isUpdating } = useFormPreflight();
  const [prefillDerivedRooms, setPrefillDerivedRooms] = useState(true);
  const adjustmentFields: FixedPrices[] = useBuildAdjustmentInputsArray<FixedPrices>(formName);
  const { control, formState, trigger, setValue, getValues, watch } = useFormContext();
  const { fields, replace } = useFieldArray({ name: formName, control });
  const editDate = getValues('editDate');
  const isBulkEdit = getValues('isBulkEdit');
  const { recommendedPriceData } = useRecommendedPrice(dayjs(editDate));

  function updatePrefillDerivedRooms(name?: string) {
    if (prefillDerivedRooms && name === `${formName}.0.value`) {
      map(fields, (_, index) => {
        const value = Number(getValues(`${formName}.0.value`));
        const isAbsolute = Boolean(getValues(`${formName}.${index}.isAbsolute`));
        const adjustment = Number(getValues(`${formName}.${index}.adjustment`));
        if (index > 0) {
          setValue(
            `${formName}.${index}.value`,
            absoluteOrPercentage(isAbsolute, value, adjustment)
          );
        }
      });
    }
    trigger(formName);
  }

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      updatePrefillDerivedRooms(name);
      trigger(formName);
      if (type === 'change') {
        preflightCallback();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, prefillDerivedRooms, fields]);

  useEffect(() => {
    if (!flags[Flags.PricingCheeseLayers]) return;
    preflightCallback();
  }, [editDate]);

  return (
    <Fragment>
      <Group justify="space-between" align="center">
        {pricingSettings?.hotel?.all_room_pricing ? (
          <Switcher
            checked={prefillDerivedRooms}
            onChange={setPrefillDerivedRooms}
            size="small"
            label={`${t(`Prefill Derived ${hotelDetails?.room_apartment_space_name}s`)}`}
            className="text-sm font-medium text-copyTextGrey"
          />
        ) : null}
        {!isBulkEdit && isUpdating ? (
          <Icon.LoadingCircle className="ml-2 inline-block opacity-50" />
        ) : null}
      </Group>
      <LineTable>
        <thead>
          <tr>
            <th>{hotelDetails?.room_apartment_space_name}</th>
            <th>{t('Fixed Price')}</th>
            {!isBulkEdit && view === 'admin' ? <th>{t('Recommended Price')}</th> : null}
            {!isBulkEdit && view === 'admin' ? <th>{t('Price in PMS')}</th> : null}
          </tr>
        </thead>

        <tbody>
          {map(fields, (field, index) => {
            const room = displayHotelRooms[index];
            const error = result(formState, `errors.${formName}[${index}].message`);
            const errorApi = result(
              formState,
              `errors.apiErrors.${formName}[${room.id}].fixPrice.message`
            );
            const errorPriceTooLow = result(
              formState,
              `errors.apiErrors.priceTooLow.${room?.id}.priceTooLow.message`
            );
            return !isNil(room) ? (
              <tr key={field.id}>
                <td>
                  {room?.name} {`${import.meta.env.DEV ? room?.id : ''}`}
                </td>
                <td>
                  <div className="flex items-center justify-start align-middle">
                    <div>
                      <FormInput
                        type="number"
                        nameKey={`${formName}.${index}.id` as const}
                        valueKey={`${formName}.${index}.value` as const}
                        background="grey"
                        leadingAddon={CurrencyFormatter.currencySymbol()}
                        trailingAddon={
                          <Stack gap={rem(2)}>
                            <Text size="xxs">{t('Base Price')}</Text>
                            <Text size="xxs" ta="right">
                              {CurrencyFormatter.format(
                                Math.round(
                                  result(
                                    pricingDefault,
                                    `${room.id}.${ADJUSTMENT_DB.AVG_PRICE_KEY}`
                                  )
                                )
                              )}
                            </Text>
                          </Stack>
                        }
                        helperMessage={error ?? errorApi}
                        showClearButton={false}
                      />
                    </div>
                  </div>
                </td>
                {!isBulkEdit && view === 'admin' ? (
                  <>
                    <td className="text-center">
                      <Tooltip
                        label={<>{errorPriceTooLow}</>}
                        disabled={!errorPriceTooLow}
                        position="bottom"
                      >
                        <Indicator
                          disabled={!errorPriceTooLow}
                          color="red"
                          processing
                          inline
                          offset={-4}
                        >
                          {CurrencyFormatter.format(
                            Math.round(recommendedPriceData?.[room?.id].suggested_price ?? 0),
                            '-'
                          )}
                        </Indicator>
                      </Tooltip>
                    </td>
                    <td className="text-center">
                      {CurrencyFormatter.format(
                        getRoomData(room.id, dayjs(editDate).format(API_DATE_FORMAT))
                          ?.original_price,
                        '-'
                      )}
                    </td>
                  </>
                ) : null}
              </tr>
            ) : null;
          })}
        </tbody>
      </LineTable>
    </Fragment>
  );
}
