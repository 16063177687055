import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { YearlyMonthly } from '@common/types';
import { browserTimezone } from '@common/utils/browserTimezone';
import { SegmentedControl, Anchor } from '@mantine/core';
import { usePaymentStore } from '@pages/Client/Account/Subscription/store/subscription';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { IconReceiptOff } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SubscriptionHeader = () => {
  const { t } = useTranslation();
  const today = dayjs();
  const { setIsMonthly, isMonthly } = usePaymentStore();
  const { hotelDetails: hotel } = useHotelDetails();
  const { isAdmin } = useViewStore();
  const daysLeft = Math.ceil(dayjs(hotel?.free_trial_end).diff(today, 'day', true));

  const [activeTab, setActiveTab] = useState<YearlyMonthly>(YearlyMonthly.Yearly);
  const {
    features: hotelFeatures,
    hasAdvancedUploadFeature,
    hasProUploadFeature,
    hasSmallBusinessFeature,
    hasStarterUploadFeature
  } = useFeaturesStore();

  const isDayLeft = daysLeft >= 1;
  const isFreeTrial = hotel?.state === HotelState.FREE_TRIAL;

  function getTrialType() {
    if (!isFreeTrial) return '';
    if (hasProUploadFeature()) return 'Professional';
    if (hasAdvancedUploadFeature()) return 'Advanced';
    if (hasSmallBusinessFeature()) return 'Small Business';
    if (hasStarterUploadFeature()) return 'Starter';
    return '';
  }

  useEffect(() => {
    const isSmallBusiness = hotelFeatures?.includes(28);

    setActiveTab(
      isSmallBusiness && !hotel?.subscription_type
        ? YearlyMonthly.Monthly
        : hotel?.subscription_type === 1
          ? YearlyMonthly.Monthly
          : YearlyMonthly.Yearly
    );
  }, [hotel, setActiveTab, hotelFeatures]);

  useEffect(() => {
    setIsMonthly(activeTab === YearlyMonthly.Monthly);
  }, [activeTab, setIsMonthly]);

  return (
    <div className="pb-5 ">
      {hotel?.state === 2 ? (
        <div className="mb-6 flex w-full flex-row items-center rounded-[12px] bg-gold py-3">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex flex-row gap-2 px-3">
              <Icon.WarningOutline />
              <Typography element="p" variant="paragraph-2" color="darkGrey">
                <b>{t('Your subscription was cancelled.')} </b>
              </Typography>
            </div>
            <Typography
              element="p"
              variant="paragraph-2"
              color="darkGrey"
              className="px-2 text-center"
            >
              {t(
                'If you would like to continue benefiting from great pricing and higher revenue, you can reactivate your account by re-subscribing below.'
              )}
            </Typography>
            <Typography element="span" variant="paragraph-2" color="darkGrey">
              {t('Please contact our team via our chat or send an email to')}{' '}
              <Anchor href="mailto:support@roompricegenie.com" target="_blank" underline="always">
                support@roompricegenie.com
              </Anchor>
            </Typography>
          </div>
        </div>
      ) : null}

      {hotel?.state === HotelState.FREE_TRIAL_EXPIRED ? (
        <div className="mb-6 flex w-full flex-row items-center rounded-[12px] bg-gold py-3">
          <div className="flex w-full flex-row items-center justify-center gap-3 ">
            <Icon.WarningOutline />
            <Typography element="p" variant="paragraph-2" color="darkGrey">
              <b>{t('Your free trial has expired.')} </b>
            </Typography>
          </div>
        </div>
      ) : null}

      {hotel?.state === HotelState.PAUSED ? (
        <div className="mb-6 flex w-full flex-row items-center rounded-[12px] bg-gold py-3">
          <div className="flex w-full flex-col items-center justify-center gap-2 ">
            <div className="flex flex-row gap-2">
              <Icon.WarningOutline />
              <Typography element="p" variant="paragraph-2" color="darkGrey">
                <b>{t('Your subscription is paused.')}</b>
                {t(
                  'If you want to continue benefiting from great prices and higher revenue, you can reactivate your account below by subscribing again.'
                )}
              </Typography>
            </div>
            <Typography element="p" variant="paragraph-2" color="darkGrey">
              {t('Please contact our team if you have any questions.')}
            </Typography>
          </div>
        </div>
      ) : null}

      {hotel?.state === HotelState.NON_RENEWING && isAdmin ? (
        <div className="mb-6 flex w-full flex-row items-center rounded-[12px] bg-uiRed py-2">
          <div className="flex w-full flex-row items-center justify-center gap-3 ">
            <IconReceiptOff className="text-white" />
            <Typography element="p" variant="paragraph-2" className="text-white font-medium">
              {t('Non-Renewing Subscription')}
            </Typography>
          </div>
        </div>
      ) : null}

      {hotel?.state === HotelState.FREE_TRIAL && isDayLeft ? (
        <div className="mb-6 flex w-full flex-row items-center rounded-[12px] bg-gold py-3">
          <div className="flex w-full flex-row items-center justify-center gap-3 ">
            <Icon.Timer40s />
            <Typography element="p" variant="paragraph-2" color="darkGrey">
              {t('Your') + ' '}
              <b>{t(`${getTrialType()}`) + ' '}</b>
              {t('free trial is about to expire on') + ' '}
              <b>{`${dayjs(hotel?.free_trial_end).tz(browserTimezone).format('MMM D')}.`}</b>{' '}
              {t('You have') + ' '} {' ' + `${daysLeft}` + ' '} {t('days left.')}
            </Typography>
          </div>
        </div>
      ) : null}

      {!hotelFeatures?.includes(Feature.PartnerSubscriptionPage) &&
      hotel?.is_locked &&
      hotel?.state !== HotelState.SUBSCRIBED_PARTNER &&
      hotel?.state !== HotelState.CANCELED &&
      hotel?.state !== HotelState.CANCELED_PARTNER &&
      hotel?.state !== HotelState.DEMO ? (
        <div className="mb-4 flex flex-col gap-4">
          <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
            {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
              ? 'Oops!'
              : t('All Good Things Come to an End...')}
          </Typography>
          <div className="flex max-w-6xl flex-col gap-3">
            <Typography color="uiRed">
              {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                ? t(
                    'It seems like there is a problem with your credit card. We tried 3 times but unfortunately it didn`t work. Please update your details if they are incorrect. And please call us if you are having any issues.'
                  )
                : t(
                    "But this one doesn't have to. Just pop in your credit card details to continue to benefit from our great prices and your increased revenue. And if you choose the monthly payment option, you will have the flexibility to be able to cancel with maximum one month's notice. We're quietly confident that you won't want to though."
                  )}
            </Typography>
            <Typography>
              {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                ? ' '
                : t(
                    ' If not, best of luck in the future. And let us know if we can ever be of service'
                  )}
            </Typography>
          </div>
        </div>
      ) : null}

      <div className="flex w-full flex-wrap justify-between">
        <div className="flex flex-col">
          <Typography element="h3" variant="h3" color="darkGrey">
            {t('Subscription')}
          </Typography>
          <div className="mt-3 max-w-3xl">
            <Typography element="p" variant="paragraph-3" color="copyTextGrey">
              {t(
                `Choose a plan that's right for your property. Pay by the month or the year, and cancel at any time.`
              )}
            </Typography>
          </div>
        </div>
        {hotelFeatures?.includes(Feature.PartnerSubscriptionPage) ? null : (
          <div className="relative">
            <SegmentedControl
              data={[
                { label: t('Yearly'), value: 'yearly' },
                { label: t('Monthly'), value: 'monthly' }
              ]}
              value={activeTab}
              onChange={(value) => {
                setActiveTab(value as YearlyMonthly);
                setIsMonthly(value === YearlyMonthly.Monthly);
              }}
            />
            <div className={`${!isMonthly ? 'opacity-0' : ''} absolute -left-6 top-7 -rotate-3`}>
              <svg
                width="10"
                height="41"
                viewBox="0 0 10 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.34436 0.500185C9.6204 0.507705 9.83808 0.737575 9.83056 1.01361L9.70803 5.51195C9.70051 5.78799 9.47064 6.00567 9.1946 5.99815C8.91856 5.99063 8.70088 5.76076 8.7084 5.48472L8.81732 1.4862L4.8188 1.37728C4.54276 1.36976 4.32508 1.13989 4.3326 0.863853C4.34012 0.587813 4.56999 0.370134 4.84603 0.377653L9.34436 0.500185ZM9.67454 1.36305C-1.35332 11.8061 -1.82744 29.2116 8.61557 40.2395L7.88947 40.9271C-2.93328 29.4982 -2.44192 11.4597 8.98695 0.636951L9.67454 1.36305Z"
                  fill="#5B48EE"
                />
              </svg>
            </div>
            <div className={`mt-3 flex gap-2 ${!isMonthly ? 'opacity-0' : ''}`}>
              <div>
                <svg
                  className="mt-1"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.845 8.04033L7.56896 0.764331C7.50646 0.701814 7.42169 0.666683 7.33329 0.666664H0.999959C0.911554 0.666664 0.826769 0.701783 0.764257 0.764295C0.701745 0.826807 0.666626 0.911592 0.666626 0.999997V7.33333C0.666645 7.42173 0.701776 7.5065 0.764293 7.569L8.04029 14.845C8.35311 15.1571 8.77693 15.3323 9.21879 15.3323C9.66065 15.3323 10.0845 15.1571 10.3973 14.845L14.845 10.3973C15.1575 10.0848 15.3331 9.66085 15.3331 9.21883C15.3331 8.77681 15.1575 8.35289 14.845 8.04033ZM4.66663 6C4.40292 6 4.14513 5.9218 3.92587 5.77529C3.7066 5.62878 3.5357 5.42054 3.43479 5.17691C3.33387 4.93327 3.30747 4.66518 3.35891 4.40654C3.41036 4.1479 3.53735 3.91032 3.72382 3.72385C3.91029 3.53738 4.14786 3.4104 4.40651 3.35895C4.66515 3.3075 4.93324 3.33391 5.17687 3.43482C5.42051 3.53574 5.62874 3.70664 5.77525 3.9259C5.92176 4.14517 5.99996 4.40296 5.99996 4.66666C5.99996 5.02029 5.85948 5.35942 5.60944 5.60947C5.35939 5.85952 5.02025 6 4.66663 6ZM9.23563 11.2357C9.17312 11.2982 9.08835 11.3333 8.99996 11.3333C8.91157 11.3333 8.8268 11.2982 8.76429 11.2357L6.09763 8.569C6.03691 8.50613 6.00331 8.42193 6.00407 8.33453C6.00483 8.24713 6.03988 8.16353 6.10169 8.10172C6.16349 8.03992 6.24709 8.00486 6.33449 8.00411C6.42189 8.00335 6.50609 8.03694 6.56896 8.09766L9.23563 10.7643C9.29812 10.8268 9.33322 10.9116 9.33322 11C9.33322 11.0884 9.29812 11.1732 9.23563 11.2357ZM11.2356 9.23566C11.1731 9.29815 11.0883 9.33326 11 9.33326C10.9116 9.33326 10.8268 9.29815 10.7643 9.23566L8.09763 6.569C8.03691 6.50613 8.00331 6.42193 8.00407 6.33453C8.00483 6.24713 8.03988 6.16353 8.10169 6.10172C8.16349 6.03992 8.24709 6.00487 8.33449 6.00411C8.42189 6.00335 8.50609 6.03694 8.56896 6.09766L11.2356 8.76433C11.2981 8.82684 11.3332 8.91161 11.3332 9C11.3332 9.08838 11.2981 9.17315 11.2356 9.23566Z"
                    fill="#5B48EE"
                  />
                </svg>
              </div>
              <Typography element="p" variant="paragraph-1" color="indigo">
                {t('Save up to')} <b>17%</b>
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
