export enum AuthQueryKeys {
  USER_AUTH = 'user_auth',
  USER_ME = 'user_me',
  PENDING_INVITES = 'pending_invites',
  PENDING_INVITES_RAW = 'pending_invites_raw',
  ADMIN_LIST = 'admin_list',
  STAFF_LIST = 'staff_list',
  MANAGER_LIST = 'manager_list',
  OWNER_DETAILS = 'owner_details'
}

export enum HotelQueryKeys {
  ALGO_PROGRAM_EXECUTE = 'algo-program-execute',
  DEFAULT_CONFIG_SURGE = 'default-config-surge',
  GET_ACCOUNT_HOTEL = 'get_account_hotel',
  GET_HOTEL_CLIENT_DETAIL = 'get_hotel_client_detail',
  GET_HOTEL_CLIENT_MAP = 'get_hotel_client_map',
  GET_HOTEL_DAY_EVENTS = 'get_hotel_day_events',
  GET_HOTEL_FREE_TO_BOOK_AUTO_UPLOAD_DISABLE_CLIENT_ACCESS = 'get_hotel_free_to_book_auto_upload_disable_client_access',
  GET_HOTEL_IMPORTANT_EVENTS = 'get_hotel_important_events',
  GET_HOTEL_INBOUND_API_LOGS = 'get_hotel_inbound_api_logs',
  GET_HOTEL_OUTBOUND_API_LOGS = 'get_hotel_outbound_api_logs',
  GET_HOTEL_PMS_DATA_MAP = 'get_hotel_pms_data_map',
  GET_HOTEL_PMS_LIST = 'get_hotel_pms_list',
  GET_HOTEL_PROPERTIES = 'get_hotel_properties',
  GET_HOTEL_RESERVATION_API_LOGS = 'get_hotel_reservation_api_logs',
  GET_HOTEL_ROOMS_LIST = 'get_hotel_rooms_list',
  GET_HOTEL_USERS = 'get_hotel_users',
  GET_HOTEL_ROOM_RESTRICTIONS = 'get_hotel_room_restrictions',
  GET_HOTEL_RATE_RESTRICTIONS = 'get_hotel_rate_restrictions',
  HOTEL_AUTH = 'hotel_auth',
  HOTEL_CLIENT_LIST = 'hotel_client_list',
  HOTEL_CLIENT_LIST_EXPORT = 'hotel_client_list_export',
  HOTEL_CLIENT_MAP_DETAIL = 'hotel_client_map_detail',
  HOTEL_DETAIL = 'hotel-detail',
  UPDATE_HOTEL_PMS_DATA = 'update_hotel_pms_data',
  UPDATE_HOTEL_PMS_OCCUPANCY_DATA = 'update_hotel_pms_occupancy_data',
  UPDATE_HOTEL_ROOM_DERIVED_GENERATE_MAX_MIN_PRICE = 'update_hotel_room_derived_generate_max_min_price',
  GET_HOTEL_EVENTS = 'get_hotel_events'
}

export enum RoomsQueryKeys {
  ADD_HOTEL_ROOM = 'add_hotel_room',
  DELETE_HOTEL_ROOM = 'delete_hotel_room',
  GET_HOTEL_ROOMS_LIST = 'get_hotel_rooms_list',
  GET_ROOM_PRICES = 'get_room_prices',
  PMS_ROOM_TYPE_LIST = 'pms_room_type_list',
  PMS_ROOM_RATE_LIST = 'pms_room_rate_list',
  UPDATE_HOTEL_CALCULATE_NO_OF_ROOMS = 'update_hotel_calculate_no_of_rooms',
  UPDATE_HOTEL_ROOM = 'update_hotel_room',
  UPDATE_HOTEL_PMS_ROOM_MAP = 'update_hotel_pms_room_map',
  GET_INTERNAL_ROOM_MAPPING = 'get_internal_room_mapping'
}

export enum AdjustmentQueryKeys {
  SAVE_BULK_REMOVE_ADJUSTMENT = 'save_bulk_remove_adjustment',
  SAVE_BULK_EDIT_ADJUSTMENT = 'save_bulk_edit_adjustment',
  SAVE_EDIT_PRICES = 'save_edit_prices'
}

export enum PricingQueryKeys {
  AUTO_SAVE_AVERAGE_PRICE = 'auto_save_average_price',
  GET_PRICING_SETTINGS = 'get_pricing_settings',
  GET_HOTEL_PRICE_PER_RATE = 'get_hotel_price_per_rate',
  GET_HOTEL_PRICE_PER_OCCUPANCY = 'get_hotel_price_per_occupancy',
  GET_HOTEL_PRICE_PER_OCCUPANCYV2 = 'get_hotel_price_per_occupancy-v2',
  UPDATE_BUDGET_VALUES = 'update_budget_values',
  UPDATE_MIN_MAX_PRICE = 'update_min_max_price',
  UPDATE_PRICING_SETTINGS = 'update_pricing_settings',
  SAVE_DERIVED_RATES = 'save_derived_rates',
  SAVE_HOTEL_PRICE_PER_OCCUPANCY = 'save_hotel_price_per_occupancy',
  GET_PRICE_DEVELOPMENT = 'get_price_development',
  GET_PAYMENT_INFORMATION = 'get_payment_information',
  PRICING_ALGORITHM_INVENTORY_PRICE = 'pricing_algorithm_inventory_price',
  RUN_PRICING_FETCH_INVENTORY_PRICE = 'run_pricing_fetch_inventory_price',
  GET_RATE_DERIVATION = 'get_rate_derivation',
  SAVE_RATE_DERIVATION = 'save_rate_derivation',
  APPLY_INDIVIDUAL_ADJUSTMENTS = 'apply_individual_adjustments'
}

export enum SurgeEventQueryKeys {
  DEACTIVATE_SURGE_EVENT = 'deactivate_surge_event',
  REMOVE_SURGE_PRICES = 'remove_surge_prices',
  GET_SURGE_EVENTS = 'get_surge_events',
  GET_SURGE_LOGS = 'get_surge_logs',
  SIMULATE_SURGE_EVENTS = 'simulate_surge_events'
}

export enum PartnerQueryKeys {
  GET_PARTNER_LIST = 'get_partner_list',
  GET_PARTNER_USER_LIST = 'get_partner_user_list'
}

export enum RPGUsersQueryKeys {
  GET_ADMIN_USER_LIST = 'get_admin_user_list'
}

export enum DashboardDailyReportsQueryKeys {
  GET_DASHBOARD_DAILY_REPORTS = 'get_dashboard_daily_reports'
}

export enum ErrorTicketsQueryKeys {
  ERROR_TICKET_LIST = 'error_ticket_list',
  ERROR_TICKET_TAGS = 'error_ticket_tags'
}

export enum DashboardErrorTicketsQueryKeys {
  GET_DASHBOARD_ERROR_TICKETS = 'get_dashboard_error_tickets',
  DASHBOARD_ERROR_TICKET_LIST = 'dashboard_error_ticket_list'
}

export enum ReservationQueryKeys {
  GET_RESERVATION_STATUS = 'get_reservation_status'
}

export enum ReportsQueryKeys {
  GET_PROFIT_REPORT = 'get_profit_report',
  GET_PERFORMANCE_DASHBOARD_RESERVATION_KPI = 'get_performance_dashboard_reservation_kpi',
  PERFORMANCE_DASHBOARD_SCHEDULE_REPORTS_LIST = 'performance-dashboard-schedule-reports-list'
}

export enum MembershipQueryKeys {
  MEMBERSHIP_PRICING = 'membership_pricing'
}

export enum TrackingQueryKeys {
  VISITOR_IDENTIFICATION = 'visitor_identification',
  HOTEL_TRACK_TIME = 'hotel_track_time'
}

export enum UserListQueryKeys {
  ADMIN_LIST = 'admin_list',
  STAFF_LIST = 'staff_list',
  MANAGER_LIST = 'manager_list',
  OWNER_DETAILS = 'owner_details',
  GET_DEAL_OWNER_LIST = 'get_deal_owner_list',
  GET_USER_DATA_INVITATION = 'get_user_data_invitation',
  GET_SUPPORT_TEAM = 'get_support_team'
}

export enum ScrapeQueryKeys {
  GET_HOTEL_SCRAPE_LIST = 'get_hotel_scrape_list'
}

export enum MarketIntelligenceQueryKeys {
  GET_HOTEL_COMPETITOR = 'get_hotel_competitor',
  GET_COMPETITOR_SETUP = 'get_competitor_setup',
  GET_COMPETITOR_SCORE = 'get_competitor_score',
  GET_AIRBNB_INSIGHTS_CACHE = 'get_airbnb_insights_cache'
}

export enum NotesQueryKeys {
  SAVE_NOTE = 'save_note',
  GET_HOTEL_ROOM_NOTES = 'get_hotel_room_notes'
}

export enum ForecastQueryKeys {
  GET_FORECASTING_CACHE = 'get_forecasting_cache'
}

export enum BudgetQueryKeys {
  GET_BUDGET = 'get_budget'
}

export enum AnalyticsDashboardQueryKeys {
  GET_ANALYTICS_DASHBOARD_CACHE = 'get_analytics_dashboard_cache',
  GET_ANALYTICS_DASHBOARD_FILTERS = 'get_analytics_dashboard_filters'
}

export enum UtilQueryKeys {
  HEALTH_REPORT = 'health_report',
  GET_PICKUP = 'get_pickup',
  GET_CURRENT_UTC_TIME = 'get_current_utc_time',
  GENERATE_DEFAULT_VALUE_SET = 'generate_default_value_set',
  UPDATE_HOURLY_EXECUTION = 'update_hourly_execution',
  GET_DEFAULT_AFTER_365_DAILY_AVERAGES = 'get_default_after365_daily_averages',
  REQUEST_IFRAME_URL = 'request_iframe_url',
  GET_RESET_PASSWORD_SESSION = 'get_reset_password_session',
  HOTEL_SETTINGS_LOG = 'hotel_settings_log',
  USER_DETAIL = 'user_detail',
  CLERK_USER_DETAIL = 'clerk_user_detail',
  USER_LOGS = 'user_logs',
  GET_COUNTRY_LIST = 'get_country_list',
  GET_CURRENCY_LIST = 'get_currency_list',
  GET_WEATHER_INFO = 'get_weather_info',
  GET_ON_DEMAND_LIST = 'get_on_demand_list',
  GET_SCHEDULE_HISTORY_LIST = 'get_schedule_history_list',
  GET_DETAILS_SCHEDULE_HISTORY_LIST = 'get_details_schedule_history_list',
  GET_FUTURE_DAILY = 'get_future_daily',
  VALUE_REPORT = 'value_report',
  HOTEL_NOTES = 'hotel_notes'
}

export enum SnowflakeQueryKeys {
  RESET_POSTGRES_SNOWFLAKE = 'reset_postgres_snowflake',
  DELETE_POSTGRES_SNOWFLAKE = 'delete_postgres_snowflake'
}

export enum FeaturesQueryKeys {
  UPDATE_FEATURES = 'update_features'
}

export enum AccountQueryKeys {
  ADD_FREE_TRIAL_TIME = 'add_free_trial_time'
}

export enum ResellerQueryKeys {
  GET_RESELLER_LIST = 'get_reseller_list',
  GET_RESELLER_MEMBER = 'get_reseller_member',
  GET_SALES_LIST = 'get_sales_list'
}

export enum ClientQueryKeys {
  SEARCH_CLIENT = 'search_client',
  SEARCH_ASSIGN_CLIENT = 'search_assign_client'
}

export enum ManageIframeQueryKeys {
  GET_IFRAME_STATUS = 'get-iframe-status'
}
export enum PropertyQueryKeys {
  GET_MULTI_PROPERTY_FILTERS = 'get_multi_property_filters',
  SEARCH_HOTEL_COMPETITOR = 'search_hotel_competitor'
}

export enum IntegrationPartnerQueryKeys {
  GET_INTEGRATION_PARTNERS = 'get_integration_partners',
  GET_INTEGRATION_PARTNER = 'get_integration_partner'
}

export enum PmsConfigQueryKeys {
  GET_PMS_CONFIG_LIST = 'get_pms_config_list',
  GET_PMS_CONFIG_DETAIL = 'get_pms_config_detail'
}
