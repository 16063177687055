import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@common/components/atoms/Button';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConnectChargebee } from '@pages/Client/Account/Subscription/hooks/useConnectChargebee';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
};

export const ConnectChargebeeModal = ({ isOpen, onClose }: Props) => {
  const { hotelDetails: hotel } = useHotelDetails();
  const { t } = useTranslation();
  const { mutate: handleConnectChargebee, isSuccess } = useConnectChargebee();

  const schema = z.object({
    subscription_id: z.string().nonempty({ message: `${t('Please enter Subscription ID')}` })
  });
  const { handleSubmit, control, reset, watch, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    reset({ subscription_id: hotel?.subscription_id });
  }, [hotel?.subscription_id]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, []);

  const onSubmit = () => {
    handleConnectChargebee(watch());
  };

  const handleConnect = () => {
    handleSubmit(onSubmit)();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      size="custom"
      onClose={onClose}
      disableCloseOnOk={!formState.isValid}
      showFooter={false}
    >
      <div className="relative min-w-[400px] max-w-[400px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={onClose}
        >
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="p-5">
          <Typography element="h5" variant="h5" color="darkGrey">
            {t('Connect A Chargebee Subscription')}
          </Typography>
          <div className="mt-8">
            <Controller
              control={control}
              defaultValue={hotel?.subscription_id}
              name="subscription_id"
              render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                <Input
                  disabled={false}
                  type="email"
                  tabIndex={1}
                  label={`${t('Chargebee Subscription ID*')}`}
                  placeholder={`${t('Please enter a Chargebee subscription ID')}`}
                  value={value || ''}
                  error={invalid}
                  helperMessage={
                    invalid && (
                      <InputHelperMessage
                        icon={error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null}
                        message={error?.message}
                      />
                    )
                  }
                  onChange={onChange}
                  onClear={() => {
                    reset({ subscription_id: '' });
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey"
            >
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleConnect}>
              {t('Connect')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
