import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { z } from 'zod';

const basePmsMappingSchema = z.object({
  roomInPms: z
    .number()
    .or(z.string().transform(Number))
    .or(z.null())
    .refine((val) => val !== undefined, {
      message: 'Please select one of the options in the dropdown'
    }),
  baseRateInPms: z
    .number()
    .or(z.string().transform(Number))
    .or(z.string())
    .or(z.null())
    .refine((val) => val !== undefined, {
      message: 'Please select one of the options in the dropdown'
    }),
  roomName: z.string().nonempty({ message: 'Please enter a name' }),
  numberOfRooms: z
    .number()
    .nonnegative({ message: 'Number of rooms cannot be less than 0' })
    .or(z.string().transform(Number))
    .refine((val) => validateNoDecimals(val), {
      message: 'Number of rooms must be a whole number'
    }),
  roomOccupancyForPricing: z
    .number()
    .or(z.string().transform(Number))
    .or(z.null())
    .refine((val) => val !== undefined, {
      message: 'Please select one of the options in the dropdown'
    }),
  priceType: z.string().optional(),
  virtualRoomType: z.boolean().optional(),
  numberOfBedsPerPhysicalRoom: z
    .number()
    .or(z.string().transform(Number))
    .refine((val) => val !== undefined && +val >= 1, {
      message: 'Please enter a positive number'
    })
    .refine((val) => validateNoDecimals(val), {
      message: 'Number must be a whole number'
    })
    .optional()
});

export const basePricingSetupSchema = z.object({
  basePrice: z
    .number()
    .nonnegative({ message: 'Base price cannot be less than 0' })
    .or(z.string().transform(Number))
    .refine((val) => val !== undefined, {
      message: 'Please enter the base price'
    })
    .refine((val) => validateNoDecimals(val), {
      message: 'Base price must be a whole number'
    }),
  defaultMinPrice: z
    .number()
    .nonnegative({ message: 'Default min price cannot be less than 0' })
    .or(z.string().transform(Number))
    .refine((val) => val !== undefined, {
      message: 'Please enter the default min price'
    })
    .refine((val) => validateNoDecimals(val), {
      message: 'Default min price must be a whole number'
    }),
  defaultMaxPrice: z
    .number()
    .nonnegative({ message: 'Default max price cannot be less than 0' })
    .or(z.string().transform(Number))
    .refine((val) => val !== undefined, {
      message: 'Please enter the default max price'
    })
    .refine((val) => validateNoDecimals(val), {
      message: 'Default max price must be a whole number'
    }),
  derivationFromReferenceRoom: z
    .number()
    .or(z.string().transform(Number))
    .refine((val) => val !== undefined, {
      message: 'Please enter the derivation'
    })
    .refine((val) => validateNoDecimals(val), {
      message: 'Derivation must be a whole number'
    }),
  dontCountUploads: z.boolean().optional()
});

const basePricingSetupSchemaWithoutMinMax = basePricingSetupSchema.omit({
  defaultMinPrice: true,
  defaultMaxPrice: true
});

const baseOccupancyPricingSchema = z
  .object({
    defaultOccupancy: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Default occupancy must be a whole number'
      })
      .optional(),
    minOccupancy: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Minimum occupancy must be a whole number'
      })
      .optional(),
    maxOccupancy: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Maximum occupancy must be a whole number'
      })
      .optional(),
    additionalChildPrice: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Additional child price must be a whole number'
      })
      .optional(),
    additionalAdultPrice: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Additional adult price must be a whole number'
      })
      .optional(),
    occupancyDerivation: z
      .array(
        z.object({
          occupancy: z
            .number()
            .or(z.string().transform(Number))
            .refine((val) => validateNoDecimals(val), {
              message: 'Occupancy must be a whole number'
            }),
          derivation: z
            .number()
            .or(z.string().transform(Number))
            .refine((val) => validateNoDecimals(val), {
              message: 'Derivation must be a whole number'
            })
        })
      )
      .optional()
  })
  .refine(
    (data) =>
      data.defaultOccupancy === undefined ||
      (data.minOccupancy === undefined && data.maxOccupancy === undefined) ||
      (data.defaultOccupancy >= (data.minOccupancy || -Infinity) &&
        data.defaultOccupancy <= (data.maxOccupancy || Infinity)),
    {
      message: 'Default occupancy must be within min and max',
      path: ['defaultOccupancy']
    }
  )
  .refine(
    (data) => {
      if (data.minOccupancy !== undefined && data.defaultOccupancy !== undefined) {
        return data.minOccupancy <= data.defaultOccupancy;
      }
      return true;
    },
    {
      message: 'Minimum occupancy should be less than or equal to default occupancy',
      path: ['minOccupancy']
    }
  )
  .refine(
    (data) => {
      if (data.maxOccupancy !== undefined && data.minOccupancy !== undefined) {
        return data.maxOccupancy >= data.minOccupancy;
      }
      return true;
    },
    {
      message: 'Maximum occupancy should be greater than or equal to minimum occupancy',
      path: ['maxOccupancy']
    }
  );

const baseDerivedRateSchema = z.object({
  id: z.number().or(z.string().transform(Number)).optional(),
  derivedRateInPms: z.number().or(z.string().transform(Number)).or(z.null()),
  derivation: z
    .number()
    .or(z.string().transform(Number))
    .refine((val) => validateNoDecimals(val), {
      message: 'Derivation must be a whole number'
    })
    .optional(),
  percentDerivation: z.boolean().optional(),
  productDerivation: z.boolean().optional(),
  minOccupancy: z.number().or(z.string().transform(Number)).optional(),
  maxOccupancy: z.number().or(z.string().transform(Number)).optional(),
  occupancyDerivation: z
    .number()
    .or(z.string().transform(Number))
    .refine((val) => !isNaN(val), {
      message: 'Value must be a number'
    })
    .optional()
});

export const DerivationV2Schema = z
  .object({
    id: z.number().or(z.string().transform(Number)).optional(),
    derivedRateInPms: z.number().or(z.string().transform(Number)).or(z.null()),
    baseRate: z.number().or(z.string().transform(Number)).optional(),
    derivation: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => !isNaN(val), {
        message: 'Derivation must be a number'
      })
      .optional(),
    percentDerivation: z.boolean().optional(),
    productDerivation: z.boolean().optional(),
    defaultMinOccupancy: z.number().or(z.string().transform(Number)).optional(),
    defaultMaxOccupancy: z.number().or(z.string().transform(Number)).optional(),
    minOccupancy: z.number().or(z.string().transform(Number)).optional(),
    maxOccupancy: z.number().or(z.string().transform(Number)).optional(),
    occupancyDerivation: z
      .number()
      .or(z.string().transform(Number))
      .refine((val) => !isNaN(val), {
        message: 'Person derivation must be a number'
      })
      .nullish()
      .optional()
  })
  // .extend(basePricingSetupSchema.pick({ basePrice: true }).partial().shape)
  .refine(
    (data) => {
      return data.percentDerivation || data.productDerivation
        ? data.derivation !== undefined
        : true;
    },
    {
      message: 'Please enter the derivation',
      path: ['derivation']
    }
  )
  .refine(
    (data) => {
      if (data.percentDerivation) {
        // If percentDerivation is true, derivation should be higher than -100%
        return data.derivation !== undefined && data.derivation > -100;
      }
      return true;
    },
    {
      message: 'Derivation should be higher than -100% ',
      path: ['derivation']
    }
  )
  .refine(
    (data) => {
      if (data.percentDerivation === false && data.baseRate !== undefined) {
        // If percentDerivation is false and basePrice is defined, derivation should be higher than negative basePrice
        return data.derivation !== undefined && data.derivation > -data.baseRate;
      }
      return true;
    },
    {
      message: 'Derivation should be  higher than the negative base price',
      path: ['derivation']
    }
  )
  .refine(
    (data) =>
      data.minOccupancy === undefined ||
      data.defaultMinOccupancy === undefined ||
      data.minOccupancy >= data.defaultMinOccupancy,
    {
      message: 'minOccupancy must be at least defaultMinOccupancy',
      path: ['minOccupancy']
    }
  )
  .refine(
    (data) =>
      data.maxOccupancy === undefined ||
      data.defaultMaxOccupancy === undefined ||
      data.maxOccupancy <= data.defaultMaxOccupancy,
    {
      message: 'maxOccupancy must be no more than defaultMaxOccupancy',
      path: ['maxOccupancy']
    }
  )
  .refine(
    (data) =>
      data.minOccupancy === undefined ||
      data.maxOccupancy === undefined ||
      data.minOccupancy <= data.maxOccupancy,
    {
      message: 'minOccupancy cannot exceed maxOccupancy',
      path: ['minOccupancy']
    }
  )
  .refine(
    (data) =>
      data.maxOccupancy === undefined ||
      data.minOccupancy === undefined ||
      data.maxOccupancy >= data.minOccupancy,
    {
      message: 'maxOccupancy cannot be less than minOccupancy',
      path: ['maxOccupancy']
    }
  );

export const DerivationsV2Schema = z.array(DerivationV2Schema).max(20).optional().default([]);

export const schema = z.object({
  pmsMapping: basePmsMappingSchema,
  pricingSetup: basePricingSetupSchema
    .refine(
      (data) => {
        if (typeof data.basePrice === 'number' && typeof data.defaultMinPrice === 'number') {
          return data.defaultMinPrice < data.basePrice;
        }
        return true;
      },
      {
        message: 'Minimum price must be lower than base price',
        path: ['defaultMinPrice']
      }
    )
    .refine(
      (data) => {
        if (typeof data.basePrice === 'number' && typeof data.defaultMaxPrice === 'number') {
          return data.defaultMaxPrice > data.basePrice;
        }
        return true;
      },
      {
        message: 'Maximum price must be higher than base price',
        path: ['defaultMaxPrice']
      }
    )
    .refine(
      (data) => {
        if (typeof data.defaultMinPrice === 'number' && typeof data.defaultMaxPrice === 'number') {
          return data.defaultMaxPrice > data.defaultMinPrice;
        }
        return true;
      },
      {
        message: 'Maximum price must be higher than minimum price',
        path: ['defaultMaxPrice']
      }
    ),
  occupancyPricing: baseOccupancyPricingSchema,
  derivedRates: z.array(baseDerivedRateSchema).max(12).optional()
});

export const editSchema = schema.extend({
  pmsMapping: basePmsMappingSchema.extend({
    roomId: z.number().or(z.string().transform(Number)).optional()
  })
});

export const editReferenceRoomSchema = editSchema.extend({
  pricingSetup: basePricingSetupSchema
    .extend({
      variableCost: z
        .number()
        .or(z.string().transform(Number))
        .refine((val) => validateNoDecimals(val), {
          message: 'Value must be a whole number'
        })
        .refine((val) => Number(val) > 0, {
          message: 'Value should be greater than 0'
        })
        .optional(),
      roundPriceSetting: z
        .number()
        .or(z.string().transform(Number))
        .or(z.null())
        .refine((val) => val !== undefined, {
          message: 'Please select one of the options in the dropdown'
        }),
      lockDerivationWhenBasePriceChanges: z.boolean().optional()
    })
    .refine(
      (data) => {
        if (typeof data.basePrice === 'number' && typeof data.roundPriceSetting === 'number') {
          // Rounding should not exceed 10% of base price
          const maxRounding = Math.ceil(data.basePrice * 0.1);
          return data.roundPriceSetting <= maxRounding;
        }
        return true;
      },
      {
        message: 'Rounding value cannot exceed 10% of base price',
        path: ['roundPriceSetting']
      }
    )
    .refine(
      (data) => {
        if (typeof data.basePrice === 'number' && typeof data.defaultMinPrice === 'number') {
          return data.defaultMinPrice < data.basePrice;
        }
        return true;
      },
      {
        message: 'Minimum price must be lower than base price',
        path: ['defaultMinPrice']
      }
    )
    .refine(
      (data) => {
        if (typeof data.basePrice === 'number' && typeof data.defaultMaxPrice === 'number') {
          return data.defaultMaxPrice > data.basePrice;
        }
        return true;
      },
      {
        message: 'Maximum price must be higher than base price',
        path: ['defaultMaxPrice']
      }
    )
    .refine(
      (data) => {
        if (typeof data.defaultMinPrice === 'number' && typeof data.defaultMaxPrice === 'number') {
          return data.defaultMaxPrice > data.defaultMinPrice;
        }
        return true;
      },
      {
        message: 'Maximum price must be higher than minimum price',
        path: ['defaultMaxPrice']
      }
    )
    .refine(
      (data) => {
        if (typeof data.variableCost === 'number' && typeof data.defaultMinPrice === 'number') {
          return data.variableCost < data.defaultMinPrice;
        }
        return true;
      },
      {
        message: 'Variable costs should be lower than minimum price',
        path: ['variableCost']
      }
    ),
  hotel: z.object({
    automatic_inflation_increase: z.boolean().optional(),
    yearly_percent_increase: z
      .number()
      .or(z.string().nonempty({ message: 'Please enter a value' }).transform(Number))
      .refine((val) => validateNoDecimals(val), {
        message: 'Percentage must be a whole number'
      })
      .refine((value) => value >= 0 && value <= 100, {
        message: 'Percentage must be between 0 and 100'
      })
      .optional()
  }),
  pmsMapping: basePmsMappingSchema.extend({
    roomId: z.number().or(z.string().transform(Number)).optional(),
    priceReferenceRoomOnly: z.boolean().optional()
  })
});

export const schemaWithoutMinMax = schema
  .omit({
    pricingSetup: true
  })
  .extend({
    pricingSetup: basePricingSetupSchemaWithoutMinMax
  });

export const editSchemaWithoutMinMax = editSchema
  .omit({
    pricingSetup: true
  })
  .extend({
    pricingSetup: basePricingSetupSchemaWithoutMinMax
  });

export const schemaWithoutOccupancyPricing = schema.omit({
  occupancyPricing: true
});
export const schemaWithoutMinMaxAndOccupancyPricing = schemaWithoutMinMax.omit({
  occupancyPricing: true
});
