import { FormInput } from '@common/components/atoms/Input';
import { Fragment, useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Switcher } from '@common/components/atoms/Switcher';
import { cn } from '@common/utils/cn';
import { isEmpty, isNil, map, result } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useRecommendedPrice } from '@pages/Client/Calendar/components/Tables/hooks';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Icon } from '@common/components/foundations/icons';
import dayjs from 'dayjs';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { useFormPreflight } from '@pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight';
import { PercentAdjustment } from '@pages/Client/Calendar/components/BulkEdit/types/schema/percentageAdjustmentSchema';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useViewStore } from '@common/store/view';
import { Group, Indicator, Tooltip } from '@mantine/core';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';

export function PercentageAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.PERCENT;
  const flags = useFlags();
  const adjustmentFields: PercentAdjustment[] =
    useBuildAdjustmentInputsArray<PercentAdjustment>(formName);
  const { view } = useViewStore();
  const { hotelDetails } = useHotelDetails();
  const { pricingSettings, getRoomData } = useRoomPrices();
  const { displayHotelRooms } = useHotelRoomsList();
  const { preflightCallback, isUpdating } = useFormPreflight();
  const [prefillDerivedRooms, setPrefillDerivedRooms] = useState(true);
  const { control, formState, setValue, getValues, trigger, watch } = useFormContext();
  const { fields, replace } = useFieldArray({ name: formName, control });
  const editDate = getValues('editDate');
  const isBulkEdit = getValues('isBulkEdit');
  const { recommendedPriceData } = useRecommendedPrice(dayjs(editDate));

  function updatePrefillDerivedRooms(name?: string) {
    if (prefillDerivedRooms && name === `${formName}.0.value`) {
      map(fields, (_, index) => {
        const root = `${formName}.${index}.value`;
        const value = Number(getValues(`${formName}.0.value`));
        if (index > 0) setValue(root, value);
      });
    }
    trigger(formName);
  }

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      updatePrefillDerivedRooms(name);
      trigger(formName);
      if (type === 'change') {
        preflightCallback();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, prefillDerivedRooms, fields]);

  useEffect(() => {
    if (!flags[Flags.PricingCheeseLayers]) return;
    preflightCallback();
  }, [editDate]);

  return (
    <Fragment>
      <Group justify="space-between" align="center">
        {pricingSettings?.hotel?.all_room_pricing ? (
          <Switcher
            checked={prefillDerivedRooms}
            onChange={setPrefillDerivedRooms}
            size="small"
            label={`${t(`Prefill Derived ${hotelDetails?.room_apartment_space_name}s`)}`}
            className="text-sm font-medium text-copyTextGrey"
            data-userflow-id={PriceDrawerUserflowIds.PREFILL_DERIVED_ROOM_SWITCHER}
          />
        ) : null}
        {!isBulkEdit && isUpdating ? (
          <Icon.LoadingCircle className="ml-2 inline-block opacity-50" />
        ) : null}
      </Group>
      <LineTable>
        <thead>
          <tr>
            <th>{t(`${hotelDetails?.room_apartment_space_name}`)}</th>
            <th>{t('Percent Adjustment')}</th>
            {!isBulkEdit && <th>{t('Recommended/Fix Price')}</th>}
            {!isBulkEdit && view === 'admin' && <th>{t('Price in PMS')}</th>}
          </tr>
        </thead>

        <tbody>
          {fields?.length
            ? map(fields, (field, index) => {
                const room = displayHotelRooms[index];
                const error = result(formState, `errors.${formName}[${index}].message`);
                const errorPriceTooLow = result(
                  formState,
                  `errors.apiErrors.priceTooLow.${room?.id}.priceTooLow.message`
                );
                return !isNil(room) ? (
                  <tr key={field?.id}>
                    <td>
                      {room?.name} {`${import.meta.env.DEV ? room?.id : ''}`}
                    </td>
                    <td>
                      <div className={cn(isBulkEdit ? 'w-full' : 'max-w-[170px]')}>
                        <FormInput
                          type="number"
                          nameKey={`${formName}.${index}.id` as const}
                          valueKey={`${formName}.${index}.value` as const}
                          background="grey"
                          trailingAddon={'%'}
                          showClearButton={false}
                          helperMessage={error}
                        />
                      </div>
                    </td>
                    {!isBulkEdit && (
                      <td className="text-center">
                        <Tooltip
                          label={<>{errorPriceTooLow}</>}
                          disabled={!errorPriceTooLow}
                          position="bottom"
                        >
                          <Indicator
                            disabled={!errorPriceTooLow}
                            color="red"
                            processing
                            inline
                            offset={-4}
                          >
                            {CurrencyFormatter.format(
                              Math.round(recommendedPriceData?.[room?.id]?.price ?? 0),
                              '-'
                            )}
                          </Indicator>
                        </Tooltip>
                      </td>
                    )}
                    {!isBulkEdit && view === 'admin' ? (
                      <td className="text-center">
                        {CurrencyFormatter.format(
                          getRoomData(room?.id, dayjs(editDate).format(API_DATE_FORMAT))
                            ?.original_price,
                          '-'
                        )}
                      </td>
                    ) : null}
                  </tr>
                ) : null;
              })
            : null}
        </tbody>
      </LineTable>
    </Fragment>
  );
}
