import { HotelList } from '@common/api/account/types';
import { Hotel } from '@common/api/hotel/types';
import { HoteliersInspiration } from '@common/assets/images';
import { Button } from '@common/components/atoms/Button';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { UpsellingModal } from '@common/components/organisms/UpsellingModal';
import { useAuthStore } from '@common/store/auth';
import { useSidebarStore } from '@common/store/sidebar';
import { NavUserflowIds } from '@common/types/userflow-ids';
import { cn } from '@common/utils/cn';
import { Combobox, Dialog, Menu, Tab, Transition } from '@headlessui/react';
import { PopoverNote } from '@pages/Client/Notes/components/PopoverNote';
import { SecureNavLink } from '@pages/Client/components/SecureNavLink';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { IconBuildingSkyscraper, IconProps } from '@tabler/icons-react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { filter, isEmpty, sortBy } from 'lodash-es';
import { Fragment, SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import useMedia from 'use-media';

export default function ApplicationShell({
  children,
  navigation,
  secondaryNavigation,
  userProfile,
  isLoading,
  isIframe,
  isHoteliersInspiration,
  selectedHotel,
  selectHotel,
  hotel_list,
  isAdmin,
  view,
  setView,
  hidePropertySelector,
  subscribeButton
}: {
  children: React.ReactNode;
  navigation: {
    title: string;
    icon: React.FC<SVGProps<SVGSVGElement>> | React.FC<IconProps>;
    highlight?: boolean;
    highlightColor?: string;
    path?: string;
    badge?: React.ReactNode;
    feature?: number | number[];
    subNavigation?: {
      title: string;
      path: string;
      feature?: number | number[];
      badge?: React.ReactNode;
    }[];
    userflowId?: string;
  }[];
  secondaryNavigation?: {
    title: string;
    path: string;
    icon: React.FC<SVGProps<SVGSVGElement>>;
    badge?: React.ReactNode;
    trigger?: string;
    userflowId?: string;
  }[];
  userProfile?: {
    email: string;
    path: string;
    icon?: React.FC<SVGProps<SVGSVGElement>>;
    tooltip?: string;
  };
  isLoading?: boolean;
  isIframe?: boolean;
  isHoteliersInspiration?: boolean;
  selectHotel?: (hotelId: Hotel['id']) => void;
  selectedHotel?: Hotel;
  isAdmin?: boolean;
  hotel_list?: HotelList[];
  view?: 'admin' | 'client';
  setView?: (view: 'admin' | 'client') => void;
  hidePropertySelector?: boolean;
  subscribeButton?: {
    title: string;
    daysLeft?: string;
    path?: string;
    upselling?: boolean;
    icon: React.FC<SVGProps<SVGSVGElement>>;
  };
}) {
  const { t } = useTranslation();
  const {
    sidebarOpen,
    setSidebarOpen,
    setMobileNavOpen,
    mobileNavOpen,
    openDisclosure,
    setOpenDisclosure,
    closingDisclosure,
    setClosingDisclosure,
    nextDisclosure,
    setNextDisclosure
  } = useSidebarStore();
  const sortedHotelList: HotelList[] = !isEmpty(hotel_list)
    ? sortBy(hotel_list, ['name'], ['asc'])
    : [];
  const [menuOpen, setMenuOpen] = useState(false);
  const isSmallDesktopResolution = useMedia({ maxWidth: '1440px' });
  const isGlobalFetching = useIsFetching();
  const isGlobalMutating = useIsMutating();
  const isGlobalLoading = isGlobalFetching >= 1 || isGlobalMutating >= 1;
  const [autoSelectedHotel, setAutoSelectHotel] = useState(
    sortedHotelList?.find((hotel) => hotel.id === selectedHotel?.id)
  );
  const [query, setQuery] = useState('');
  const [popOverOpen, setPopOverOpen] = useState(false);

  const [displayValue, setDisplayValue] = useState(true);

  const { upsellingModalOpen, setUpsellingModalOpen } = useUpselling();
  const { user: userData } = useAuthStore();

  const filteredHotel = filter(sortedHotelList, (hotel) => {
    return hotel.name.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    // Update autoSelectedHotel when selectedHotel changes
    setAutoSelectHotel(sortedHotelList?.find((hotel) => hotel.id === selectedHotel?.id));
  }, [selectedHotel, sortedHotelList]);

  useEffect(() => {
    if (menuOpen) setSidebarOpen(true);
  }, [menuOpen, setSidebarOpen]);

  useEffect(() => {
    switch (true) {
      case isSmallDesktopResolution && sidebarOpen:
        setSidebarOpen(false);
        break;
      case !isSmallDesktopResolution && !sidebarOpen:
        setSidebarOpen(true);
        break;

      default:
        break;
    }
  }, [isSmallDesktopResolution, setSidebarOpen]);

  const handleDisclosureClick = (title: string) => {
    if (openDisclosure === title) {
      // Close the clicked accordion if it's already open
      setOpenDisclosure(null);
    } else {
      // Set the clicked disclosure to be opened next
      setNextDisclosure(title);
      // If there's another accordion open, close it first
      if (openDisclosure !== null) {
        setClosingDisclosure(openDisclosure);
        setOpenDisclosure(null);
      } else {
        // If there's no other accordion open, open the clicked one immediately
        setOpenDisclosure(title);
      }
    }
  };

  useEffect(() => {
    if (closingDisclosure !== null) {
      const timer = setTimeout(() => {
        // Open the next disclosure after the closing animation has finished
        setOpenDisclosure(nextDisclosure);
        // Clear the closing disclosure
        setClosingDisclosure(null);
      }, 150); // Make sure to match this delay with the exit transition duration

      // Clean up the timer when the component unmounts or when `closingDisclosure` changes
      return () => clearTimeout(timer);
    }
  }, [closingDisclosure, nextDisclosure]);

  const renderNavigation = (isSidebarOpen?: boolean) => {
    return (
      <div
        className={`mb-4 flex flex-1 flex-col content-center space-y-2 overflow-auto duration-300 ${
          isSidebarOpen ? 'mt-5 px-1 md:px-2' : 'mt-2 px-1'
        }`}
      >
        {!hidePropertySelector ? (
          <>
            {sortedHotelList.length > 4 ? (
              <Menu as="div" className="relative inline-block w-full">
                <Combobox value={autoSelectedHotel} onChange={setAutoSelectHotel}>
                  <div className="relative mt-1 ">
                    <div>
                      <TooltipProvider delayDuration={75}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div
                              onClick={(open) => setMenuOpen(open as unknown as boolean)}
                              className={cn(
                                isSidebarOpen
                                  ? 'absolute inset-y-0 left-2 flex items-center pr-2 hover:cursor-pointer'
                                  : 'flex items-center justify-center rounded-sm border-2 border-indigo-contrast border-opacity-30 px-3 py-2 hover:cursor-pointer hover:bg-white hover:bg-opacity-10'
                              )}
                            >
                              <IconBuildingSkyscraper className="h-5 w-5 text-indigo-reduced" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent hidden={isSidebarOpen}>
                            {sortedHotelList?.find((hotel) => hotel.id === selectedHotel?.id)?.name}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <Combobox.Button as="div">
                        <TooltipProvider delayDuration={75}>
                          <Tooltip>
                            <TooltipTrigger>
                              {' '}
                              <Combobox.Input
                                autoComplete="off"
                                className={cn(
                                  isSidebarOpen
                                    ? 'w-full truncate rounded-sm border-2 border-indigo-contrast border-opacity-30 bg-transparent px-4 pl-11  pr-10 text-meta-2 font-semibold hover:bg-white hover:bg-opacity-10 focus:ring-1'
                                    : 'hidden',
                                  'py-4 text-xs text-white'
                                )}
                                displayValue={(hotel: Hotel) =>
                                  isSidebarOpen && displayValue ? hotel.name : ''
                                }
                                onChange={(event) => {
                                  setQuery(event.currentTarget.value);
                                }}
                                onFocus={() => setDisplayValue(false)}
                                onBlur={() => setDisplayValue(true)}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    if (filteredHotel)
                                      selectHotel && selectHotel(filteredHotel[0].id);
                                  }
                                }}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              {
                                sortedHotelList?.find((hotel) => hotel.id === selectedHotel?.id)
                                  ?.name
                              }
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Combobox.Button>
                      {isSidebarOpen ? (
                        <Combobox.Button className="absolute  inset-y-0 right-0 flex items-center pr-2">
                          <Icon.ChevronDown className="h-5 w-5 text-white" aria-hidden="true" />
                        </Combobox.Button>
                      ) : null}
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery('')}
                    >
                      <Combobox.Options className="ring-black absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-grey shadow-lg ring-1 ring-opacity-5 hover:cursor-pointer focus:outline-none sm:text-sm">
                        {filteredHotel?.length === 0 && query !== '' ? (
                          <>
                            <div className="relative cursor-default select-none px-4 py-2 text-grey">
                              No results found
                            </div>
                          </>
                        ) : (
                          filteredHotel?.map((hotel) => (
                            <Combobox.Option
                              key={hotel.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 hover:cursor-pointer ${
                                  active
                                    ? 'bg-indigo-reduced bg-opacity-30 pr-4 text-grey'
                                    : 'text-gray-900'
                                }`
                              }
                              onClick={() => selectHotel && selectHotel(hotel.id)}
                              value={hotel}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block ${selected ? 'font-medium' : 'font-normal'}`}
                                  >
                                    {hotel.name}
                                  </span>
                                  {selected && (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? 'text-grey' : 'text-teal-600'
                                      }`}
                                    >
                                      <Icon.CheckList className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </Menu>
            ) : null}
            {!isEmpty(sortedHotelList) && sortedHotelList.length <= 4 ? (
              <Menu as="div" className="relative inline-block w-full">
                {({ open }) => {
                  useEffect(() => {
                    setMenuOpen(open);
                  }, [open]);
                  return (
                    <>
                      <div className="z-0 mt-1 h-full w-full">
                        <TooltipProvider delayDuration={75}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Menu.Button
                                className={cn(
                                  'inline-flex w-full items-center gap-x-3 rounded-sm border-2 border-indigo-contrast border-opacity-30 p-2 text-left text-[13px] font-semibold hover:bg-white hover:bg-opacity-10 2xl:text-nav',
                                  !isSidebarOpen ? 'justify-center' : null
                                )}
                                value={
                                  sortedHotelList.find((hotel) => hotel.id === selectedHotel?.id)
                                    ?.id
                                }
                              >
                                <Icon.Home className="h-5 w-5 flex-shrink-0 fill-indigo-reduced" />
                                <Typography
                                  color="white"
                                  variant="meta-2"
                                  className={cn(
                                    'truncate leading-tight',
                                    !isSidebarOpen ? 'hidden' : null
                                  )}
                                >
                                  {isLoading
                                    ? 'Loading...'
                                    : sortedHotelList?.find(
                                        (hotel) => hotel.id === selectedHotel?.id
                                      )?.name}
                                </Typography>
                                <div className={cn('flex-1', !isSidebarOpen ? 'hidden' : null)} />
                                <Icon.ChevronDown
                                  className={cn(
                                    open ? 'rotate-180' : null,
                                    'group-hover:text-gray-400 h-5 w-5 flex-shrink-0 transform text-white transition-colors duration-150 ease-in-out',
                                    !isSidebarOpen && 'hidden',
                                    sortedHotelList.length === 1 && 'hidden'
                                  )}
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </TooltipTrigger>
                            <TooltipContent hidden={isSidebarOpen}>
                              {
                                sortedHotelList?.find((hotel) => hotel.id === selectedHotel?.id)
                                  ?.name
                              }
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>

                      {sortedHotelList.length > 1 ? (
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top rounded-sm bg-white shadow-lg ring-1 ring-focusNative ring-opacity-5">
                            <div className="flex w-full flex-col gap-y-1 p-1">
                              {sortedHotelList
                                .sort((a, b) => {
                                  if (a.name > b.name) {
                                    return 1;
                                  } else if (a.name < b.name) {
                                    return -1;
                                  } else {
                                    return 0;
                                  }
                                })
                                .map((hotel) => (
                                  <Menu.Item key={hotel.id}>
                                    <button
                                      type="button"
                                      className={cn(
                                        hotel.id === selectedHotel?.id
                                          ? 'bg-indigo bg-opacity-10'
                                          : 'hover:bg-indigo hover:bg-opacity-10',
                                        'flex w-full items-center rounded p-2 text-left'
                                      )}
                                      onClick={() => selectHotel && selectHotel(hotel.id)}
                                      title={hotel.name}
                                    >
                                      <Typography variant="meta-2" className="truncate">
                                        {hotel.name}
                                      </Typography>
                                    </button>
                                  </Menu.Item>
                                ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      ) : null}
                    </>
                  );
                }}
              </Menu>
            ) : null}
          </>
        ) : null}

        <div className="no-scrollbar overflow-y-auto">
          <div className="flex flex-col gap-1">
            {navigation?.map((item) =>
              !item.subNavigation ? (
                <TooltipProvider key={item.title} delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger>
                      <SecureNavLink
                        data-userflow-id={item?.userflowId}
                        feature={item.feature}
                        to={item.path || '#'}
                        onClick={() => setMobileNavOpen(false)}
                        className={({ isActive }) =>
                          cn(
                            isActive
                              ? 'bg-white bg-opacity-10 text-[13px] font-semibold text-white 2xl:text-nav-semibold'
                              : 'hover:bg-white hover:bg-opacity-10',
                            'flex w-full items-center gap-x-3 whitespace-nowrap rounded-sm p-2 text-left text-[13px] text-lightGrey 2xl:text-nav',
                            !isSidebarOpen && 'justify-center',
                            item.highlight
                              ? item.highlightColor
                                ? item.highlightColor
                                : 'bg-orange hover:bg-orange hover:bg-opacity-30'
                              : null
                          )
                        }
                      >
                        {item.highlight && item.badge && !isSidebarOpen ? (
                          <span className={`flex-shrink-0 duration-200`}>{item.badge}</span>
                        ) : (
                          <item.icon
                            className={cn(
                              'h-5 w-5 flex-shrink-0 fill-indigo-reduced',
                              item.highlight ? 'fill-white' : null
                            )}
                            aria-hidden="true"
                          />
                        )}
                        <span className={`flex-1 truncate ${!isSidebarOpen && 'hidden'}`}>
                          {item.title}
                        </span>
                        {item.badge ? (
                          <span
                            className={`duration-200 ${!isSidebarOpen && 'hidden'} flex-shrink-0`}
                          >
                            {item.badge}
                          </span>
                        ) : null}
                      </SecureNavLink>
                    </TooltipTrigger>
                    <TooltipContent hidden={isSidebarOpen}>{item.title}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <div key={item.title} className="space-y-1">
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          data-userflow-id={item?.userflowId}
                          className={cn(
                            'group flex w-full items-center gap-x-3 rounded-sm p-2 text-left text-[13px] text-lightGrey hover:bg-white hover:bg-opacity-10 2xl:text-nav',
                            !isSidebarOpen && 'justify-center'
                          )}
                          onClick={() => {
                            handleDisclosureClick(item.title);

                            if (!isSidebarOpen) {
                              setSidebarOpen(true);
                            }
                          }}
                        >
                          <item.icon
                            className="h-5 w-5 flex-shrink-0 text-indigo-reduced"
                            aria-hidden="true"
                          />

                          <span className={`flex-1 truncate ${!isSidebarOpen && 'hidden'}`}>
                            {item.title}
                          </span>

                          <Icon.ChevronDown
                            className={cn(
                              openDisclosure?.includes(item.title)
                                ? 'text-gray-400 rotate-180'
                                : 'text-gray-300',
                              'group-hover:text-gray-400 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out',
                              !isSidebarOpen && 'hidden'
                            )}
                            aria-hidden="true"
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent hidden={isSidebarOpen}>{item.title}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <AnimatePresence>
                    {openDisclosure?.includes(item.title) ? (
                      <motion.div
                        className="space-y-1"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.15, delay: 0.05 }}
                      >
                        {item.subNavigation?.map((subItem) => (
                          <SecureNavLink
                            feature={subItem.feature}
                            key={subItem.title}
                            to={subItem.path}
                            onClick={() => setMobileNavOpen(false)}
                            className={({ isActive }: { isActive: boolean }) =>
                              cn(
                                isActive
                                  ? 'bg-white bg-opacity-10 text-[13px] font-semibold text-lightGrey 2xl:text-nav-semibold'
                                  : ' text-white hover:bg-white hover:bg-opacity-10',
                                'group flex w-full items-center justify-between gap-x-3 rounded-sm py-2 pl-10 pr-1 text-left text-[13px] font-normal 2xl:text-nav',
                                !isSidebarOpen && 'hidden'
                              )
                            }
                          >
                            <span className="truncate">{subItem.title}</span>
                            {subItem.badge ? subItem.badge : null}
                          </SecureNavLink>
                        ))}
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSecondaryNavigation = (isSidebarOpen?: boolean) => {
    if (isEmpty(secondaryNavigation)) return null;
    return (
      <div className="flex flex-shrink-0 flex-col">
        <hr className={`${isSidebarOpen ? 'mx-2' : 'mx-1'} border-t border-indigo-reduced`} />
        <nav
          className={`my-1 flex flex-1 duration-300 2xl:my-2 ${
            isSidebarOpen
              ? 'items-center justify-around gap-x-1 px-2'
              : 'flex-col content-center space-y-1 p-1.5'
          }`}
        >
          {secondaryNavigation?.map((item) => (
            <TooltipProvider key={item.title} delayDuration={75}>
              <Tooltip>
                <TooltipTrigger className="w-full">
                  <NavLink
                    data-userflow-id={item?.userflowId}
                    to={item.path}
                    onClick={() => setMobileNavOpen(false)}
                    className={({ isActive }) =>
                      cn(
                        item.trigger,
                        item.trigger || !isActive
                          ? 'text-white hover:bg-white hover:bg-opacity-10'
                          : 'bg-white bg-opacity-10 text-[13px] font-semibold text-white 2xl:text-nav-semibold',
                        'flex w-full items-center justify-center rounded-sm py-2 text-[13px] 2xl:text-nav',
                        isSidebarOpen ? 'justify-items-stretch' : 'justify-center'
                      )
                    }
                  >
                    <div className="flex flex-col items-center gap-x-3">
                      <item.icon
                        className="h-5 w-5 flex-shrink-0 fill-indigo-reduced"
                        aria-hidden="true"
                      />
                    </div>
                  </NavLink>
                </TooltipTrigger>
                <TooltipContent side={isSidebarOpen ? 'top' : 'right'}>{item.title}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
          {view === 'admin' ? (
            <TooltipProvider delayDuration={75}>
              <Tooltip>
                <Popover open={popOverOpen} onOpenChange={(open) => setPopOverOpen(open)}>
                  <TooltipTrigger className="w-full" asChild>
                    <div>
                      <PopoverTrigger
                        className={cn(
                          'flex w-full items-center justify-center rounded-sm py-2 text-[13px] text-white hover:bg-white hover:bg-opacity-10 2xl:text-nav',
                          isSidebarOpen ? 'justify-items-stretch' : 'justify-center'
                        )}
                      >
                        <Icon.CommentOutline className="h-5 w-5 flex-shrink-0 text-indigo-reduced" />
                      </PopoverTrigger>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side={isSidebarOpen ? 'top' : 'right'} className="z-50">
                    {t('Add Note')}
                  </TooltipContent>
                  <PopoverContent
                    side="right"
                    sideOffset={isSidebarOpen ? 40 : 30}
                    align="end"
                    className="min-w-[400px] bg-white p-0"
                  >
                    <PopoverNote onClose={() => setPopOverOpen(false)} />
                  </PopoverContent>
                </Popover>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </nav>
      </div>
    );
  };

  const locale = userData?.language === 'de' ? 'de/' : userData?.language === 'es-ES' ? 'es/' : '';
  const referralLink = `https://roompricegenie.com/${locale}customer-referral/?referral_email_address=${userProfile?.email}`;

  const renderUserProfile = (isSidebarOpen?: boolean) => {
    return (
      <div className="flex flex-shrink-0 flex-col">
        <hr className={`${isSidebarOpen ? 'mx-2' : 'mx-1'} border-t border-indigo-reduced`} />
        <nav
          className={` flex flex-1 flex-col content-center space-y-1 duration-300 ${
            isSidebarOpen ? 'my-1 px-2 2xl:my-5' : 'my-1 px-1 2xl:my-2'
          }`}
        >
          <TooltipProvider delayDuration={75}>
            <Tooltip>
              <TooltipTrigger>
                <NavLink
                  data-userflow-id={NavUserflowIds.USER_PROFILE_MENU_ITEM}
                  to={userProfile?.path || '/'}
                  onClick={() => setMobileNavOpen(false)}
                  className={({ isActive }) =>
                    cn(
                      isActive
                        ? 'bg-white bg-opacity-10 text-[13px] font-semibold text-white 2xl:text-nav-semibold'
                        : ' text-white hover:bg-white hover:bg-opacity-10',
                      'flex items-center rounded-sm px-2 py-2 text-[13px] 2xl:text-nav',
                      isSidebarOpen ? 'justify-items-stretch' : 'justify-center'
                    )
                  }
                >
                  <div className="flex items-center gap-x-3">
                    {userProfile?.icon ? (
                      <userProfile.icon
                        className="h-5 w-5 flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <Icon.AccountCircle
                        className="h-5 w-5 flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div
                      className={`flex flex-col items-start duration-200 ${
                        !isSidebarOpen && 'hidden'
                      }`}
                    >
                      <Typography element="span" variant="nav" color="lightGrey">
                        {t('Account Settings')}
                      </Typography>
                    </div>
                  </div>
                </NavLink>
              </TooltipTrigger>
              <TooltipContent hidden={isSidebarOpen}>
                {userProfile?.tooltip ? userProfile?.tooltip : t('Account Settings')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          {view === 'client' ? (
            <a
              href={referralLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`${
                isSidebarOpen ? 'justify-items-stretch' : 'justify-center'
              } flex items-center rounded-sm px-2 py-2 text-[13px] text-white hover:bg-white hover:bg-opacity-10 2xl:text-nav`}
            >
              <div className="flex items-center gap-x-3">
                <Icon.Handshake className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
                <div
                  className={`flex flex-col items-start duration-200 ${!isSidebarOpen && 'hidden'}`}
                >
                  <Typography element="span" variant="nav" color="lightGrey">
                    {t('Refer & Earn Rewards')}
                  </Typography>
                </div>
              </div>
            </a>
          ) : null}

          {subscribeButton ? (
            <TooltipProvider delayDuration={75}>
              <Tooltip>
                <TooltipTrigger>
                  <NavLink
                    to={subscribeButton?.path || '#'}
                    onClick={(e) => {
                      setMobileNavOpen(false);
                      if (!subscribeButton?.path) {
                        e.preventDefault(); // Prevent navigation if there is no path
                      }
                      if (subscribeButton.upselling) {
                        setUpsellingModalOpen(true);
                      }
                    }}
                    className={({ isActive }) =>
                      cn(
                        'flex w-full items-center rounded-sm px-2 py-2 text-[13px] 2xl:text-nav',
                        subscribeButton?.path && isActive
                          ? 'bg-orange bg-opacity-80 font-semibold text-white hover:bg-opacity-100 2xl:text-nav-semibold'
                          : 'bg-orange bg-opacity-100 text-white hover:bg-opacity-80',
                        isSidebarOpen ? 'justify-items-stretch' : 'justify-center'
                      )
                    }
                  >
                    <div className="flex items-center gap-x-3">
                      {subscribeButton?.icon && (
                        <subscribeButton.icon
                          className="h-6 w-6 flex-shrink-0 text-white"
                          aria-hidden="true"
                        />
                      )}
                      {isSidebarOpen && (
                        <div className="flex items-start duration-200">
                          <Typography
                            element="span"
                            variant="meta-1"
                            color="white"
                            className="text-meta-1-medium"
                          >
                            {subscribeButton?.title}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </TooltipTrigger>
                <TooltipContent hidden={isSidebarOpen}>{t(subscribeButton?.title)}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}
        </nav>
      </div>
    );
  };

  return (
    <div className="flex h-screen w-full flex-1 overflow-hidden">
      <div>
        <Transition.Root show={mobileNavOpen} as={Fragment}>
          <Dialog
            as="div"
            className={cn('relative z-50', isIframe ? 'block' : 'md:hidden')}
            onClose={setMobileNavOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-darkGrey/40" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 flex items-center rounded-full bg-orange p-2.5 px-2 py-2 hover:bg-gold"
                        onClick={() => setMobileNavOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Icon.Clear className="h-5 w-5 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo px-4 pb-2 pt-8 md:px-6">
                    {isAdmin && !isHoteliersInspiration ? (
                      <Link to="/admin/clients" className="flex shrink-0 items-center gap-x-2">
                        <div
                          className={cn(
                            'h-auto w-10',
                            isLoading ? 'animate-rpg-bounce duration-700' : 'animate-none'
                          )}
                        >
                          <Genie className="fill-white" />
                        </div>
                        <div className="h-14 w-14">
                          <RoomPriceGenieText className="fill-white" />
                        </div>
                      </Link>
                    ) : isAdmin && isHoteliersInspiration ? (
                      <Link
                        to="/admin/clients"
                        className={`flex items-center gap-x-4 ${
                          sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                        }`}
                        title="Hoteliers Inspiration"
                      >
                        <div
                          className={cn('h-auto w-full duration-300', !sidebarOpen && 'scale-90')}
                        >
                          <img src={HoteliersInspiration} alt="Hoteliers Inspiration" />
                        </div>
                      </Link>
                    ) : isHoteliersInspiration ? (
                      <div
                        className={`flex items-center gap-x-4 ${
                          sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                        }`}
                        title="Hoteliers Inspiration"
                      >
                        <div
                          className={cn('h-auto w-full duration-300', !sidebarOpen && 'scale-90')}
                        >
                          <img src={HoteliersInspiration} alt="Hoteliers Inspiration" />
                        </div>
                      </div>
                    ) : (
                      <div className="flex shrink-0 items-center gap-x-2">
                        <div
                          className={cn(
                            'h-auto w-10',
                            isLoading ? 'animate-rpg-bounce duration-700' : 'animate-none'
                          )}
                        >
                          <Genie className="fill-white" />
                        </div>
                        <div className="h-14 w-14">
                          <RoomPriceGenieText className="fill-white" />
                        </div>
                      </div>
                    )}

                    <nav className="flex flex-1 flex-col overflow-auto">
                      {renderNavigation(mobileNavOpen)}
                      <div>
                        {secondaryNavigation ? renderSecondaryNavigation(mobileNavOpen) : null}
                        {userProfile ? renderUserProfile(mobileNavOpen) : null}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <div
        className={cn(
          'm-3 rounded-lg bg-indigo p-3',
          sidebarOpen ? 'w-60 transition-all duration-300' : 'transition-all duration-300 md:w-20',
          isIframe ? 'hidden' : 'hidden md:flex md:flex-col'
        )}
      >
        <div className="flex h-full min-h-0 w-full flex-col">
          {/* Sidebar for desktop */}
          <div className={`relative flex h-full w-full flex-1 flex-col`}>
            <nav className="flex h-full w-full flex-1 flex-col">
              <div className={`relative z-0 flex ${!sidebarOpen ? 'justify-center' : ''}`}>
                {isAdmin && !isHoteliersInspiration ? (
                  <Link
                    to="/admin/clients"
                    className={`flex items-center gap-x-4 ${
                      sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                    }`}
                    title="RoomPriceGenie"
                  >
                    <div
                      className={cn(
                        'h-auto w-10',
                        !sidebarOpen && 'scale-90',
                        isGlobalLoading ? 'animate-rpg-bounce duration-700' : 'animate-none'
                      )}
                    >
                      <Genie className="fill-white" />
                    </div>
                    <Transition
                      show={sidebarOpen}
                      enter="transition-scale duration-100"
                      enterFrom="scale-0"
                      enterTo="scale-100"
                      leave="transition-scale"
                      leaveFrom="scale-100"
                      leaveTo="scale-0"
                    >
                      <div className="h-14 w-14">
                        <RoomPriceGenieText className="fill-white" />
                      </div>
                    </Transition>
                  </Link>
                ) : isAdmin && isHoteliersInspiration ? (
                  <Link
                    to="/admin/clients"
                    className={`flex items-center gap-x-4 ${
                      sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                    }`}
                    title="Hoteliers Inspiration"
                  >
                    <div className={cn('h-auto w-full duration-300', !sidebarOpen && 'scale-90')}>
                      <img src={HoteliersInspiration} alt="Hoteliers Inspiration" />
                    </div>
                  </Link>
                ) : isHoteliersInspiration ? (
                  <div
                    className={`flex items-center gap-x-4 ${
                      sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                    }`}
                    title="Hoteliers Inspiration"
                  >
                    <div className={cn('h-auto w-full duration-300', !sidebarOpen && 'scale-90')}>
                      <img src={HoteliersInspiration} alt="Hoteliers Inspiration" />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex items-center gap-x-4 ${
                      sidebarOpen ? 'px-2 pt-4' : 'justify-center px-1 py-4'
                    }`}
                    title="RoomPriceGenie"
                  >
                    <div
                      className={cn(
                        'h-auto w-10',
                        !sidebarOpen && 'scale-90',
                        isGlobalLoading ? 'animate-rpg-bounce duration-700' : 'animate-none'
                      )}
                    >
                      <Genie className="fill-white" />
                    </div>
                    <Transition
                      show={sidebarOpen}
                      enter="transition-scale duration-100"
                      enterFrom="scale-0"
                      enterTo="scale-100"
                      leave="transition-scale"
                      leaveFrom="scale-100"
                      leaveTo="scale-0"
                    >
                      <div className="h-14 w-14">
                        <RoomPriceGenieText className="fill-white" />
                      </div>
                    </Transition>
                  </div>
                )}

                <div className="absolute -right-[22.5px] top-[74px] z-10">
                  <Button
                    icon
                    className="h-5 w-5 items-center justify-center rounded-full bg-white text-indigo"
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                  >
                    <span className="sr-only">
                      {!sidebarOpen ? 'Open sidebar' : 'Close sidebar'}
                    </span>
                    {sidebarOpen ? (
                      <Icon.ChevronLeft aria-hidden="true" />
                    ) : (
                      <Icon.ChevronRight aria-hidden="true" />
                    )}
                  </Button>
                </div>
              </div>
              {/* Navigation */}
              {renderNavigation(sidebarOpen)}
              <div>
                {secondaryNavigation ? renderSecondaryNavigation(sidebarOpen) : null}
                {userProfile ? renderUserProfile(sidebarOpen) : null}
                {isAdmin && setView && view && sidebarOpen ? (
                  <div className="w-full max-w-md px-1 sm:px-2">
                    <Tab.Group>
                      <Tab.List className="flex gap-0.5 space-x-1 rounded-sm bg-indigo-reduced p-1 text-meta-2 font-medium leading-5 2xl:text-meta-1">
                        {['admin', 'client'].map((tab) => (
                          <Tab
                            key={tab}
                            onClick={() => setView(tab as 'admin' | 'client')}
                            className={cn(
                              'w-full rounded p-1 text-darkGrey',
                              'ring-focusNative ring-opacity-60 ring-offset-0',
                              view === tab
                                ? 'bg-white shadow'
                                : 'hover:bg-white/[0.5] hover:ring-1 hover:ring-indigo-reduced'
                            )}
                          >
                            {tab === 'admin' ? 'Admin' : 'Client'}
                          </Tab>
                        ))}
                      </Tab.List>
                    </Tab.Group>
                  </div>
                ) : null}
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className={cn('flex h-full w-2/3 max-w-full flex-1 flex-col')}>
        <div className={cn('mobile-header', isIframe ? 'p-3' : 'p-3 md:p-0')}>
          <div
            className={cn(
              'sticky top-0 z-30 items-center gap-x-4 rounded-lg bg-indigo px-4  py-2 shadow-sm',
              isIframe ? 'flex' : 'flex md:hidden'
            )}
          >
            <button
              type="button"
              className="-m-2.5 flex items-center rounded-full px-2 py-2 hover:bg-white hover:bg-opacity-10"
              onClick={() => setMobileNavOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Icon.Hamburger className="h-5 w-5 fill-white" aria-hidden="true" />
            </button>

            <div className="flex shrink-0 items-center gap-x-2">
              {isHoteliersInspiration ? (
                <div className={cn('h-auto w-32 duration-300', !sidebarOpen && 'scale-90')}>
                  <img src={HoteliersInspiration} alt="Hoteliers Inspiration" />
                </div>
              ) : (
                <>
                  <div
                    className={cn(
                      'h-auto w-5',
                      isLoading ? 'animate-rpg-bounce duration-700' : 'animate-none'
                    )}
                  >
                    <Genie className="fill-white" />
                  </div>
                  <div className="h-7 w-7">
                    <RoomPriceGenieText className="fill-white" />
                  </div>
                </>
              )}
            </div>
            <div className="flex-1" />
            {!isIframe && userProfile ? (
              <NavLink
                to={userProfile.path}
                className="-m-2.5 flex items-center gap-x-2 rounded-md px-2 py-2 hover:bg-white hover:bg-opacity-10"
              >
                <div className="flex flex-col items-end">
                  <Typography element="span" variant="meta-2" color="lightGrey">
                    {t('Account Settings')}
                  </Typography>
                </div>
                <span className="sr-only">Open profile dropdown</span>
                <Icon.AccountCircle className="h-5 w-5 fill-white" aria-hidden="true" />
              </NavLink>
            ) : null}
          </div>
        </div>
        <main className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden">
          <div className="flex-1 overflow-hidden">
            <div className="relative h-full">
              <div className="scrollPage h-full w-full overflow-y-auto pb-32">{children}</div>
            </div>
          </div>
        </main>
        <UpsellingModal
          isModalOpen={upsellingModalOpen}
          onClose={() => setUpsellingModalOpen(false)}
        />
      </div>
    </div>
  );
}
