import { Returns } from 'use-lilius';
import { create } from 'zustand';

import {
  PICKUP_COMPARE_TYPE,
  PRICE_COMPARE_TYPE,
  AdjustmentType
} from '@pages/Client/Calendar/constants';
import { MinStaysVisualiser } from '@pages/Client/Calendar/hooks/useMinStayVisualiser';

type BulkUpdateInitialData = {
  startDate: Date;
  endDate: Date;
};

interface CalendarPageStore {
  /**
   * Calendar instance for all Calendar pages
   */
  liliusInstance?: Returns;
  setLiliusInstance: (instance: CalendarPageStore['liliusInstance']) => void;

  selectedHotelRoomId?: number;
  setSelectedHotelRoomId: (id: CalendarPageStore['selectedHotelRoomId']) => void;

  // Price

  comparePriceTo: PRICE_COMPARE_TYPE;
  setComparePriceMode: (compareTo: PRICE_COMPARE_TYPE) => void;

  // Pickup
  comparePickupTo: PICKUP_COMPARE_TYPE;
  setComparePickupMode: (compareTo: PICKUP_COMPARE_TYPE) => void;

  // Adjustment
  adjustment: AdjustmentType | undefined;

  showBulkEditDrawer: boolean;
  setShowBulkEditDrawer: (bool: boolean, initialData?: BulkUpdateInitialData) => void;
  bulkUpdatedInitialData?: BulkUpdateInitialData;

  setAdjustmentMode: (status: AdjustmentType | undefined) => void;

  recommendedPriceData?: Record<number, { price: number; suggested_price: number }>;
  setRecommendedPriceData: (
    data?: Record<number, { price: number; suggested_price: number }>
  ) => void;

  recommendedMinStayData?: Record<number, number>;
  setRecommendedMinStayData: (data?: Record<number, number>) => void;
  isTableView: boolean;
  setIsTableView: (isTableView: boolean) => void;
  weekIndex: number | null;
  setWeekIndex: (weekIndex: number | null) => void;
  initialRoomId: number | undefined;
  setInitialRoomId: (initialRoomId: number | undefined) => void;
  minStayDays: MinStaysVisualiser;
  setMinStayDays: (minStayDays: MinStaysVisualiser) => void;
  visualMinStayDays: string[];
  setVisualMinStayDays: (visualMinStayDays: string[]) => void;
}

export const useCalendarPageStore = create<CalendarPageStore>()((set) => ({
  liliusInstance: undefined,
  showBulkEditDrawer: false,
  setShowBulkEditDrawer: (show, initialData) =>
    set({ showBulkEditDrawer: show, bulkUpdatedInitialData: initialData }),

  setLiliusInstance: (instance) => {
    set({
      liliusInstance: instance
    });
  },
  setSelectedHotelRoomId: (id) => set({ selectedHotelRoomId: id }),

  enableComparePrice: true,
  comparePriceTo: 'pms-price',

  setComparePriceMode(compareTo) {
    set({
      comparePriceTo: compareTo
    });
  },

  enableComparePickup: true,
  comparePickupTo: 'yesterday',
  setComparePickupMode(compareTo) {
    set({
      comparePickupTo: compareTo
    });
  },

  enableAdjustment: false,
  adjustment: undefined,
  setAdjustmentMode(status) {
    set({
      adjustment: status
    });
  },

  setRecommendedMinStayData: (recommendedMinStayData) => set({ recommendedMinStayData }),
  setRecommendedPriceData: (recommendedPriceData) => set({ recommendedPriceData }),
  isTableView: false,
  setIsTableView(isTableView) {
    set({
      isTableView
    });
  },
  weekIndex: null,
  setWeekIndex(weekIndex) {
    set({
      weekIndex
    });
  },
  initialRoomId: undefined,
  setInitialRoomId(initialRoomId) {
    set({
      initialRoomId
    });
  },
  minStayDays: {},
  setMinStayDays(minStayDays) {
    set({
      minStayDays
    });
  },
  visualMinStayDays: [],
  setVisualMinStayDays(visualMinStayDays) {
    set({
      visualMinStayDays
    });
  }
}));
