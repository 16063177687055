import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Day } from 'use-lilius';

dayjs.extend(utc);
dayjs.extend(timezone);

interface MonthDetails {
  key: string;
  name: string;
  shortName: string;
}

export const useDate = ({ isHotelAccount }: { isHotelAccount?: boolean } = {}) => {
  const { hotelDetails } = useHotelDetails(isHotelAccount);
  const hotelTimezone = hotelDetails?.timezone ?? dayjs.tz.guess();

  const getNowInHotelTimezone = () => dayjs().tz(hotelTimezone);

  const convertToHotelTimezone = (date: Date) => {
    return dayjs.tz(date, hotelTimezone);
  };

  const nowInHotelTz = getNowInHotelTimezone();

  // Core date comparison functions
  const isToday = (date: Date) => {
    const hotelTodayDate = nowInHotelTz.format('YYYY-MM-DD');
    const calendarDate = dayjs(date).format('YYYY-MM-DD');
    return hotelTodayDate === calendarDate;
  };

  const isPastDate = (date: Date) => {
    const hotelToday = nowInHotelTz.format('YYYY-MM-DD');
    const calendarDate = dayjs(date).format('YYYY-MM-DD');
    return calendarDate < hotelToday;
  };

  const isWeekend = (date: Date) => {
    // This is the hotel's setting for the start of the week, if starts_monday is true, then the week starts on Monday and is considered an ISOWeek
    const isISOWeek = hotelDetails?.starts_monday;
    const day = dayjs(date).day();

    if (isISOWeek) {
      return day === Day.SUNDAY || day === Day.SATURDAY; // 0 for Sunday, 6 for Saturday
    }

    return day === Day.SATURDAY || day === Day.SUNDAY; // 6 for Saturday, 0 for Sunday
  };

  const getMonthDetails = (date: dayjs.Dayjs): MonthDetails => ({
    key: date.format('YYYY-MM'),
    name: date.format('MMMM'),
    shortName: date.format('MMM')
  });

  const currentDate = nowInHotelTz;

  const currentMonth: MonthDetails = getMonthDetails(currentDate);
  const nextMonth: MonthDetails = getMonthDetails(currentDate.add(1, 'month'));

  const startOfCurrentMonth = currentDate.startOf('month');

  const next12Months = Array.from({ length: 12 }, (_, i) =>
    startOfCurrentMonth.add(i, 'month').format('YYYY-MM')
  );

  return {
    isToday,
    isPastDate,
    isWeekend,
    currentMonth,
    nextMonth,
    getNowInHotelTimezone,
    convertToHotelTimezone,
    startOfCurrentMonth,
    next12Months,
    hotelTimezone // expose this for debugging if needed
  };
};
